import React, { Dispatch, SetStateAction, useState } from "react";
import { CommnetInter } from "../../interface/interface";
import { FileDown, formatDate } from "../../lib/lib";
import "./css/index.css";
import Reply from "./Reply/Reply";
const RoomComment: React.FunctionComponent<{
  data: CommnetInter;
  usr_id: string;
  leadr_usr_id: string;
  __report: (cid: string, content: string) => void;
  __reply: (commentid: string) => void;
  __remove: (id: string) => void;
  setfix: Dispatch<SetStateAction<CommnetInter | undefined>>;
}> = ({ data, usr_id, leadr_usr_id, __report, __reply, __remove, setfix }) => {
  const {
    ctts_id,
    ctts_ctts,
    ctts_reg_login_nm,
    ctts_reg_at,
    ctts_reg_usr_id,
    file_link,
    link,
    ctts_tit,
    reply_lst,
    usr_thumbnail,
    isalertblock,
    file_nm,
  } = data;
  return (
    <>
      {/* ${lid === uid ? "lment" : ""} */}
      <div
        className={`comment ${leadr_usr_id === ctts_reg_usr_id ? "lment" : ""}`}
      >
        {usr_id && (
          <div className="btn-wrapper">
            {isalertblock !== "BLOCK" && (
              <button
                onClick={() => {
                  __reply(ctts_id);
                }}
              >
                답글
              </button>
            )}
            {usr_id === ctts_reg_usr_id ? (
              <>
                {/* <button
                  onClick={() => {
                    setfix(data);
                  }}
                >
                  수정
                </button> */}
                <button
                  onClick={() => {
                    __remove(ctts_id);
                  }}
                >
                  삭제
                </button>
              </>
            ) : isalertblock !== "BLOCK" ? (
              <button
                onClick={() => {
                  __report(ctts_id, ctts_ctts);
                }}
              >
                신고
              </button>
            ) : undefined}
          </div>
        )}
        <div className="profile-wrapper">
          <div
            className="img-wrapper"
            style={{ backgroundImage: `url(${usr_thumbnail})` }}
          />
          <div className="right">
            <div className="nickname">{ctts_reg_login_nm}</div>
            <div className="grade">
              {" "}
              {leadr_usr_id === ctts_reg_usr_id ? "팀플리더" : "팀플러"}
            </div>
          </div>
        </div>
        <div className="content">{ctts_ctts}</div>
        <div className="file-wrapper">
          {link ? (
            <a href={link} target={"_blank"} rel="noreferrer">
              <div className="left">
                <div className="img-wrapper">
                  <img src="/assets/common/link.svg" alt="" />
                </div>
                <div className="title">{link}</div>
              </div>
              <img src="/assets/common/grey-arrow.svg" alt="" />
            </a>
          ) : undefined}
          {file_link ? (
            <button
              onClick={() => {
                FileDown(file_link, file_nm);
              }}
            >
              <div className="left">
                <div className="img-wrapper">
                  <img src="/assets/common/blue-file.svg" alt="" />
                </div>
                <div className="title">{ctts_tit}</div>
              </div>
              <img src="/assets/common/grey-arrow.svg" alt="" />
            </button>
          ) : undefined}
        </div>
        <div className="timestamp">{formatDate(ctts_reg_at, "/")}</div>
      </div>
      <div className="reply-list">
        {reply_lst &&
          reply_lst.map((item, idx) => {
            return (
              <Reply
                key={idx}
                data={item}
                leadr_usr_id={leadr_usr_id}
                cid={""}
                __reply={__reply}
                __report={__report}
                __remove={__remove}
                usr_id={usr_id}
              />
            );
          })}
      </div>
    </>
  );
};

export default RoomComment;
