import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { clientApi } from "../../api/config";
import {
  CommnetInter,
  fileType,
  RoomCommentWriteInter,
} from "../../interface/interface";
import { FileChangeUrl } from "../../lib/lib";

type Props = {
  length: number;
  ctts_id: string;
  prjt_id: string;
  getDetail: () => void;
  reply_id: string | undefined;
  setfix: Dispatch<SetStateAction<CommnetInter | undefined>>;
  fixComment: CommnetInter | undefined;
  setReplyId: Dispatch<SetStateAction<string | undefined>>;
};

let writeInfo = {
  file: {
    name: "",
    url: "",
  },
  link: "",
  content: "",
};
const initializer = (initialState: RoomCommentWriteInter) => initialState;
const RoomCommentWrite = ({
  length,
  ctts_id,
  prjt_id,
  getDetail,
  reply_id,
  setfix,
  fixComment,
  setReplyId,
}: Props) => {
  const [
    {
      file: { name, url },
      link,
      content,
    },
    dispatch,
  ] = useReducer(reducer, writeInfo, initializer);
  const [isLink, setIsLink] = useState(false);

  const CommentWrite = useCallback(() => {
    clientApi
      .post(`/web/cmnt/2/ctts`, {
        bbs_id: "3",
        ctts_tit: name,
        ctts_ctts: content,
        link: link,
        up_ctts_id: reply_id ? reply_id : ctts_id,
        file_link: url,
        file_nm: name,
      })
      .then(({ data: { sts_fg } }) => {
        if (sts_fg) {
          getDetail();
          dispatch({
            type: "reset",
            reset: writeInfo,
            file: {
              name: "",
              url: "",
            },
            payload: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ctts_id, content, url, link, getDetail, reply_id, name]);
  const CommentFix = useCallback(() => {
    if (fixComment) {
      clientApi
        .post(`/web/cmnt/2/ctts`, {
          bbs_id: "3",
          ctts_tit: name,
          ctts_ctts: content,
          link: link,
          ctts_id: fixComment.ctts_id,
          file_link: url,
          file_nm: name,
        })
        .then(({ data: { sts_fg } }) => {
          if (sts_fg) {
            getDetail();
            dispatch({
              type: "reset",
              reset: writeInfo,
              file: {
                name: "",
                url: "",
              },
              payload: "",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [fixComment, ctts_id, content, url, link, getDetail, name]);

  const __updateFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      FileChangeUrl(file[0]).then(({ url }) => {
        dispatch({
          type: "file",
          file: {
            name: file[0].name,
            url,
          },
          payload: "",
          reset: undefined,
        });
      });
    }
  }, []);
  const __fileCancel = useCallback(() => {
    dispatch({
      type: "file",
      file: {
        name: "",
        url: "",
      },
      payload: "",
      reset: undefined,
    });
  }, []);

  useEffect(() => {
    if (fixComment) {
      dispatch({
        type: "reset",
        reset: {
          file: {
            name: fixComment.ctts_tit ? fixComment.ctts_tit : "",
            url: fixComment.file_link ? fixComment.file_link : "",
          },
          link: fixComment.link ? fixComment.link : "",
          content: fixComment.ctts_ctts,
        },
        file: {
          name: "",
          url: "",
        },
        payload: "",
      });
    }

    return () => {};
  }, [fixComment]);

  return (
    <div
      className={`write-wrapper ${
        reply_id || fixComment ? "reply-write-wrapper" : ""
      }`}
    >
      <div className="title">
        <div className="title-text">
          {reply_id ? "답글 입력" : fixComment ? "댓글 수정" : "댓글"}
          {!reply_id && !fixComment && <span> {length}개</span>}
        </div>
        {reply_id ||
          (fixComment && (
            <button>
              <img
                src="/assets/common/cancel.svg"
                alt=""
                className="cancel"
                onClick={() => {
                  if (reply_id) {
                    setReplyId(undefined);
                  }
                  if (fixComment) {
                    setfix(undefined);
                  }
                }}
              />
            </button>
          ))}
      </div>
      <div className="comment-write-wrapper">
        <textarea
          placeholder="댓글 입력"
          value={content}
          onChange={(e) => {
            dispatch({
              type: "content",
              payload: e.target.value,
              file: {
                url: "",
                name: "",
              },
              reset: undefined,
            });
          }}
        ></textarea>
        {isLink ? (
          <div className="link-wrapper">
            <img src="/assets/common/grey-link.svg" alt="" />
            <input
              type="text"
              placeholder="링크를 입력해주세요"
              value={link}
              onChange={(e) => {
                dispatch({
                  type: "link",
                  payload: e.target.value,
                  file: {
                    url: "",
                    name: "",
                  },
                  reset: undefined,
                });
              }}
            />
          </div>
        ) : undefined}
        {name ? (
          <div className="link-wrapper file">
            <img src="/assets/common/grey-file.svg" alt="" />
            <div className="title">{name}</div>
            <img
              src="/assets/common/cancel.svg"
              alt=""
              className="cancel"
              onClick={__fileCancel}
            />
          </div>
        ) : undefined}
        <div className="write-btn-wrapper">
          <div className="left">
            <label>
              <img src="/assets/common/grey-file.svg" alt="" />
              <input type="file" multiple={false} onChange={__updateFile} />
            </label>
            <button onClick={() => setIsLink(!isLink)}>
              <img src="/assets/common/grey-link.svg" alt="" />
            </button>
          </div>
          <button
            className="right"
            onClick={() => {
              if (fixComment) {
                CommentFix();
              } else {
                CommentWrite();
              }
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoomCommentWrite;

function reducer(
  state: RoomCommentWriteInter,
  {
    payload,
    type,
    file,
    reset,
  }: {
    payload: string;
    type: string;
    file: fileType;
    reset: RoomCommentWriteInter | undefined;
  }
) {
  switch (type) {
    case "content":
      return { ...state, content: payload };
    case "file":
      return { ...state, file };
    case "link":
      return { ...state, link: payload };
    case "reset":
      return initializer(reset ? reset : state);

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
