import React, { useState } from "react";
import Calendar from "../../../components/Calendar/Calendar";
import { CalendarInter, ProjectCardInter } from "../../../interface/interface";
import { NavigateFunction } from "react-router-dom";

type Props = {
  state: CalendarInter;
  UpdateInfo: (type: "title" | "content" | "timestamp", value: string) => void;
  nowPrj: ProjectCardInter;
  navigate: NavigateFunction;
  WriteSubmit: () => void;
};

const CalendarWrite = ({
  state,
  UpdateInfo,
  nowPrj,
  navigate,
  WriteSubmit,
}: Props) => {
  const [isClendar, setIsClendar] = useState<boolean>(false);
  return (
    <div className="room-write">
      <div className="title">일정 추가</div>
      <div className="input-wrapper">
        <div className="title">일정추가</div>
        <button
          className="select-calendar-btn"
          onClick={() => {
            setIsClendar(true);
          }}
        >
          {state.timestamp && (
            <div className="title">
              {state.timestamp.getFullYear()}년 {state.timestamp.getMonth() + 1}
              월 {state.timestamp.getDate()}일
            </div>
          )}
          <img src="/assets/calendar/white-btn.svg" alt="날자변경" />
        </button>
      </div>
      <div className="input-wrapper">
        <div className="title">제목</div>
        <input
          type="text"
          placeholder="제목을 입력해주세요"
          onChange={(e) => {
            UpdateInfo("title", e.target.value);
          }}
        />
      </div>
      <div className="input-wrapper">
        <div className="title">내용</div>
        <textarea
          placeholder="내용을 입력해주세요"
          onChange={(e) => {
            UpdateInfo("content", e.target.value);
          }}
        ></textarea>
      </div>
      {isClendar && (
        <div className="open-calendar">
          <Calendar
            initial={state.timestamp}
            type="write"
            __update={UpdateInfo}
            prj_id={nowPrj && nowPrj.prjt_id}
            nowPrj={nowPrj}
            isWrite={false}
            setIsClendar={setIsClendar}
            navigate={navigate}
          />
        </div>
      )}
      <button
        className={`write-btn ${
          state.title && state.content ? "active-write" : ""
        }`}
        onClick={WriteSubmit}
      >
        작성하기
      </button>
    </div>
  );
};

export default CalendarWrite;
