import React, { Dispatch, SetStateAction, useState } from "react";

type Props = {
  data: {
    title: string;
    sub: string;
  };
  setIsOpen: Dispatch<SetStateAction<number | undefined>>;
  isOpen: boolean;
  idx: number;
};

function InquiryFold({ data, setIsOpen, isOpen, idx }: Props) {
  return (
    <div
      className={`card ${isOpen ? "open" : " "}`}
      onClick={() => {
        if (!isOpen) {
          setIsOpen(idx);
        } else {
          setIsOpen(undefined);
        }
      }}
    >
      <div className="title-wrapper">
        <div className="left">
          <img src="/assets/common/blue-q.svg" alt=""></img>
          {data.title}
        </div>
        <div className="right">
          {" "}
          <img src="/assets/common/grey-arrow.svg" alt="" />
        </div>
      </div>
      <div className="sub">{data.sub}</div>
    </div>
  );
}

export default InquiryFold;
