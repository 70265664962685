import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmtyList from "../../components/EmtyList/EmtyList";
import { Common_get_api } from "../../api/get_api";
import { formatNumber, PaymentStateForm } from "../../lib/lib";
import "./css/index.css";
import { PaymentList } from "../../interface/interface";

function MyPayment() {
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState<PaymentList[]>([]);

  const __navDetail = useCallback(
    (id: string, type: "cancel" | "refund" | undefined) => {
      navigate(`/my/payment/detail`, {
        state: {
          id,
          type,
        },
      });
    },
    [navigate]
  );
  useEffect(() => {
    Common_get_api(`/web/my/prjt?row_limit_cnt=10000`).then(
      ({ state, data }) => {
        if (state) {
          setPaymentData(data.lst);
        }
      }
    );
    return () => {};
  }, []);

  return (
    <div className="mypayment">
      <div className="wrapper">
        <div className="title">결제 내역</div>
        <div className="sub">
          <div className="text">
            ✓ 팀프로젝트 신청기간에는 언제든 취소가 가능합니다. <br />
            ✓ 팀플러로 선정되지 않은 프로젝트는 결제가 진행되지 않습니다.
            <br />✓ 팀플러 모집 종료 후, 선정된 프로젝트에 대한 환불은
            환불규정대로 진행됩니다.
          </div>
        </div>
        {paymentData.length > 0 ? (
          <div className="box">
            {paymentData.map(
              (
                {
                  prjt_type_cd,
                  prjt_nm,
                  attd_amt,
                  prjt_sts_cd,
                  prjt_thumbnail,
                  apply_id,
                  rcrt_id,
                  prjt_id,
                  apprv_sts_cd,
                  apply_sts_cd,
                  rcrt_paysts_cd,
                },
                idx
              ) => {
                const btn_state = PaymentStateForm(
                  prjt_type_cd,
                  prjt_sts_cd,
                  apprv_sts_cd,
                  apply_sts_cd,
                  rcrt_paysts_cd
                );
                return (
                  <div key={idx} className="boxlist">
                    <div className={`left-wrapper`}>
                      <div className="left">
                        <div
                          className="type-img"
                          style={{
                            backgroundImage: `url(${prjt_thumbnail})`,
                          }}
                        >
                          <div className={`type ${btn_state.type}`}>
                            {btn_state.text}
                          </div>
                        </div>
                      </div>
                      <div className="right">
                        <div className="title">{prjt_nm}</div>
                        <div className="pay">{formatNumber(attd_amt)}원</div>
                        {btn_state.type === "cancel" ||
                        btn_state.type === "refund" ? (
                          <button
                            className={`toggle `}
                            onClick={() => {
                              __navDetail(
                                `/web/prjt/${prjt_id}/rcrt/${rcrt_id}/apply/${apply_id}`,
                                btn_state.type === "cancel" ||
                                  btn_state.type === "refund"
                                  ? btn_state.type
                                  : undefined
                              );
                            }}
                          >
                            {btn_state.type === "cancel"
                              ? "신청 취소"
                              : "환불요청"}
                          </button>
                        ) : undefined}
                        <div className="detail-btn-wrapper">
                          <div
                            className={`state-text ${
                              btn_state.type === "refund-ready"
                                ? "refund-state"
                                : ""
                            }`}
                          >
                            {btn_state.type === "cancel-complete"
                              ? "취소완료"
                              : btn_state.type === "refund-complete"
                              ? "환불완료"
                              : btn_state.type === "refund-ready"
                              ? "환불처리중"
                              : ""}
                          </div>
                          <button
                            className="view"
                            onClick={() => {
                              __navDetail(
                                `/web/prjt/${prjt_id}/rcrt/${rcrt_id}/apply/${apply_id}`,
                                undefined
                              );
                            }}
                          >
                            <div className="title">상세</div>
                            <div className="img">
                              <img
                                src="/assets/common/right-arrow.svg"
                                alt="상세"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <EmtyList text={`결제 내역이 없습니다`} />
        )}
      </div>
    </div>
  );
}

export default MyPayment;
