import React from "react";
import "./css/index.css";
import { ReactComponent as ArrowSvg } from "../Banner/svg/btn-arrow.svg";
import { encrypto, formatDate } from "../../lib/lib";
import { ProjectCardInter } from "../../interface/interface";
import { NavigateFunction } from "react-router-dom";
type Props = {
  data: ProjectCardInter;
  usr_typ: string;
  usr_id: string;
  navigate: NavigateFunction;
};

const MyTeamplCard = ({
  data: {
    prjt_thumbnail,
    prjt_nm,
    rcrt_apply_en_at,
    prjt_id,
    def_reg_at,
    rcrt_nm,
    isregisted,
  },
  usr_typ,
  usr_id,
  navigate,
}: Props) => {
  return (
    <div className="my-teampl-card">
      <div className="top-section">
        <div className="left">
          <div
            className="img-wrapper"
            style={{ backgroundImage: `url(${prjt_thumbnail})` }}
          />
          <div className="title">{prjt_nm}</div>
        </div>
        <div className={`state-box ready`}>
          {usr_typ !== "READ" ? "선정" : "오픈"}
        </div>
      </div>
      <div className="line" />
      <div className="bottom-section">
        {usr_typ === "READ" ? (
          <div className="info-section">
            <div className="tag">모집마감일</div>
            <div className="info-data">{formatDate(rcrt_apply_en_at, "-")}</div>
          </div>
        ) : (
          <>
            {" "}
            <div className="info-section">
              <div className="tag">참여유형</div>
              <div className="info-data">{rcrt_nm}</div>
            </div>
            <div className="info-section">
              <div className="tag">결제일</div>
              <div className="info-data">{formatDate(def_reg_at, "-")}</div>
            </div>
          </>
        )}
        <div className="button-section">
          <button
            className="room-btn"
            onClick={() => {
              navigate("/room/notice", {
                state: prjt_id,
              });
            }}
          >
            <div className="btn-title">팀플룸 입장하기</div>
            <ArrowSvg />
          </button>
          {usr_typ !== "READ" || usr_id === "1" ? (
            <button
              className={isregisted === "NONE" ? "review-btn" : "room-btn"}
              onClick={() => {
                navigate(`/detail/project/${encrypto(prjt_id)}`, {
                  state: "review",
                });
              }}
            >
              <div className="btn-title">
                {isregisted === "NONE"
                  ? "후기를 작성해주세요"
                  : "작성한 후기 확인하기"}
              </div>
              <ArrowSvg />
            </button>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default MyTeamplCard;
