import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../..";
import { clientApi } from "../../../api/config";
import { RootState } from "../../../reducers";
import { ResetAuth } from "../../../reducers/auth";

type Props = { __cancel: () => void; dispatch: AppDispatch };

const WithDraw = ({ __cancel, dispatch }: Props) => {
  const navigate = useNavigate();
  const usr_id = useSelector<RootState, string>((state) => state.auth.usr_id);
  const withdraw = useCallback(() => {
    if (usr_id) {
      clientApi
        .delete(`/auth/usr/usr/${usr_id}`)
        .then(({ data: { sts_fg } }) => {
          if (sts_fg) {
            dispatch(ResetAuth());
            navigate("/login");
            window.localStorage.removeItem("accessToken");
          } else {
            alert("탈퇴에 실패하셨습니다");
          }
          __cancel();
        })
        .catch(() => {
          alert("네트워크 에러가 발생하였습니다");
        });
    }
  }, [usr_id, dispatch, navigate, __cancel]);
  return (
    <div className="with-draw-section">
      <div className="title">진짜로... 나 없어도 괜찮아?</div>
      <div className="btn-wrapper">
        <button className="no" onClick={__cancel}>
          팀플 계속하기
        </button>
        <button className="yes" onClick={withdraw}>
          팀플 그만하기
        </button>
      </div>
    </div>
  );
};

export default WithDraw;
