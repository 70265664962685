import React, { useState } from "react";
import { Link } from "react-router-dom";
import { menuboxinterface } from "../../../interface/interface";

function MenuBox({ title, arr }: { title: string; arr: menuboxinterface[] }) {
  const [isopen, setIsopen] = useState(true);
  return (
    <div
      className={`menu-box ${isopen ? "open-box" : ""}`}
      style={isopen ? { height: `${(arr.length + 1) * 50}px` } : undefined}
    >
      <div
        className="title-wrapper"
        onClick={() => {
          setIsopen(!isopen);
        }}
      >
        <div className="title">{title}</div>
        <img src="/assets/common/grey-arrow.svg" alt="" />
      </div>
      <div className="menu-arr">
        {arr.map(({ title, link }, idx) => {
          return (
            <Link to={link} key={idx} className="link-box">
              <div className="title">{title}</div>
              <img src="/assets/common/menu-arrow.svg" alt="" />
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default MenuBox;
