import React from "react";
import "./css/index.css";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Oval } from "react-loader-spinner";

const Loading = () => {
  const isLoading = useSelector<RootState, boolean>(
    (state) => state.config.isLoading
  );
  return isLoading ? (
    <div className="loading-section">
      <div className="back-black" />
      <Oval width={100} height={100} color="#0062FF" secondaryColor="#fffff" />
    </div>
  ) : (
    <></>
  );
};

export default Loading;
