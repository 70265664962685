import React from "react";

type Props = {
  number: string;
  title: string;
  __update: (type: "main" | "sub", e: string) => void;
  type: "main" | "sub";
  value: string;
  sub?: string;
};

const PaymentIntroArea = ({
  number,
  title,
  __update,
  type,
  value,
  sub,
}: Props) => {
  return (
    <div className="payment-area">
      <div className="number">{number}</div>
      <div className="title">{title}</div>
      {sub && <div className="sub">{sub}</div>}
      <textarea
        value={value}
        onChange={(e) => {
          __update(type, e.target.value);
        }}
      ></textarea>
    </div>
  );
};

export default PaymentIntroArea;
