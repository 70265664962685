import React, { useCallback, useState } from "react";
import { AppDispatch } from "../../..";
import ReviewComment from "../../../components/Comment/ReviewComment";
import CommentWrite from "../../../components/Comment/Write/CommentWrite";
import EmtyList from "../../../components/EmtyList/EmtyList";
import Infinite from "../../../components/Infinite/Infinite";
import UseCommoent from "../../../hooks/UseCommoent";
import { CommnetInter } from "../../../interface/interface";
import { PopupChange } from "../../../reducers/config";

type Props = {
  dispatch: AppDispatch;
  usr_id: string;
  prjt_id: string;
  profile: string;
  apprv_lst: { apply_usr_id: string; apprv_sts_cd: string }[];
};

function Review({ dispatch, usr_id, prjt_id, profile, apprv_lst }: Props) {
  const [isOpen, setisOpen] = useState<boolean>(false);
  const [ReviewData, WriteComment, isWrite, star, PagingUpdate] = UseCommoent({
    path: `/web/cmnt/1?bbs_id=1&ctts_ctg_cd=REVIEW&prjt_id=${prjt_id}&row_limit_cnt=10`,
    dispatch,
  });
  const isSelect =
    apprv_lst.filter(
      ({ apply_usr_id, apprv_sts_cd }) =>
        apply_usr_id === usr_id && apprv_sts_cd === "apprv_sts_select"
    ).length > 0;
  /** 신고 팝업 */
  const ReportPopup = useCallback(
    (cid: string, content: string) => {
      dispatch(
        PopupChange({
          type: "report",
          active: true,
          data: {
            cid,
            content,
          },
        })
      );
    },
    [dispatch]
  );

  const CloseOpen = useCallback(() => {
    setisOpen(false);
  }, []);

  /** 삭제 팝업 */
  const RemovePopup = useCallback(
    (id: string) => {
      dispatch(
        PopupChange({
          type: "REVIEW",
          data: id,
          active: true,
        })
      );
    },
    [dispatch]
  );

  /** 리뷰 작성 */
  const WriteReview = useCallback(
    (content: string) => {
      WriteComment({
        bbs_id: "1",
        ctts_ctts: content,
        ctts_ctg_cd: "REVIEW",
        prjt_id,
      });
    },
    [WriteComment, prjt_id]
  );
  /** 리뷰 수정 */
  const FixReview = useCallback(
    (star: number, content: string, ctts_id: string) => {
      WriteComment({
        bbs_id: "1",
        ctts_ctts: content,
        ctts_ctg_cd: "REVIEW",
        prjt_id,
        ctts_id,
      });
    },
    [WriteComment, prjt_id]
  );
  return (
    <div className="review-section">
      <div className="review-wrapper">
        <div className="wrapper">
          {ReviewData.length > 0 ? (
            <>
              <div className="review-length">
                <div className="title">후기</div>
                <span>{ReviewData.length}</span>
              </div>
              <div className="review-list">
                {ReviewData.map((item: CommnetInter) => {
                  return (
                    <ReviewComment
                      data={item}
                      usr_id={usr_id}
                      key={item.ctts_id}
                      __remove={RemovePopup}
                      __report={ReportPopup}
                      fix={FixReview}
                    />
                  );
                })}
              </div>
              <Infinite PagingUpdate={PagingUpdate} />
            </>
          ) : (
            <EmtyList text={`등록된 후기가 없습니다`} />
          )}
        </div>
      </div>
      {!isWrite && isSelect ? (
        <div className="insert-bottom">
          <div className="wrapper">
            <div
              className="img-wrapper"
              style={{ backgroundImage: `url(${profile})` }}
            />
            <button
              onClick={() => {
                setisOpen(true);
              }}
            >
              후기를 남겨주세요
            </button>
          </div>
        </div>
      ) : undefined}
      {!isWrite && isOpen ? (
        <CommentWrite
          __update={WriteReview}
          __cancel={CloseOpen}
          type={"comment"}
          category="review"
        />
      ) : undefined}
    </div>
  );
}

export default Review;
