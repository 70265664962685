import React, { useState } from "react";
import "./css/index.css";
function CommentWrite({
  __update,
  __cancel,
  type,
  category,
}: {
  __update: (e: string, type: string) => void;
  __cancel: () => void;
  type: string | undefined;
  category: "ask" | "review";
}) {
  const [text, setText] = useState("");
  return (
    <div className="content-write">
      <div className="wrapper">
        <div className="title">
          {category === "ask" ? (
            <>
              {" "}
              무엇이 궁금한가요? <br /> 팀플리더에게 자유롭게 질문해보세요
            </>
          ) : (
            <>프로젝트 리뷰를 남겨주세요</>
          )}
        </div>
        <textarea
          maxLength={1000}
          value={text}
          placeholder="내용을 입력해주세요"
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        <div className="sub-wrapper">
          <div className="sub">
            {category === "ask" ? (
              <>
                커뮤니티는 간단한 문의와 답변을 위한 것으로 <br /> 사진 및
                장문의 글이 지원되지 않습니다.
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="text-length">{text.length}/1000</div>
        </div>
        <div className="btn-wrapper">
          <button className="cancel" onClick={__cancel}>
            취소
          </button>
          <button
            className="insert"
            onClick={() => {
              if (type) {
                __update(text, type);
                __cancel();
              }
            }}
          >
            등록
          </button>
        </div>
      </div>
    </div>
  );
}

export default CommentWrite;
