import React, { useCallback, useEffect } from "react";
import "./css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { authinterface } from "../../interface/interface";
import LoginBtn from "../LoginBtn/LoginBtn";
import { useLocation, useNavigate } from "react-router-dom";
import MenuBox from "./components/MenuBox";
import { MenuChange, PopupChange } from "../../reducers/config";
import { ResetAuth } from "../../reducers/auth";

function Menu() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    grade: { cnt_prjt, cnt_end, cnt_inbi },
    info: { img, login_nm },
    email,
    usr_id,
    usr_typ,
  } = useSelector<RootState, authinterface>((state) => state.auth);
  const isMenu = useSelector<RootState, boolean>(
    (state) => state.config.isMenu
  );

  const __nav = useCallback(() => {
    navigate("/login");
  }, [navigate]);
  useEffect(() => {
    dispatch(MenuChange(false));

    return () => {};
  }, [location, dispatch]);

  const logout = useCallback(() => {
    window.localStorage.removeItem("accessToken");
    dispatch(ResetAuth());
    navigate("/login");
  }, [navigate, dispatch]);

  return (
    <div className={`menu-wrapper ${isMenu ? "menu-open" : "menu-off"}`}>
      <div
        className={`top ${usr_id ? "login-state" : "not-login"} ${
          usr_typ === "READ" ? "leader-top" : ""
        }`}
      >
        <div className={`wrapper ${usr_id ? "on" : "off"}`}>
          <button
            className="nav-mypage"
            onClick={() => {
              navigate("/my/page");
            }}
          >
            프로필수정
          </button>
          <div className="grade-name">
            {usr_typ === "READ" ? "팀플리더" : "팀플러 회원"}
          </div>
          <label>
            {img ? (
              <img src={img} alt="profile" className="main-img" />
            ) : (
              <img
                className="main-img"
                src="/assets/profile/profile.png"
                srcSet="/assets/profile/profile@2x.png 2x ,/assets/profile/profile@3x.png 3x"
                alt=""
              />
            )}
          </label>
          <div className="nickname">{login_nm}</div>
          <div className="email">{email}</div>
          {usr_typ !== "READ" ? (
            <div className="grade-wrapper">
              <div className="box">
                <div className="title">팀플 선정</div>
                <div className="number">{cnt_prjt}회</div>
              </div>
              <div className="box">
                <div className="title">팀플 완주</div>
                <div className="number">{cnt_end}회</div>
              </div>
              <div className="box">
                <div className="title">우수팀플러</div>
                <div className="number">{cnt_inbi}회</div>
              </div>
            </div>
          ) : undefined}
          <button
            className="my-teampl-btn"
            onClick={() => {
              navigate("/my/teampl");
            }}
          >
            MY 팀플
          </button>
        </div>
        <div className={`wrapper ${usr_id ? "off" : "on"}`}>
          <img src="/assets/common/emty-img.svg" alt="" className="emty-img" />
          <div className="title">로그인 해주세요</div>
          <LoginBtn type="login" title="로그인하기" __submit={__nav} />
        </div>
      </div>
      <div className={`bottom ${usr_id ? "login-bottom" : "non-bottom"}`}>
        <div className={`wrapper ${usr_id ? "on" : "off"}`}>
          <MenuBox title="마이팀플 메뉴" arr={pagearr} />
          <div className="block" />
          <MenuBox title="마이페이지" arr={myarr} />
          <div className="block" />
          <MenuBox title="프로젝트/밋업 관련" arr={projectarr} />
          <div className="block" />
          <MenuBox title="서비스 관련" arr={servicearr} />
          <div className="logout-wrapper">
            <button onClick={logout}>로그아웃</button> |{" "}
            <button
              onClick={() => {
                dispatch(
                  PopupChange({
                    active: true,
                    type: "withdraw",
                    data: undefined,
                  })
                );
              }}
            >
              탈퇴하기
            </button>
          </div>
        </div>
        <div className={`wrapper ${usr_id ? "off" : "on"}`}>
          <MenuBox title="마이팀플 메뉴" arr={pagearr} />
          <MenuBox title="서비스 관련" arr={servicearr} />
        </div>
      </div>
    </div>
  );
}

export default Menu;

const myarr = [
  {
    title: "기본 정보",
    link: "/my/page",
  },
  {
    title: "결제 카드 등록",
    link: "/my/card",
  },
  {
    title: "결제 내역",
    link: "/my/payment",
  },
  {
    title: "쿠폰 및 인비테이션",
    link: "/my/coupon",
  },
];

const projectarr = [
  {
    title: "MY 팀플",
    link: "/my/teampl",
  },
  {
    title: "스크랩 목록",
    link: "/my/scrap",
  },
];

const servicearr = [
  {
    title: "ABOUT",
    link: "/about",
  },
  {
    title: "팀플러 제휴 및 혜택보기",
    link: "/alliance",
  },
  {
    title: "공지사항",
    link: "/notice",
  },
  {
    title: "Q&A",
    link: "/inquiry",
  },
];
const pagearr = [
  {
    title: "프로젝트",
    link: "/project",
  },
  {
    title: "밋업",
    link: "/meetup",
  },
  {
    title: "매거진",
    link: "/magazine",
  },
];
