import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authinterface } from "../../interface/interface";
import { RootState } from "../../reducers";
import "./css/index.css";
import Profile from "../../components/Profile/Profile";
import UseUserCommon from "../../hooks/UserUserCommon";
import { FileReaderBase64 } from "../../lib/lib";
import { clientApi } from "../../api/config";
import { UserImg } from "../../reducers/auth";
import { ToastChange } from "../../reducers/config";
function Mypage() {
  const dispatch = useDispatch();
  const authinfo = useSelector<RootState, authinterface>((state) => state.auth);
  const [FixUserInfo] = UseUserCommon({ user_info: authinfo });
  const [fixtype, setFixtype] = useState<string | undefined>(undefined);
  const __updateImg = useCallback(
    (e: File) => {
      FileReaderBase64(e).then(({ url }) => {
        if (url) {
          clientApi
            .post("/auth/usr/usr", {
              usr_id: authinfo.usr_id,
              usr_thumbnail: url,
            })
            .then((res) => {
              if (res.status === 200) {
                dispatch(UserImg(String(url)));
                dispatch(
                  ToastChange({
                    active: true,
                    content: "프로필 이미지가 수정되었습니다",
                  })
                );
              }
            });
        }
      });
    },
    [authinfo.usr_id, dispatch]
  );
  const __fixportfolio = useCallback(
    (e: string) => {
      FixUserInfo({ link: e }).then(({ state }) => {
        if (state) {
          dispatch(
            ToastChange({
              active: true,
              content: "포트폴리오가 수정되었습니다",
            })
          );
          setFixtype(undefined);
        }
      });
    },
    [FixUserInfo, dispatch]
  );
  const __fixKeywords = useCallback(
    (e: { tag_nm: string }[]) => {
      FixUserInfo({ tag_lst: e }).then(({ state }) => {
        if (state) {
          setFixtype(undefined);
          dispatch(
            ToastChange({
              active: true,
              content: "나의 키워드가 수정되었습니다",
            })
          );
        }
      });
    },
    [FixUserInfo, dispatch]
  );

  const __updateNickname = useCallback(
    (nick: string, content: string) => {
      FixUserInfo(
        authinfo.info.login_nm !== nick
          ? { login_nm: nick, info_desc: content }
          : { info_desc: content }
      ).then(({ state }) => {
        if (state) {
          dispatch(
            ToastChange({
              active: true,
              content: "기본 정보가 수정되었습니다",
            })
          );
          setFixtype(undefined);
        }
      });
    },
    [FixUserInfo, dispatch, authinfo]
  );

  return (
    <main className="my-page">
      <Profile
        __updateNickname={__updateNickname}
        intro={authinfo.info.info_desc}
        __fixportfolio={__fixportfolio}
        fixtype={fixtype}
        __fixKeywords={__fixKeywords}
        setFixtype={setFixtype}
        __updateImg={__updateImg}
        authinfo={authinfo}
        tabLayout={authinfo.usr_typ === "READ" ? leader_layout : tabLayout}
        my_usr_id={authinfo.usr_id}
        isMe={true}
      />
    </main>
  );
}

export default Mypage;

const tabLayout = ["프로필", "프로젝트", "추천평", "지원서", "인증서"];

const leader_layout = ["프로필", "프로젝트", "추천평"];
