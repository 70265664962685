import React, { useEffect, useState } from "react";
import "./css/index.css";
import { PaymentDetailInter } from "../../interface/interface";
import {
  ChargeChange,
  DetailReturnType,
  formatDate,
  makeComma,
} from "../../lib/lib";

type Props = {
  type: "cancel" | "refund" | undefined;
  paymentData: PaymentDetailInter;
  paymentState: DetailReturnType;
  paymentFix: () => void;
  salePrice: number;
};

const PaymentResultCard = ({
  type,
  paymentData,
  paymentState,
  paymentFix,
  salePrice,
}: Props) => {
  const [chargeInfo, setChargeInfo] = useState({
    type: "",
    amt: 0,
    text: "",
  });
  const [payType, setPayType] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (paymentState === "refund-state" || type === "refund") {
      const pay_sts = paymentData.payList[0].rcrt_paysts_cd;
      setChargeInfo(
        ChargeChange(
          paymentData.paym_at,
          paymentData.paym_amt - salePrice,
          paymentData.prjt_type_cd,
          paymentData.prjt_apply_st_at,
          type === "refund" ? String(new Date()) : paymentData.apply_sts_at
        )
      );
      setPayType(pay_sts);
    }
    return () => {};
  }, [type, paymentData, paymentState, salePrice]);

  return (
    <>
      <div className="refund-info-section">
        <div className="line" />
        <div className="refund-tag-wrapper">
          {!type ? (
            <>
              <div className="title">취소 정보</div>
              <div className="tag-wrapper">
                <div className="tag">처리상태</div>
                <div className="charge">
                  {paymentState === "cancel-state"
                    ? "취소완료"
                    : payType === "ref_req"
                    ? "환불처리중"
                    : "환불완료"}
                </div>
              </div>
            </>
          ) : undefined}
          {paymentState === "refund-state" || type === "refund" ? (
            <>
              {" "}
              <div className="tag-wrapper">
                <div className="tag">결제일</div>
                <div className="content">
                  {formatDate(paymentData.paym_at, "-")}
                </div>
              </div>
              <div className="tag-wrapper">
                <div className="tag">취소일</div>
                <div className="content">
                  {formatDate(
                    type ? Date.now() : paymentData.apply_sts_at,
                    "-"
                  )}
                </div>
              </div>
              <div className="tag-wrapper">
                <div className="tag">취소일 기준</div>
                <div className="refund-time">{chargeInfo.text}</div>
              </div>
              <div className="tag-wrapper">
                <div className="tag">
                  수수료 <span>수수료는 상세페이지의 환불규정을 따릅니다.</span>
                </div>
                <div className="charge">
                  {chargeInfo.type === "charge"
                    ? "20%"
                    : chargeInfo.type === "all"
                    ? "0%"
                    : "환불불가"}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="tag-wrapper">
                <div className="tag">신청일</div>
                <div className="content">
                  {formatDate(paymentData.apply_sts_at, "-")}
                </div>
              </div>
              <div className="tag-wrapper">
                <div className="tag">취소일</div>
                <div className="content">
                  {formatDate(paymentData.apply_sts_at, "-")}
                </div>
              </div>
              <div className="tag-wrapper">
                <div className="tag">수수료</div>
                <div className="content">0%</div>
              </div>
            </>
          )}
        </div>
        <div className="refund-price-box">
          <div className="title">
            {paymentState === "refund-state" ? "환불금" : "취소금"}
          </div>
          <div className="content">
            {makeComma(
              paymentState === "refund-state"
                ? paymentData.paym_amt - salePrice - chargeInfo.amt
                : paymentData.attd_amt - salePrice
            )}
            원
          </div>
        </div>
      </div>
      {!type && paymentState === "refund-state" ? (
        <>
          <div className="card-title">카드등록정보</div>
          <div className="card-box">
            <div className="type">{paymentData.crd_nm}</div>
            <div className="number">{paymentData.crd_num}</div>
          </div>
        </>
      ) : type === "refund" && chargeInfo.type !== "no-refund" ? (
        <button className="payment-active-btn" onClick={paymentFix}>
          환불 금액 확인 완료 후 신청
        </button>
      ) : undefined}
    </>
  );
};

export default PaymentResultCard;
