import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clientApi } from "../../api/config";
import { Check_usr_email } from "../../api/user_api";
import CertifyInput from "../../components/CertifyInput/CertifyInput";
import { encrypto } from "../../lib/lib";
import { LoadingChange, ToastChange } from "../../reducers/config";
import "./css/index.css";
function SendMail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSend, setisSend] = useState<boolean>(false);
  const [type, setType] = useState<string | undefined>(undefined);
  const [isFoucs, setIsFoucs] = useState<boolean>(false);
  const [usr_id, setUsr_id] = useState<string | undefined>(undefined);
  const [login_id, setLogin_id] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const __certify = useCallback(() => {
    dispatch(LoadingChange(true));
    Check_usr_email(email).then(({ state, usr_id, login_id }) => {
      if (state) {
        setisSend(true);
        setType("finish");
        setUsr_id(usr_id);
        setLogin_id(login_id);
      } else {
        dispatch(
          ToastChange({ active: true, content: "미가입 이메일 입니다." })
        );
      }
      dispatch(LoadingChange(false));
    }); //or break
  }, [email, dispatch]);
  const __navFinish = useCallback(() => {
    dispatch(LoadingChange(true));
    if (isSend && usr_id) {
      const obj = {
        tit: "[마이팀플] 비밀번호 변경 요청입니다",
        msg: "아래 링크를 통해서 비밀번호를 변경해주세요",
        url: `${
          process.env.REACT_APP_DOMAIN
        }/fixpassword/${login_id}/${encrypto(usr_id)}`,
        usr_email: email,
      };

      clientApi
        .post("/auth/usr/sendmail", obj)
        .then(({ data: { sts_fg, err_msg } }) => {
          if (sts_fg) {
            navigate("/resend", { state: obj });
          } else {
            dispatch(ToastChange({ active: true, content: err_msg }));
          }
          dispatch(LoadingChange(false));
        })
        .catch(() => {
          dispatch(LoadingChange(false));
          dispatch(
            ToastChange({
              active: true,
              content: "네트워크 연결이 불안정합니다",
            })
          );
        });
    } else {
      dispatch(LoadingChange(true));
    }
  }, [isSend, navigate, email, usr_id, login_id, dispatch]);

  return (
    <main className="send-mail">
      <div className="wrapper">
        <div className="tag">비밀번호 재설정</div>
        <div className="title">가입한 이메일 주소를 입력해주세요</div>
        <CertifyInput
          type={type}
          isFocus={isFoucs}
          setIsFoucs={setIsFoucs}
          setValue={setEmail}
          isCertify={isSend}
          Certify={__certify}
          setIsCertify={setisSend}
          category="send"
          value={email}
        />
        <div className="btn-wrapper">
          <button
            className={`send ${isSend ? "active" : ""}`}
            onClick={__navFinish}
          >
            이메일로 변경링크 받기
          </button>
          <button className="question">
            <div className="sub">가입시 정보가 기억나지 않는다면?</div>
            <div className="title">고객센터문의</div>
          </button>
        </div>
      </div>
    </main>
  );
}

export default SendMail;
