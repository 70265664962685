import React from "react";
import "./css/index.css";
type Props = {
  text: string;
};

const EmtyList = ({ text }: Props) => {
  return (
    <div className="templ-emty-list">
      <img
        src="/assets/login/finish.png"
        srcSet="/assets/login/finish@2x.png 2x, /assets/login/finish@3x.png 3x"
        alt=""
      />
      <div className="emty-title">{text}</div>
    </div>
  );
};

export default EmtyList;
