import React, { useCallback, useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import UseGetCommon from "../../../hooks/UseGetCommon";
import { Common_get_api } from "../../../api/get_api";
import { ProjectCardInter } from "../../../interface/interface";
import EmtyList from "../../EmtyList/EmtyList";
import ProfileCard from "./components/ProfileCard";
import Infinite from "../../Infinite/Infinite";

type Props = {
  navigate: NavigateFunction;
};

const Support = ({ navigate }: Props) => {
  const [getData, PagingUpdate] = UseGetCommon({
    path: `/web/my/prjt?row_limit_cnt=100000`,
    limit: 0,
  });
  const NavSupport = useCallback(
    (e: ProjectCardInter) => {
      navigate("/my/apply", { state: e });
    },
    [navigate]
  );

  return (
    <div className="profile-support-section">
      <div className="wrapper">
        <div className="support-section-title">프로젝트 지원서</div>
        <div className="support-section-length">
          전체 {getData.filter((item) => item.prjt_type_cd === "prjt").length}개
        </div>
        <div className="support-section-list">
          {getData.length > 0 ? (
            <>
              {getData
                .filter((item) => item.prjt_type_cd === "prjt")
                .map((item, idx) => {
                  return (
                    <ProfileCard
                      key={idx}
                      data={item}
                      NavProject={undefined}
                      type="support"
                      download={undefined}
                      NavSupport={NavSupport}
                    />
                  );
                })}
              <Infinite PagingUpdate={PagingUpdate} />
            </>
          ) : (
            <EmtyList text={` 작성한 지원서가 없습니다`} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Support;
