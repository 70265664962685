import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  authinterface,
  MeetupBasicDataType,
} from "../../../interface/interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import Detail from "../Detail";
import { Meetup_detial_api } from "../../../api/get_api";
import { ToastChange } from "../../../reducers/config";
import { decypto } from "../../../lib/lib";
type Props = {};

const MeetUpContainer = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const id = decypto(location.substring(15, location.length));
  const authInfo = useSelector<RootState, authinterface>((state) => state.auth);
  const [meetUpData, setMeetUpData] = useState<MeetupBasicDataType | undefined>(
    undefined
  );

  const [navIndex, setNavIndex] = useState<number>(0);
  const __navPayment = useCallback(() => {
    if (authInfo.usr_id) {
      navigate("/payment/receipt", {
        state: {
          data: meetUpData,
          pid: id,
          field: meetUpData?.rcrt_lst[0],
        },
      });
    }
  }, [navigate, id, meetUpData, authInfo.usr_id]);
  useEffect(() => {
    Meetup_detial_api(`/web/cstm/prjt/${id}`)
      .then(({ state, data }) => {
        if (state) {
          if (data?.prjt_sts_cd === "prjt_sts_rcrt_paus") {
            navigate("/");
            dispatch(
              ToastChange({
                active: true,
                content: "팀리더가 밋업을 수정중입니다",
              })
            );
          } else {
            setMeetUpData(data);
          }
        }
      })
      .catch(() => {
        dispatch(
          ToastChange({
            active: true,
            content: "네트워크가 불안정합니다.",
          })
        );
      });

    return () => {};
  }, [dispatch, id, navigate]);
  return (
    <Detail
      Data={meetUpData}
      selectField={undefined}
      setSelectField={undefined}
      navIndex={navIndex}
      __navPayment={__navPayment}
      setNavIndex={setNavIndex}
      dispatch={dispatch}
      authInfo={authInfo}
      id={id}
      type={"meetup"}
      categoryContent={meetUpData?.mtup_typ === "OFFLINE" ? "대면" : "비대면"}
      navLayout={meetNavLayout}
    />
  );
};

export default MeetUpContainer;

const meetNavLayout = ["정보", "문의하기", "후기"];
