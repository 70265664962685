import React, { useCallback, useEffect, useState } from "react";
import { ProjectCardInter } from "../../../interface/interface";
import { calendarDataType } from "../Calendar";
import { clientApi } from "../../../api/config";
import { useDispatch, useSelector } from "react-redux";
import { PopupChange, ToastChange } from "../../../reducers/config";
import { RootState } from "../../../reducers";
import { Remove_Comment_Id } from "../../../reducers/data";

type Props = {
  nowDate: Date;
  selectDate: number | undefined;
  viewData: calendarDataType[];
  nowPrj: ProjectCardInter;
  usr_id?: string;
  GetCalendar: () => void;
};

const CalendarView = ({
  nowDate,
  selectDate,
  viewData,
  nowPrj,
  usr_id,
  GetCalendar,
}: Props) => {
  const dispatch = useDispatch();
  const viewDate = new Date(nowDate);
  viewDate.setDate(selectDate ? selectDate : 0);
  const remove_id = useSelector<RootState, string | undefined>(
    (state) => state.data.removeComment
  );
  const [OpenDetail, setOpenDetail] = useState<undefined | calendarDataType>(
    undefined
  );

  const RemoveSchedule = useCallback(() => {
    dispatch(
      PopupChange({
        active: true,
        data: OpenDetail?.schd_id,
        type: "CALENDAR",
      })
    );
  }, [OpenDetail]);

  useEffect(() => {
    if (nowPrj && OpenDetail && remove_id === OpenDetail?.schd_id) {
      clientApi
        .delete(`/web/my/prjt/${nowPrj.prjt_id}/task/${OpenDetail?.schd_id}`)
        .then(({ data }) => {
          if (data.sts_fg) {
            dispatch(Remove_Comment_Id(undefined));
            setOpenDetail(undefined);
            GetCalendar();
            dispatch(
              ToastChange({
                active: true,
                content: "일정을 삭제하였습니다",
              })
            );
          } else {
            dispatch(Remove_Comment_Id(undefined));
            dispatch(
              ToastChange({
                active: true,
                content: "일정 삭제에 실패하였습니다",
              })
            );
          }
        })
        .catch(() => {
          dispatch(Remove_Comment_Id(undefined));
          dispatch(
            ToastChange({
              active: true,
              content: "일정 삭제에 실패하였습니다",
            })
          );
        });
    }
    return () => {};
  }, [remove_id, dispatch, nowPrj, GetCalendar]);
  return (
    <>
      <div className="calendar-view-section">
        <div className="view-section-title">
          {viewDate.getFullYear()}년 {viewDate.getMonth() + 1}월 {selectDate}일{" "}
          {week_form[viewDate.getDay()]}
        </div>
        <div className="calendar-view-list">
          {viewData.length > 0 ? (
            viewData.map((data, idx) => {
              return (
                <button
                  key={idx}
                  className="view-lst-btn"
                  onClick={() => {
                    setOpenDetail(data);
                  }}
                >
                  <div className="title">{data.schd_nm}</div>
                  <div className="right-section">
                    <div className="title">자세히보기</div>
                    <img src="/assets/common/grey-arrow.svg" alt="" />
                  </div>
                </button>
              );
            })
          ) : (
            <button className="view-lst-btn emty" disabled>
              <div className="title">등록된 일정이 없습니다</div>
            </button>
          )}
        </div>
      </div>
      {OpenDetail ? (
        <div className="calendar-detail-view">
          <div className="wrapper">
            <button
              onClick={() => {
                setOpenDetail(undefined);
              }}
            >
              <img src="/assets/common/cancel.svg" alt="닫기" />
            </button>
            <div className="detail-view-time">
              {viewDate.getFullYear()}년 {viewDate.getMonth() + 1}월{" "}
              {selectDate}일 {week_form[viewDate.getDay()]}
            </div>
            <div className="title">{OpenDetail.schd_nm}</div>
            <div className="leader-section">
              <div
                className="img-wrapper"
                // style={{backgroundImage:`url(${nowPrj.})`}}
              />
              <div className="leader-name">
                <div>
                  <span>팀리더</span> {nowPrj.leadr_usr_nm}
                </div>
                {usr_id === nowPrj.leadr_usr_id && (
                  <button onClick={RemoveSchedule}>삭제</button>
                )}
              </div>
            </div>
            <div className="detail-view-content">{OpenDetail.schd_ctts}</div>
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default CalendarView;

const week_form = [
  "일요일",
  "월요일",
  "화요일",
  "수요일",
  "목요일",
  "금요일",
  "토요일",
];
