import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Fix_user_info } from "../api/user_api";
import { authinterface } from "../interface/interface";
import { UpdateAuthInfo } from "../reducers/auth";
import { ToastChange } from "../reducers/config";

type Props = {
  user_info: authinterface;
};

const UseUserCommon = ({ user_info }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const FixUserInfo = useCallback(
    (data: any): Promise<{ state: boolean }> => {
      return new Promise((resolve, reject) => {
        let obj = Object.assign({}, user_info.info);
        let clone = Object.assign(obj, data);
        Fix_user_info(Object.assign({ usr_id: user_info.usr_id }, data))
          .then(({ state, data, msg }) => {
            if (state && data) {
              dispatch(UpdateAuthInfo(clone));
              navigate("/my/page");
              resolve({ state: true });
            } else {
              dispatch(ToastChange({ active: true, content: msg }));
            }
          })
          .catch(() => {
            reject({ state: false });
          });
      });
    },
    [user_info, dispatch, navigate]
  );

  return [FixUserInfo];
};

export default UseUserCommon;
