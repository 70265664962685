import {
  MeetupBasicDataType,
  ProjectBasicDataType,
} from "../interface/interface";
import { clientApi } from "./config";
export const NetworkError = {
  state: false,
  data: undefined,
  msg: "네트워크 연결을 확인해주세요",
};

type ReturnType = {
  state: boolean;
  data: ProjectBasicDataType | undefined;
  msg: string;
};
type MeetupType = {
  state: boolean;
  data: MeetupBasicDataType | undefined;
  msg: string;
};

const Common_get_api = (path: string) => {
  return clientApi
    .get(path)
    .then(({ data }) => {
      if (data.sts_fg) {
        return {
          state: true,
          data: data.succ_obj,
          msg: "요청 성공",
        };
      } else {
        return {
          state: false,
          data: undefined,
          msg: data.err_msg,
        };
      }
    })
    .catch((error) => {
      return NetworkError;
    });
};

const Project_detial_api = (path: string): Promise<ReturnType> => {
  return clientApi
    .get(path)
    .then(({ data }) => {
      if (data.sts_fg) {
        return {
          state: true,
          data: data.succ_obj,
          msg: "요청 성공",
        };
      } else {
        return {
          state: false,
          data: undefined,
          msg: data.err_msg,
        };
      }
    })
    .catch((error) => {
      return NetworkError;
    });
};
const Meetup_detial_api = (path: string): Promise<MeetupType> => {
  return clientApi
    .get(path)
    .then(({ data }) => {
      if (data.sts_fg) {
        return {
          state: true,
          data: data.succ_obj,
          msg: "요청 성공",
        };
      } else {
        return {
          state: false,
          data: undefined,
          msg: data.err_msg,
        };
      }
    })
    .catch((error) => {
      return NetworkError;
    });
};

export { Common_get_api, Project_detial_api, Meetup_detial_api };
