import React from "react";
import "./css/index.css";

interface Propsinter {
  data: {
    title: string;
    link: string;
    spand: string;
  };
  __click: (index: number) => void;
  active: boolean;
  index: number;
}

function Policy({
  data: { title, link, spand },
  active,
  __click,
  index,
}: Propsinter) {
  return (
    <div className={`policy ${active ? "policy-active" : ""}`}>
      <div
        className="left"
        onClick={() => {
          __click(index);
        }}
      >
        <div className="circle">
          <img src="/assets/login/check.svg" alt="" />
        </div>
        <div className="title">
          <span>{spand} </span>
          {title}
        </div>
      </div>
      <a href={`/policy/${link}`} target={"_blank"} rel="noreferrer">
        전문보기
      </a>
    </div>
  );
}

export default Policy;
