import React from "react";
import "./css/index.css";
function LoginBtn({
  type,
  title,
  __submit,
}: {
  type: string;
  title: string;
  __submit: () => void;
}) {
  return (
    <button className={`login-btn ${type}-style`} onClick={__submit}>
      {type === "signup" ? (
        <div className="sub">가입만으로 대기업 프로젝트 간접경험!</div>
      ) : undefined}
      <div className="title">{title}</div>
    </button>
  );
}

export default LoginBtn;
