import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Get_usr_coupon } from "../../api/user_api";
import NotFound from "../../components/NotFound/NotFound";
import { CouponInter, PaymentInter } from "../../interface/interface";
import { RootState } from "../../reducers";
import { ToastChange } from "../../reducers/config";
import "./css/index.css";

interface LocationState {
  state: PaymentInter;
}

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation() as LocationState;
  const { field, pid, data, content, name, tel, isCs, policy, isRefund } =
    state;
  const usr_id = useSelector<RootState, string>((state) => state.auth.usr_id);
  const [coupon, setCoupon] = useState<CouponInter | undefined>(undefined);
  const [CouponData, setCouponData] = useState<CouponInter[]>([]);
  const __changeCoupon = useCallback(
    (e: CouponInter | undefined) => {
      setCoupon(e);
      if (e === undefined) {
        sessionStorage.removeItem("coupon");
      }
      navigate("./receipt", { state });
    },
    [navigate, state]
  );

  useEffect(() => {
    if (state) {
      const storage = sessionStorage.getItem("coupon");
      if (storage) {
        setCoupon(JSON.parse(storage));
      }
    } else {
      dispatch(ToastChange({ active: true, content: "잘못된 접근입니다." }));
      navigate("/");
    }
    return () => {};
  }, [state, dispatch, navigate]);
  useEffect(() => {
    if (coupon) {
      sessionStorage.setItem("coupon", JSON.stringify(coupon));
    }
    return () => {};
  }, [coupon]);
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("coupon");
    };
  }, []);
  useEffect(() => {
    if (usr_id) {
      Get_usr_coupon(`/cstm/cpon_usr?usr_id=${usr_id}&cpon_stat_cd=99`).then(
        ({ state, data }) => {
          if (state) {
            setCouponData(data);
          }
        }
      );
    }
    return () => {};
  }, [usr_id, data]);

  return (
    <main className="payment-section">
      <div className="wrapper">
        {data ? (
          <Outlet
            context={{
              data: data,
              navigate,
              field: field,
              __changeCoupon: __changeCoupon,
              coupon,
              pid,
              content,
              usr_id,
              CouponData,
              name,
              tel,
              isCs,
              policy,
              dispatch,
              isRefund,
            }}
          />
        ) : (
          <NotFound />
        )}
      </div>
    </main>
  );
};

export default Payment;
