import React, { useEffect, useRef } from "react";
import "./css/index.css";
type Props = {
  data: string;
};

const Screen = ({ data }: Props) => {
  const ContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ContentRef.current) {
      ContentRef.current.innerHTML = data;
      const files = ContentRef.current.querySelectorAll("button.file-template");
      files.forEach((item) => {
        const link = item.firstChild?.textContent;
        const name = item.lastChild?.textContent;
        if (link && name) {
          item.addEventListener("click", () => {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = (event) => {
              var blob = URL.createObjectURL(xhr.response);
              var link = document.createElement("a");
              link.href = blob;
              link.download = name;
              document.body.appendChild(link);
              link.click();
              link.remove();
            };
            xhr.open("GET", link);
            xhr.send();
          });
        }
      });
    }

    return () => {};
  }, [data]);

  return <div className="screen-section" ref={ContentRef} />;
};

export default Screen;
