import React from "react";
import { NavigateFunction, useOutletContext } from "react-router-dom";
import { ProjectBasicDataType } from "../../../interface/interface";
import { formatDate } from "../../../lib/lib";

type Props = {};

const PaymentFinish = (props: Props) => {
  const {
    navigate,
    data: { prjt_type_cd, prjt_apply_st_at },
  }: {
    navigate: NavigateFunction;
    data: ProjectBasicDataType;
  } = useOutletContext();

  return (
    <div className="payment-finish">
      <img
        src="/assets/payment/payment-finish.png"
        srcSet="/assets/payment/payment-finish@2x.png 2x, /assets/payment/payment-finish@3x.png 3x"
        alt=""
      />
      <div className="title">신청이 완료되었습니다!</div>
      <div className="sub">프로젝트 신청이 완료되었습니다!</div>

      <div className="finish-info-card">
        <div className="period-section">
          <div className="title">결제일</div>
          <div className="period">{formatDate(prjt_type_cd ==="prjt" ? prjt_apply_st_at:Date.now(), "-")}</div>
        </div>
        <div className="line" />
        <div className="sub">
          {prjt_type_cd === "prjt" ? (
            <>
              (유료)프로젝트의 참가비 결제는 선정 후 진행됩니다.
              <br /> 결제당일까지는 전액환불이 가능합니다. <br />
              결제일 이후에는 취소 수수료가 발생할 수 있습니다.
            </>
          ) : (
            <>
              밋업 결제 당일에는 전액 환불됩니다.
              <br />
              결제일 이후에는 취소 수수료가 발생할 수 있습니다.
            </>
          )}
        </div>
      </div>

      <div className="finish-btn-section">
        <button
          className="my-payment-btn"
          onClick={() => {
            navigate("/my/payment");
          }}
        >
          내 결제내역 보기
        </button>
        <button
          className="main-btn"
          onClick={() => {
            navigate("/");
          }}
        >
          메인으로
        </button>
      </div>
    </div>
  );
};

export default PaymentFinish;
