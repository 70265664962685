import React, { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import UseUserCommon from "../../hooks/UserUserCommon";
import { authinterface } from "../../interface/interface";
import { RootState } from "../../reducers";
import "./css/index.css";

interface stateface {
  img: string;
  login_nm: string;
  info_desc: string;
  link: string;
  tag_lst: { tag_nm: string }[];
}
interface actionface {
  payload: string;
  keyword: { tag_nm: string }[];
  type: string;
}
var info = {
  img: "",
  login_nm: "",
  info_desc: "",
  link: "",
  tag_lst: [],
};

function reducer(state: stateface, { payload, type, keyword }: actionface) {
  switch (type) {
    case "img":
      return { ...state, img: payload };
    case "nickname":
      return { ...state, login_nm: payload };
    case "intro":
      return { ...state, info_desc: payload };
    case "link":
      return { ...state, link: payload };
    case "keyword":
      return { ...state, tag_lst: keyword };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

function FirstInfo() {
  const reduxPatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, info);
  const auth = useSelector<RootState, authinterface>((state) => state.auth);
  const [FixUserInfo] = UseUserCommon({ user_info: auth });

  return (
    <main className="first-info">
      <Outlet
        context={{
          state,
          dispatch,
          usr_id: auth.usr_id,
          FixUserInfo,
          reduxPatch,
        }}
      />
    </main>
  );
}

export default FirstInfo;
