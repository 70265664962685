import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clientApi } from "../../api/config";
import { ToastChange } from "../../reducers/config";
import "./css/index.css";

interface LocationType {
  state: {
    usr_email: string;
    tit: string;
    msg: string;
    url: string;
  };
}

function ResendMail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation() as LocationType;
  const [isDisabled, setIsDisabled] = useState(false);
  const __resned = useCallback(() => {
    clientApi
      .post("/auth/usr/sendmail", state)
      .then(({ data: { sts_fg, err_msg } }) => {
        if (sts_fg) {
          dispatch(
            ToastChange({
              active: true,
              content: "메일함을 확인해주세요",
            })
          );
          setIsDisabled(true);
        } else {
          dispatch(ToastChange({ active: true, content: err_msg }));
        }
      })
      .catch(() => {
        dispatch(
          ToastChange({
            active: true,
            content: "네트워크 연결이 불안정합니다",
          })
        );
      });
  }, [state, dispatch]);
  useEffect(() => {
    if (!state) {
      alert("잘못된 접근입니다.");
      navigate("/");
    }

    return () => {};
  }, [navigate, state]);

  return (
    <main className="resend-mail">
      <div className="wrapper">
        <div className="tag">비밀번호 재설정</div>
        <div className="title">
          전송된 링크에서 <br /> 비밀번호를 재설정해주세요
        </div>
        <div className="box">
          <img
            src="/assets/login/mail.png"
            srcSet="/assets/login/mail@2x.png 2x, /assets/login/mail@3x.png 3x"
            alt=""
          />
          <div className="email">
            <span>{state.usr_email}</span> <br />
            에서 확인해주세요
          </div>
        </div>
        <button className="re-send" onClick={__resned} disabled={isDisabled}>
          <div className="sub">메일이 도착하지 않았나요?</div>
          <div className="title">메일 재전송하기</div>
        </button>
        <Link to={"/"} className="nav-main">
          메인으로 돌아가기
        </Link>
      </div>
    </main>
  );
}

export default ResendMail;
