import React from "react";
import { ProjectCardInter } from "../../../../interface/interface";
import { formatDate } from "../../../../lib/lib";

type Props = {
  data: ProjectCardInter;
  download: ((e: string, title: string) => void) | undefined;
  type: "project" | "support" | "certification";
  NavProject: ((category: string, id: string) => void) | undefined;
  NavSupport: ((e: ProjectCardInter) => void) | undefined;
};

const ProfileCard = ({
  data,
  NavProject,
  type,
  download,
  NavSupport,
}: Props) => {
  return (
    <div
      className={`profile-card-comp profile-card-${type}`}
      onClick={() => {
        if (data && NavProject) {
          NavProject("project", data.prjt_id);
        }
        if (data && NavSupport) {
          NavSupport(data);
        }
      }}
    >
      <div
        className="profile-card-img-wrapper"
        style={{
          backgroundImage: `url(${data.prjt_thumbnail})`,
        }}
      />
      <div className="card-content-section">
        <div className="profile-card-title">{data.prjt_nm}</div>
        {type !== "certification" ? (
          <div className="profile-card-time">
            {formatDate(data.rcrt_apply_en_at, "-")}
          </div>
        ) : (
          <button
            className="download-link"
            onClick={() => {
              if (download) {
                download(data.file_thumbnail, data.prjt_nm);
              }
            }}
          >
            다운로드
          </button>
        )}
      </div>
      {type !== "certification" ? (
        <img
          src="/assets/common/grey-arrow.svg"
          alt=""
          className="grey-arrow"
        />
      ) : undefined}
    </div>
  );
};

export default ProfileCard;
