import React from "react";
import "./css/index.css";
type Props = {
  isMobile: boolean;
  DeskTop: ({ children }: { children: JSX.Element }) => JSX.Element | null;
  Tablet: ({ children }: { children: JSX.Element }) => JSX.Element | null;
  Mobile: ({ children }: { children: JSX.Element }) => JSX.Element | null;
};

const AboutSection1 = ({ isMobile, DeskTop, Tablet, Mobile }: Props) => {
  return (
    <div className="about-section1">
      <div className="wrapper">
        {isMobile ? (
          <div className="title">
            여전히 구인기업과
            <br /> 구직자의 채용불편은
            <br />
            <span>해소되지 않고 있습니다</span>
          </div>
        ) : (
          <div className="title">
            여전히 구인기업과 구직자의 <br />
            채용불편은 <span>해소되지 않고 있습니다</span>
          </div>
        )}
        <div className="img-box">
          <div className="top">
            <div className="top-left">
              <div className="quote">
                <img src="/assets/common/about/top.svg" alt=""></img>
              </div>
              <div className="text">
                기업과 구직자 모두 <br />
                <span>
                  직무역량을{" "}
                  <Mobile>
                    <br />
                  </Mobile>
                  중요
                </span>{" "}
                하게 생각!
              </div>
              <div className="quote">
                <img src="/assets/common/about/bottom.svg" alt="" />
              </div>
            </div>
            <div className="top-right">
              <img src="/assets/common/about/section1-image-girl.png" alt="" />
            </div>
          </div>
          <div className="line" />
          <div className="bottom">
            <div className="graph-box">
              <div className="graph-top">
                <div className="left">
                  <img
                    src="/assets/common/about/section1-image-left.png"
                    srcSet="/assets/common/about/section1-image-left@2x.png 2x, /assets/common/about/section1-image-left@3x.png 3x"
                    alt=""
                  ></img>
                </div>
                <div className="right">
                  대기업 채용 담당자
                  {!isMobile ? <br /> : " "}10명 중 7명 <br />{" "}
                  <span>필요 역량 갖춘 인재 없다</span>
                </div>
              </div>
              <div className="graph-bottom">
                *소프트웨어 정책연구소,
                <Mobile>
                  <br />
                </Mobile>
                대·중소기업2만1617곳 대상 설문조사,2018
              </div>
            </div>
            <div className="graph-box">
              <div className="graph-top">
                <div className="left">
                  <img
                    src="/assets/common/about/section1-image-right.png"
                    srcSet="/assets/common/about/section1-image-right@2x.png 2x, /assets/common/about/section1-image-right@3x.png 3x"
                    alt=""
                  ></img>
                </div>
                <div className="right">
                  구직자 10명 중 9명 <br />{" "}
                  <span>
                    직무역량 쌓기 {!isMobile ? <br /> : " "}
                    너무 어렵다
                  </span>
                </div>
              </div>
              <div className="graph-bottom">
                *사람인,신입 구직자 1166명 대상 설문조사, 2021년
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection1;
