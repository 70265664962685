import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CommnetInter, ReplyInter } from "../../interface/interface";
import { encrypto, formatDate } from "../../lib/lib";
import "./css/index.css";
import Reply from "./Reply/Reply";
const Comment: React.FunctionComponent<{
  data: CommnetInter;
  leadr_usr_id: string;
  usr_id: string;
  __report: (cid: string, content: string) => void;
  __reply: (commentid: string) => void;
  __remove: (id: string) => void;
  type: "ASK" | "RECOMMEND" | "TEAMPL";
}> = ({
  __remove,
  type,
  usr_id,
  data: {
    ctts_id,
    ctts_ctts,
    ctts_reg_login_nm,
    ctts_reg_at,
    usr_thumbnail,
    ctts_cmmt_lst,
    ctts_reg_usr_id,
    isalertblock,
  },
  leadr_usr_id,
  __report,
  __reply,
}) => {
  const [ReplyData, setReplyData] = useState<ReplyInter[]>([]);
  useEffect(() => {
    if (ctts_cmmt_lst) {
      const form = ctts_cmmt_lst.map(({ value }) => {
        return JSON.parse(value);
      });
      setReplyData(form);
    }
    return () => {};
  }, [ctts_cmmt_lst]);

  return (
    <>
      <div
        className={`comment ${
          leadr_usr_id === ctts_reg_usr_id ? "lment" : ""
        } ${isalertblock === "BLOCK" ? "block-comment" : ""}
      `}
      >
        {usr_id ? (
          <div className="btn-wrapper">
            {(type !== "ASK" || (type === "ASK" && leadr_usr_id === usr_id)) &&
            isalertblock !== "BLOCK" ? (
              <button
                onClick={() => {
                  __reply(ctts_id);
                }}
              >
                답글
              </button>
            ) : undefined}
            {usr_id === ctts_reg_usr_id ? (
              <button
                onClick={() => {
                  __remove(ctts_id);
                }}
              >
                삭제
              </button>
            ) : isalertblock !== "BLOCK" ? (
              <button
                onClick={() => {
                  __report(ctts_id, ctts_ctts);
                }}
              >
                신고
              </button>
            ) : undefined}
          </div>
        ) : undefined}
        <Link
          className="profile-wrapper"
          to={`/profile/${encrypto(ctts_reg_usr_id)}`}
        >
          <div
            className="img-wrapper"
            style={{ backgroundImage: `url(${usr_thumbnail})` }}
          />
          <div className="right">
            <div className="nickname">{ctts_reg_login_nm}</div>
            <div className="grade">
              {leadr_usr_id === ctts_reg_usr_id ? "팀플리더" : "팀플러"}
            </div>
          </div>
        </Link>
        <div className="content">
          {isalertblock !== "BLOCK"
            ? ctts_ctts
            : "해당 댓글은 블라인드 처리된 댓글입니다"}
        </div>

        <div className="timestamp">{formatDate(ctts_reg_at, "/")}</div>
      </div>
      <div className="reply-list">
        {ReplyData.map((item, idx) => {
          return (
            <Reply
              key={idx}
              data={item}
              leadr_usr_id={leadr_usr_id}
              cid={ctts_id}
              __reply={__reply}
              __report={__report}
              __remove={__remove}
              usr_id={usr_id}
            />
          );
        })}
      </div>
    </>
  );
};

export default Comment;
