import React, { useState } from "react";
import UseGetCommon from "../../hooks/UseGetCommon";
import AboutNoticeCard from "./AboutNoticeCard";

const AboutNotice = () => {
  const [data] = UseGetCommon({ path: "/web/cmnt/2?bbs_id=3", limit: 0 });
  const [now, setnow] = useState<number>(0);
  const [scroll, setscroll] = useState<number>(0);
  const NoticeData = data.slice(0, 9);
  return (
    <div className="section5">
      <div className="wrapper">
        <div className="title-wrapper">
          <div className="title">팀플 소식</div>
        </div>
        <div className="list-wrapper">
          {now !== 0 ? (
            <div className="img-left">
              <img
                src="assets/common/about/left-menu.svg"
                alt=""
                onClick={() => {
                  setnow(now - 1);
                }}
              ></img>
            </div>
          ) : undefined}
          <div
            className="list"
            onScroll={(e) => {
              const width = e.currentTarget.scrollWidth;
              const left = e.currentTarget.scrollLeft;
              setscroll((100 * left) / (width - e.currentTarget.clientWidth));
            }}
          >
            <div
              className="box-wrapper"
              style={{ transform: `translateX(-${now * 1050}px)` }}
            >
              {NoticeData.map((item: any, index: number) => {
                return <AboutNoticeCard key={index} data={item} />;
              })}
            </div>
          </div>
          {Math.ceil(NoticeData.length / 3) > now + 1 ? (
            <div className="img-right">
              <img
                src="assets/common/about/right-menu.svg"
                alt=""
                onClick={() => {
                  setnow(now + 1);
                }}
              />
            </div>
          ) : undefined}
        </div>
        {NoticeData.length > 3 ? (
          <div className="scroll-mb">
            <div
              className="scroll-mb-blue"
              style={{
                marginLeft: `${scroll < 90 ? scroll : 90}%`,
              }}
            ></div>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default AboutNotice;
