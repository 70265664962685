import React from "react";
// import More from "../../../components/More/More";
import MtupPlace from "../../../components/MtupPlace/MtupPlace";
import RefundNotice from "../../../components/RefundNotice/RefundNotice";
import Screen from "../../../components/Screen/Screen";
import {
  detailtype,
  MeetupBasicDataType,
  ProjectBasicDataType,
} from "../../../interface/interface";

function Info({
  type,
  data,
  __navPayment,
  isApply,
}: {
  type: String;
  data: ProjectBasicDataType | MeetupBasicDataType;
  __navPayment: (e: detailtype | undefined) => void;
  isApply: boolean | undefined;
}) {
  const {
    leadr_thumbnail,
    leadr_ptfo_ctts,
    leadr_ptfo_tit,
    leadr_ptfo_link,
    prjt_ctts,
    rcrt_lst,
    rwrd_lst,
    pnlt_lst,
    prjt_type_cd,
    mtup_plc_basic,
    mtup_plc_detail,
    // prjt_sts_cd,
  } = data;

  return (
    <div className="info-section">
      <div className="leader-section">
        <div className="wrapper">
          <div className="title">팀 리더 소개</div>
          <img src={leadr_thumbnail} alt="" />
          <div className="leader-title">{leadr_ptfo_tit}</div>
          <div className="sub">{leadr_ptfo_ctts}</div>
          <a href={leadr_ptfo_link} target={"_blank"} rel="noreferrer">
            <div className="left-wrapper">
              <div className="icon-wrapper">
                <img src="/assets/common/link.svg" alt="" />
              </div>
              <div className="title">포트폴리오 확인하기</div>
            </div>
            <img src="/assets/common/grey-arrow.svg" alt="" className="arrow" />
          </a>
        </div>
      </div>
      <div className="intro-section">
        <div className="wrapper">
          {prjt_type_cd === "prjt" && rcrt_lst ? (
            <div className="field-wrapper">
              <div className="title">모집분야 및 인원</div>
              <div className="field-list">
                {rcrt_lst.map((item, idx) => {
                  const {
                    rcrt_nm,
                    rcrt_ctts,
                    rcrt_type_cd,
                    rcrt_usr_cnt,
                    rcrt_apply_usr_cnt,
                  } = item;
                  return (
                    <div className="field-card" key={idx}>
                      <img
                        src={`/assets/illust/${rcrt_type_cd}.svg`}
                        alt=""
                        className="category-icon"
                      />
                      <div className="left">
                        <div className="title">{rcrt_nm}</div>
                        <div className="people">
                          {rcrt_apply_usr_cnt}명 지원
                          <span> / {rcrt_usr_cnt}명 선정</span>
                        </div>
                        <div className="sub">{rcrt_ctts}</div>
                      </div>
                      <button
                        className="insert"
                        disabled={isApply}
                        onClick={() => {
                          __navPayment(item);
                        }}
                      >
                        신청하기
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : undefined}
          <div className="section-title">
            {type === "project" ? "팀 프로젝트 소개" : "팀 밋업 소개"}
          </div>
          <Screen data={prjt_ctts} />
        </div>
      </div>
      {rwrd_lst && prjt_type_cd === "prjt" ? (
        <div className="reward-section">
          <div className="wrapper">
            <img src="/assets/common/flag.svg" alt="" />
            <div className="title">
              완주하면 <br /> 이런 것들을 보상받을 수 있어요!
            </div>
            <div className="reward-list">
              {rwrd_lst.map(({ bnfit_nm, bnfit_impt_fg }, idx) => {
                return (
                  <div
                    key={idx}
                    className="reward-card"
                    style={
                      bnfit_impt_fg
                        ? {
                            backgroundColor: "#B8FF6C",
                          }
                        : undefined
                    }
                  >
                    <img src="/assets/common/black-check.svg" alt="" />
                    {bnfit_nm}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : prjt_type_cd === "mtup" && mtup_plc_basic ? (
        <div className="penalty-section">
          <div className="wrapper">
            <MtupPlace basic={mtup_plc_basic} detail={mtup_plc_detail} />
          </div>
        </div>
      ) : undefined}
      <div className="penalty-section">
        <div className="wrapper">
          <div className="title">패널티 및 강퇴조건</div>
          <div className="penalty-list">
            {pnlt_lst.map(({ bnfit_nm }, idx) => {
              return (
                <div key={idx} className="box-line">
                  {bnfit_nm}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <RefundNotice type={prjt_type_cd} />
      <div className="grey-line" />
      {/* <More list={dummy} /> */}
    </div>
  );
}

export default Info;
