import React, { useEffect, useState } from "react";
import { formatDate } from "../../../lib/lib";
import { calendarDataType } from "../Calendar";

type Props = {
  item: number | undefined;
  selectDate: number | undefined;
  select: (date: number) => void;
  data: calendarDataType[];
  nowDate: Date;
};

const CalendarCard = ({ item, selectDate, select, data, nowDate }: Props) => {
  const [isData, setIsData] = useState<boolean>(false);
  useEffect(() => {
    if (item && data) {
      let cardDate = new Date(nowDate);
      cardDate.setDate(item);
      const Filter = data.filter(
        ({ schd_apply_st_at }) =>
          formatDate(schd_apply_st_at, "-") ===
          formatDate(Number(cardDate), "-")
      );
      setIsData(Filter.length > 0);
    }

    return () => {};
  }, [data, nowDate, item]);

  return (
    <button
      className={`calendar-date-card ${
        selectDate && selectDate === item ? "select-card" : ""
      }`}
      onClick={() => {
        if (item) {
          select(item);
        }
      }}
    >
      <span>{item ? item : ""}</span>
      {isData ? <div className="calendar-circle" /> : undefined}
    </button>
  );
};

export default CalendarCard;
