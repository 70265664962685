import { clientApi } from "./config";
import { NetworkError } from "./get_api";

const post_scrap_api = (
  body:
    | {
        prjt_id: string;
      }
    | { ctts_id: string }
) => {
  return clientApi
    .post(`/cmnt/ctts/fav`, body)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "",
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch(() => {
      return NetworkError;
    });
};
const remove_scrap_api = (id: string) => {
  return clientApi
    .delete(`/cmnt/ctts/fav/${id}`)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "",
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch(() => {
      return NetworkError;
    });
};
const get_scrap_api = () => {
  return clientApi
    .get(`/cmnt/ctts/fav`)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "",
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch(() => {
      return NetworkError;
    });
};

export { post_scrap_api, remove_scrap_api, get_scrap_api };
