import { clientApi } from "./config";
import { NetworkError } from "./get_api";

// type paymentType = {
//   apply_self_brif_1: string;
//   apply_self_brif_2: string;
//   crd_id: string;
//   cpon_id: string | undefined;
// };

const payment_submit = (prjt_id: string, rcrt_id: string, data: any) => {
  return clientApi
    .post(`/web/prjt/${prjt_id}/rcrt/${rcrt_id}/apply`, data)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch((err) => {
      return NetworkError;
    });
};

const payment_cancel = (prjt_id: string, rcrt_id: string, apply_id: string) => {
  return clientApi
    .delete(`/web/prjt/${prjt_id}/rcrt/${rcrt_id}/apply/${apply_id}`)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch((err) => {
      return NetworkError;
    });
};
const payment_refund = (prjt_id: string, rcrt_id: string, apply_id: string) => {
  return clientApi
    .post(`/web/prjt/${prjt_id}/rcrt/${rcrt_id}/req/refund`, {
      apply_id,
    })
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch((err) => {
      return NetworkError;
    });
};
const payment_crd_change = (
  prjt_id: string,
  rcrt_id: string,
  apply_id: string,
  crd_id: string,
  cpon_id: string
) => {
  return clientApi
    .post(`/web/prjt/${prjt_id}/rcrt/${rcrt_id}/chg`, {
      apply_id,
      crd_id,
      cpon_id,
    })
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch((err) => {
      return NetworkError;
    });
};
export { payment_submit, payment_cancel, payment_refund, payment_crd_change };
