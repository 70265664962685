import React from "react";
import { NavigateFunction } from "react-router-dom";
import { CommnetInter } from "../../interface/interface";
import { encrypto, formatDate } from "../../lib/lib";
import "./css/index.css";
function RoomCard({
  data: {
    ctts_tit,
    ctts_reg_at,
    ctts_reg_login_nm,
    usr_thumbnail,
    cnt_cmmt,
    ctts_id,
  },
  isLeader,
  navigate,
}: {
  data: CommnetInter;
  isLeader: boolean;
  navigate: NavigateFunction;
}) {
  return (
    <button
      className="room-card"
      style={isLeader ? { backgroundColor: "#E9EFFF" } : undefined}
      onClick={() => {
        navigate(`./detail/${encrypto(ctts_id)}`);
      }}
    >
      <div className="top">
        <div className="title">{ctts_tit}</div>
        <div className="time">{formatDate(ctts_reg_at, "-")}</div>
      </div>
      <div className="bottom">
        <div className="user-wrapper">
          <div
            className="img-wrapper"
            style={{ backgroundImage: `url(${usr_thumbnail})` }}
          />
          <div className="nickname">{ctts_reg_login_nm}</div>
        </div>
        <div className="length-wrapper">
          <div className="text">{cnt_cmmt}</div>
          <img src="/assets/room/comment.svg" alt="" />
        </div>
      </div>
    </button>
  );
}

export default RoomCard;
