import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CouponSection from "../../components/CouponSection/CouponSection";
import { RootState } from "../../reducers";
import "./css/index.css";

const navLayout = ["보유 내역", "사용 내역"];

type Props = {};

const MyCoupon = (props: Props) => {
  const ListRef = useRef<HTMLDivElement | null>(null);
  const usr_id = useSelector<RootState, string>((state) => state.auth.usr_id);
  const [nowIndex, setNowIndex] = useState<number>(0);
  useEffect(() => {
    const ref = ListRef.current;
    if (ref) {
      ref?.classList.remove("inside-ani");
      void ref.offsetWidth;
      ref?.classList.add("inside-ani");
    }
    return () => {};
  }, [nowIndex, ListRef]);

  return (
    <main className="mycoupon-page">
      <div className="wrapper">
        <div className="title">쿠폰 및 인비테이션</div>
      </div>
      <div className="nav-wrapper">
        <div className="btn-wrapper">
          {navLayout.map((item, idx) => {
            return (
              <button
                className={nowIndex === idx ? "active-nav" : ""}
                key={idx}
                onClick={() => {
                  setNowIndex(idx);
                }}
              >
                {item}
              </button>
            );
          })}
          <div className="nav-bar" style={{ left: `${nowIndex * 124}px` }} />
        </div>
      </div>
      <div className="wrapper">
        {nowIndex === 0 ? (
          <div className="coupon-section inside-ani" ref={ListRef}>
            <CouponSection
              usr_id={usr_id}
              type={undefined}
              stat="99"
              location="my"
            />
          </div>
        ) : (
          <div className="coupon-section inside-ani" ref={ListRef}>
            <CouponSection
              usr_id={usr_id}
              type={undefined}
              stat="0"
              location="my"
            />
          </div>
        )}
      </div>
    </main>
  );
};

export default MyCoupon;
