import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { clientApi } from "../../api/config";
import { decypto } from "../../lib/lib";
import LoginBtn from "../../components/LoginBtn/LoginBtn";
import LoginInput from "../../components/LoginInput/LoginInput";
import "./css/index.css";
import { useDispatch } from "react-redux";
import { ToastChange } from "../../reducers/config";
import { passwordreg } from "../Signup/Signup";

function FixPassword() {
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const id = location.split("/")[2];
  const [isFix, setIsFix] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const __fix = useCallback(() => {
    if (password === confirm && passwordreg.test(password)) {
      clientApi
        .post("/auth/usr/chgpw", {
          usr_id: String(
            decypto(location.substring(14 + id.length, location.length))
          ),
          login_pwd: password,
        })
        .then(({ data: { sts_fg, err_msg } }) => {
          if (sts_fg) {
            setIsFix(true);
          } else {
            dispatch(
              ToastChange({
                active: true,
                content: err_msg,
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            ToastChange({
              active: true,
              content: "네트워크 연결이 불안정합니다",
            })
          );
        });
    }
  }, [password, confirm, location, dispatch, id]);
  const __navMain = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const __navLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  return (
    <main className="fix-password">
      <div className="wrapper">
        <div className="id-wrapper">
          <div className="title">회원님의 아이디</div>
          <div className="id">{id}</div>
        </div>
        <div className="title">비밀번호를 재설정합니다</div>
        <div className="input-wrapper">
          <LoginInput
            title="비밀번호"
            type="password"
            placeholder="새로운 비밀번호 입력"
            setValue={setPassword}
            errorType={
              password && !passwordreg.test(password)
                ? "숫자, 영문, 특수문자 조합 최소 8자"
                : confirm && password !== confirm
                ? "비밀번호 확인이 일치하지 않습니다"
                : undefined
            }
            value={password}
            Check={undefined}
          />
          <LoginInput
            title="비밀번호 확인"
            type="password"
            placeholder="새로운 비밀번호 재입력"
            setValue={setConfirm}
            errorType={undefined}
            value={confirm}
            Check={undefined}
          />
        </div>
        <div className="btn-wrapper">
          <LoginBtn type="login" title="재설정하기" __submit={__fix} />
          <LoginBtn
            type="nav"
            title="홈페이지로 돌아가기"
            __submit={__navMain}
          />
        </div>
      </div>
      <div className={`finish-fix ${isFix ? "on" : ""}`}>
        <div className="wrapper">
          <div className="title">
            비밀번호가 <br /> 변경되었습니다
          </div>
          <img src="/assets/login/fix-password.svg" alt="" />
          <LoginBtn
            type="login"
            title="팀플 로그인하기"
            __submit={__navLogin}
          />
        </div>
      </div>
    </main>
  );
}

export default FixPassword;
