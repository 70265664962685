import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as ArrowSvg } from "./svg/white-arrow.svg";
import { ReactComponent as StopSvg } from "./svg/stop.svg";
import { ReactComponent as PlaySvg } from "./svg/play.svg";
import { ReactComponent as Btnsvg } from "./svg/btn-arrow.svg";
import "./css/index.css";
import UseGetAdd from "../../hooks/UseGetAdd";
export var reg = new RegExp(
  /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi // eslint-disable-line
);

function Banner() {
  const [AddData] = UseGetAdd({ type: "normal" });
  const [now, setNow] = useState<number>(1);
  const [DownX, setDownX] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<any>(undefined);
  const __swipe = useCallback(() => {
    if (AddData.length > 0) {
      const id = setTimeout(() => {
        setNow((prev) => (prev >= AddData.length ? 1 : prev + 1));
      }, 3500);
      setIntervalId(id);
    }
  }, [AddData]);
  const __left = useCallback(() => {
    if (now <= 1) {
      setNow(AddData.length);
    } else {
      setNow(now - 1);
    }
    if (intervalId) {
      clearTimeout(intervalId);
    }
  }, [now, AddData, intervalId]);
  const __right = useCallback(() => {
    if (now >= AddData.length) {
      setNow(1);
    } else {
      setNow(now + 1);
    }
    if (intervalId) {
      clearTimeout(intervalId);
    }
  }, [now, AddData, intervalId]);

  const __stop = useCallback(() => {
    if (intervalId) {
      clearTimeout(intervalId);
      setIntervalId(undefined);
    }
  }, [intervalId]);
  useEffect(() => {
    __swipe();
    return () => {};
  }, [__swipe, now]);
  return (
    <div
      className={`banner-section ${
        AddData.length > 0 && AddData[now - 1].bg_color
      }`}
    >
      <div className="wrapper">
        <div className="content-wrapper">
          <div
            className="banner-wrapper"
            onTouchStart={(e) => {
              setDownX(e.targetTouches[0].clientX);
            }}
            onTouchEnd={(e) => {
              const moveX = e.changedTouches[0].clientX;
              if (moveX < DownX) {
                if (DownX - moveX > 40) {
                  __right();
                }
              } else {
                if (moveX - DownX > 40) {
                  __left();
                }
              }
            }}
          >
            <div className="content">
              <div
                className="content-slider"
                style={{
                  gridTemplateColumns: `repeat(${AddData.length},100%)`,
                  transform: `translateX(-${(now - 1) * 100}%)`,
                }}
              >
                {AddData.map(
                  ({ ctts_tit, ctts_ctts, ctts_link, btn_label }, idx) => {
                    return (
                      <div key={idx} className="slide-content">
                        <div className="title">{ctts_tit}</div>
                        <div className="sub">{ctts_ctts}</div>
                        <a href={ctts_link} rel="noreferrer" target={"_blank"}>
                          {btn_label}
                          <Btnsvg />
                        </a>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="img-wrapper">
              <div
                className="img-slider"
                style={{
                  gridTemplateColumns: `repeat(${AddData.length},100%)`,
                  transform: `translateX(-${(now - 1) * 100}%)`,
                }}
              >
                {AddData.map(({ ad_thumbnail }, idx) => {
                  return (
                    <div
                      key={idx}
                      className="back-img"
                      style={{
                        backgroundImage: `url(${ad_thumbnail})`,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="slide-wrapper">
          <div className="index-wrapper">
            {now}/{AddData.length}
          </div>
          <div className="btn-wrapper">
            <ArrowSvg className="arrow left" onClick={__left} />
            {!intervalId ? (
              <PlaySvg className="stop" onClick={__swipe} />
            ) : (
              <StopSvg className="stop" onClick={__stop} />
            )}
            <ArrowSvg className="arrow right" onClick={__right} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
