import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useOutletContext } from "react-router-dom";
import { remove_comment_api } from "../../../api/comment_api";
import { Common_get_api } from "../../../api/get_api";
import RoomComment from "../../../components/Comment/RoomComment";
import RoomCommentWrite from "../../../components/RoomCommentWrite/RoomCommentWrite";
import { CommnetInter, ProjectCardInter } from "../../../interface/interface";
import { decypto, FileDown, formatDate } from "../../../lib/lib";
import { RootState } from "../../../reducers";
import { PopupChange, ToastChange } from "../../../reducers/config";
import { Remove_Comment_Id } from "../../../reducers/data";
function RoomDetail() {
  const dispatch = useDispatch();
  const {
    usr_id,
    pathname,
    nowPrj,
  }: {
    nowPrj: ProjectCardInter;
    usr_id: string;
    pathname: string;
    navigate: NavigateFunction;
  } = useOutletContext();
  const id = decypto(pathname.split("/detail/")[1]);
  const remove_id = useSelector<RootState, string | undefined>(
    (state) => state.data.removeComment
  );
  const [info, setInfo] = useState<undefined | CommnetInter>(undefined);
  const [fixComment, setFixComment] = useState<undefined | CommnetInter>(
    undefined
  );
  const [comment, setComment] = useState<CommnetInter[]>([]);
  const [replyId, setReplyId] = useState<string | undefined>(undefined);
  const __reportPopup = useCallback(
    (cid: string) => {
      dispatch(
        PopupChange({
          type: "report",
          active: true,
          data: cid,
        })
      );
    },
    [dispatch]
  );
  const __replyWrite = useCallback((commentid: string) => {
    setReplyId(commentid);
  }, []);
  const getDetail = useCallback(() => {
    Common_get_api(`/web/cmnt/2/ctts/${id}`).then(({ state, data }) => {
      if (state) {
        setInfo(data);
        if (data.ctts_cmmt_lst) {
          const form = data.ctts_cmmt_lst.map((item: { value: string }) => {
            return JSON.parse(item.value);
          });
          // 계층 구조 부수기

          // 1계층 분리
          const clone = form
            .slice()
            .filter(({ ctts_lv }: { ctts_lv: number }) => ctts_lv === 1);
          const result = clone.map((data: CommnetInter, index: number) => {
            const { ctts_id, ctts_lv } = data;
            let reply_lst: any[] = [];
            if (ctts_lv === 1) {
              form.forEach((item: CommnetInter) => {
                const { up_cmmt_id } = item;
                if (ctts_id === up_cmmt_id) {
                  reply_lst.push(item);
                }
              });
            }
            return Object.assign({ reply_lst }, data);
          });
          setComment(result);
          setReplyId(undefined);
          setFixComment(undefined);
        }
      }
    });
  }, [id]);
  const RemovePost = useCallback(() => {
    dispatch(PopupChange({ active: true, data: id, type: "POST_REMOVE" }));
  }, [id, dispatch]);

  const RemovePopup = useCallback(
    (id: string) => {
      dispatch(
        PopupChange({
          type: "ROOM",
          data: id,
          active: true,
        })
      );
    },
    [dispatch]
  );
  const RemoveComment = useCallback(
    (id: string) => {
      remove_comment_api(id).then(({ state, data, msg }) => {
        if (state) {
          getDetail();
          dispatch(Remove_Comment_Id(undefined));
        } else {
          dispatch(ToastChange({ active: true, content: msg }));
        }
      });
    },
    [getDetail, dispatch]
  );
  useEffect(() => {
    if (remove_id) {
      RemoveComment(remove_id);
    }
    return () => {};
  }, [remove_id, dispatch, RemoveComment]);
  useEffect(() => {
    getDetail();
    return () => {};
  }, [getDetail]);

  return info ? (
    <div className="room-detail">
      <div className="title">
        <span>{info.ctts_tit}</span>
        {usr_id === info.ctts_reg_usr_id && (
          <div className="btn-wrapper">
            {/* <button
           onClick={()=>{
            navigate(`./${info.ctts_ctg_cd
}/fix/${pathname.split("/detail/")[1]}`)
           }}
          >수정</button> */}
            <button onClick={RemovePost}>삭제</button>
          </div>
        )}
      </div>
      <div className="profile-wrapper">
        <div className="left">
          <div
            className="img-wrapper"
            // style={{ backgroundImage: `url(${info.profile.img})` }}
          />
          <div className="nickname">
            <span>{nowPrj&& info.ctts_reg_usr_id === nowPrj.leadr_usr_id ? "팀플리더" : "팀플러"}</span>{" "}
            {info.ctts_reg_login_nm}
          </div>
        </div>
        <div className="right">{formatDate(info.ctts_reg_at, "-")}</div>
      </div>
      <div className="line" />
      <div className="content">{info.ctts_ctts}</div>
      <div className="a-wrapper">
        {info.link ? (
          <a href={info.link} target={"_blank"} rel="noreferrer">
            <div className="left">
              <div className="svg-wrapper">
                <img src="/assets/common/link.svg" alt="" />
              </div>
              <div className="title">{info.link}</div>
            </div>
            <img src="/assets/common/grey-arrow.svg" alt="" />
          </a>
        ) : undefined}
        {info.file_link ? (
          <button
            onClick={() => {
              FileDown(info.file_link, `${info.ctts_tit} 파일`);
            }}
          >
            <div className="left">
              <div className="svg-wrapper">
                <img src="/assets/common/blue-file.svg" alt="" />
              </div>
              <div className="title">파일 다운로드</div>
            </div>
            <img src="/assets/common/grey-arrow.svg" alt="" />
          </button>
        ) : undefined}
      </div>
      <div className="bold-line" />
      <RoomCommentWrite
        length={comment.length}
        ctts_id={info.ctts_id}
        prjt_id={nowPrj && nowPrj.prjt_id}
        getDetail={getDetail}
        reply_id={replyId}
        setfix={setFixComment}
        setReplyId={setReplyId}
        fixComment={fixComment}
      />
      <div className="line" />
      <div className="comment-wrapper">
        {comment.map((item) => {
          return (
            <RoomComment
              key={item.ctts_id}
              data={item}
              __report={__reportPopup}
              __reply={__replyWrite}
              __remove={RemovePopup}
              usr_id={usr_id}
              leadr_usr_id={nowPrj && nowPrj.leadr_usr_id}
              setfix={setFixComment}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default RoomDetail;
