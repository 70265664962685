import axios from "axios";

export const clientApi = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  // headers: {
  //   authorization: window.sessionStorage.getItem("accessToken"),
  // },
});

clientApi.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("accessToken");
  if (token) {
    const accessToken = JSON.parse(token).token;
    config.headers["Authorization"] = accessToken;
  }
  return config;
});
