import React from "react";
import "./css/index.css";
import { Link } from "react-router-dom";

type Props = {
  isTablet: boolean;
  DeskTop: ({ children }: { children: JSX.Element }) => JSX.Element | null;
  Tablet: ({ children }: { children: JSX.Element }) => JSX.Element | null;
  Mobile: ({ children }: { children: JSX.Element }) => JSX.Element | null;
};

const AboutMainSection = ({ isTablet, DeskTop, Tablet, Mobile }: Props) => {
  return (
    <div className="about-main-section">
      <div className="wrapper-title">
        <div className="left">
          <div className="title">수박 겉핥기식 부트캠프는 NOPE!</div>
          <div className="sub">
            현직 PM이 리더가 되는 <br />
            직무역량교육 기반
            <br /> 사이드프로젝트
          </div>
          <DeskTop>
            <div className="text">
              마이팀플은 현업 팀플리더와 팀플러가 함께 <br />
              최대 12주 동안 다양한 프로젝트를 함께 만들며 성장하는 <br />
              사이드프로젝트 팀매칭 플랫폼입니다.
            </div>
          </DeskTop>
          <Tablet>
            <div className="text">
              마이팀플은 현직자 PM 팀플리더와 크루 팀플러가
              <br /> 함께 최대 90일 동안 다양한 프로젝트를
              <br /> 함께 만들며 성장하는 사이드프로젝트
              <br /> 팀매칭 플랫폼입니다.
            </div>
          </Tablet>
          <Mobile>
            <div className="text">
              마이팀플은 현직자 PM 팀플리더와 크루 팀플러가 함께 <br />
              최대 90일 동안 다양한 프로젝트를 함께 만들며
              <br /> 성장하는 사이드프로젝트 팀매칭 플랫폼입니다.
            </div>
          </Mobile>
          <Link to="/project" className="button">
            <div className="title">
              {isTablet ? "프로젝트 보기" : "다양한 팀플 바로가기"}
            </div>
            <div className="img">
              <img src="/assets/common/about/right.svg" alt=""></img>
            </div>
          </Link>
        </div>
        <div className="right">
          <img
            src="/assets/common/about/main-image.png "
            alt=""
            className="pc"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutMainSection;
