import React from "react";
type Props = {
  intro: string;
  portfolio: string;
  keyword: { tag_nm: string }[];
};

const OtherInfo = ({ intro, portfolio, keyword }: Props) => {
  return (
    <div className="info-wrapper">
      <div className="basic-info other-info">
        <div className="wrapper">
          <div className="area-wrapper">
            <div className="title-wrapper">
              <div className="title">소개글</div>
            </div>
            <div className="intro-text">{intro}</div>
          </div>
        </div>
      </div>
      <div className="block" />
      <div className="portfolio-wrapper">
        <div className="wrapper">
          <div className="title-wrapper">
            <div className="title">포트폴리오</div>
          </div>
          <div className="box">
            <img src="/assets/profile/portfolio.svg" alt="" />
            <div className="title">나의 포트폴리오</div>
            <div className="link">{portfolio}</div>
            <a
              className={`firstinfo-btn`}
              href={`${portfolio}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="title">포트폴리오 이동</div>
              <img src="/assets/profile/white-arrow.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="block" />
      <div className="keyword-wrapper">
        <div className="wrapper">
          <div className="title-wrapper">
            <div className="title">나의 키워드</div>
          </div>
          <div className="keyword-array">
            {keyword.map(({ tag_nm }, idx) => {
              return (
                <div className="box" key={idx}>
                  <div className="title">#{tag_nm}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherInfo;
