import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmtyList from "../../components/EmtyList/EmtyList";
import Footer from "../../components/Footer/Footer";
import Infinite from "../../components/Infinite/Infinite";
import ListFilter from "../../components/ListFilter/ListFilter";
import MagazineCard from "../../components/MagazineCard/MagazineCard";
import UseGetCommon from "../../hooks/UseGetCommon";
import UseScrap from "../../hooks/UseScrap";
import { encrypto } from "../../lib/lib";
import Magazine from "../Magazine/Magazine";
import "./css/index.css";
type LocationType = {
  state: number | undefined;
};

const MagazineCategoryList = () => {
  const { state } = useLocation() as LocationType;
  const navigate = useNavigate();
  const [nowType, setNowType] = useState<number>(0);
  const [nowFilter, setNowFilter] = useState<undefined | number>(0);
  const [MagazineList, PagingUpdate] = UseGetCommon({
    path:
      nowType === 0
        ? ""
        : `/web/cmnt/2?bbs_id=4&ctts_ctg_cd=${
            navLayout[nowType].type
          }&row_sort_order=${
            filterLayout[nowFilter ? nowFilter : 0].type
          }&row_limit_cnt=9&ctts_stat_cd=ctts_sts_nrml`,
  });
  const [ScrapList, PostScarp, RemoveScarp] = UseScrap();
  const __navDetail = useCallback(
    (id: string) => {
      navigate(`/detail/magazine/${encrypto(id)}`);
    },
    [navigate]
  );

  useEffect(() => {
    const ref = document.getElementById("magazine-list");
    const root = document.getElementById("root");
    if (root) {
      root.scrollTo(0, 0);
    }
    if (ref) {
      ref.classList.remove("inside-ani");
      void ref.offsetWidth;
      ref.classList.add("inside-ani");
    }
    return () => {};
  }, [nowType]);
  useEffect(() => {
    if (state) {
      setNowType(state);
    }
    return () => {};
  }, [state]);

  return (
    <>
      <main className="magazine-category-list">
        <div className="wrapper">
          <div className="title">매거진</div>
        </div>
        <div className="nav-wrapper">
          <div className="nav-section">
            {navLayout.map(({ name, type }, idx) => {
              return (
                <button
                  key={idx}
                  className={`nav-btn ${idx === nowType ? "active-type" : ""}`}
                  onClick={() => {
                    setNowType(idx);
                  }}
                >
                  {name}
                </button>
              );
            })}
            <div className="nav-bar" style={{ left: `${95 * nowType}px` }} />
          </div>
        </div>
        {nowType === 0 ? (
          <Magazine NavLink={setNowType} />
        ) : (
          <div className="wrapper">
            <div className="magazine-filter-wrapper">
              <ListFilter
                list={filterLayout}
                now={
                  nowFilter !== undefined
                    ? filterLayout[nowFilter].title
                    : filterLayout[0].title
                }
                __update={setNowFilter}
                type="magazine"
              />
            </div>
            {MagazineList.length > 0 ? (
              <div
                className="magazine-card-wrapper inside-ani"
                id="magazine-list"
              >
                {MagazineList.map((item, idx) => {
                  return (
                    <MagazineCard
                      key={idx}
                      data={item}
                      __nav={__navDetail}
                      scrap={PostScarp}
                      unscrap={RemoveScarp}
                      isScrap={
                        item &&
                        ScrapList.mgn.lst.filter(
                          ({ ctts_id }) => item.ctts_id === ctts_id
                        ).length > 0
                      }
                    />
                  );
                })}
                <Infinite PagingUpdate={PagingUpdate} />
              </div>
            ) : (
              <EmtyList text="등록된 매거진이 없습니다" />
            )}
          </div>
        )}
      </main>
      <Footer />
    </>
  );
};

export default MagazineCategoryList;

const filterLayout = [
  {
    title: "최신순",
    type: "desc",
  },
  { title: "오래된순", type: "asc" },
];

const navLayout = [
  { name: "전체", type: "" },
  { name: "월간 팀플리더", type: "MGN_01" },
  { name: "워크스토리", type: "MGN" },
  { name: "팀플러 인터뷰", type: "MGN_02" },
];
