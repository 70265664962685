import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./css/index.css";
import { NavigateFunction } from "react-router-dom";
import LeaderIntro from "../LeaderIntro/LeaderIntro";

type Props = {
  sharpList: MgnCardType[];
  LeaderList: MgnCardType[];
  WorkList: MgnCardType[];
  NavLink?: Dispatch<SetStateAction<number>>;
  navigate?: NavigateFunction;
};

export type MgnCardType = {
  thumbnail: string;
  reader_usr_info: string;
  ctts_id: string;
  ctts_tit: string;
  ctts_ctg_cd: "MGN_01" | "MGN_02" | "MGN";
};

const MagazineSlideSection = ({
  sharpList,
  LeaderList,
  WorkList,
  NavLink,
  navigate,
}: Props) => {
  const [IntroList, setIntroList] = useState<MgnCardType[]>([]);
  const [now, setNow] = useState<number>(1);
  const [DownX, setDownX] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<any>(undefined);
  const __swipe = useCallback(() => {
    if (IntroList.length > 0) {
      const id = setTimeout(() => {
        setNow((prev) => (prev >= IntroList.length ? 1 : prev + 1));
      }, 3500);
      setIntervalId(id);
    }
  }, [IntroList]);
  const __left = useCallback(() => {
    if (now <= 1) {
      setNow(IntroList.length);
    } else {
      setNow(now - 1);
    }
    if (intervalId) {
      clearTimeout(intervalId);
    }
  }, [now, intervalId, IntroList]);
  const __right = useCallback(() => {
    if (now >= IntroList.length) {
      setNow(1);
    } else {
      setNow(now + 1);
    }
    if (intervalId) {
      clearTimeout(intervalId);
    }
  }, [now, intervalId, IntroList]);
  useEffect(() => {
    __swipe();
    return () => {};
  }, [__swipe, now]);

  useEffect(() => {
    setIntroList(
      [LeaderList[0], sharpList[0], WorkList[0]].filter((item) => item)
    );

    return () => {};
  }, [LeaderList, sharpList, WorkList]);

  return (
    <div
      className="magazine-slide-section"
      style={{
        gridTemplateColumns: `repeat(${IntroList.length},100%)`,
        transform: `translateX(-${(now - 1) * 100}%)`,
      }}
      onTouchStart={(e) => {
        setDownX(e.targetTouches[0].clientX);
      }}
      onTouchEnd={(e) => {
        const moveX = e.changedTouches[0].clientX;
        if (moveX < DownX) {
          if (DownX - moveX > 40) {
            __right();
          }
        } else {
          if (moveX - DownX > 40) {
            __left();
          }
        }
      }}
    >
      {IntroList.map((item, idx) => {
        return (
          <LeaderIntro
            data={item}
            key={idx}
            NavLink={NavLink}
            navigate={navigate}
          />
        );
      })}
    </div>
  );
};

export default MagazineSlideSection;
