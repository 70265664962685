import React, { useCallback } from "react";

function Marketing({ __cancel }: { __cancel: () => void }) {
  const __marketing = useCallback(() => {}, []);

  return (
    <div className="marketing">
      <img
        src="/assets/common/cancel.svg"
        alt=""
        className="cancel"
        onClick={__cancel}
      />
      <img
        src="/assets/popup/marketing.png"
        srcSet="/assets/popup/marketing@2x.png 2x , /assets/popup/marketing@3x.png 3x"
        alt=""
        className="main"
      />
      <div className="title">
        마케팅 수신 동의하고 <br /> 최신 기업 사이드 프로젝트 <br /> 정보를
        받아보시겠어요?
      </div>
      <div className="btn-wrapper">
        <button className="yes" onClick={__marketing}>
          수신 동의하기
        </button>
        <button className="no" onClick={__cancel}>
          다음에 할래요
        </button>
      </div>
    </div>
  );
}

export default Marketing;
