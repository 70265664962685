import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/index.css";
function Page_404() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("fixpassword")) {
      let path = pathname.substring(1);
      navigate(path);
      return;
    }
    setIsLoading(true);
    return () => {};
  }, [location]);

  if (isLoading) {
    return (
      <main className="page-404">
        <div className="wrapper">
          <div className="symbol">
            <img
              src="/assets/login/finish.png"
              srcSet="/assets/login/finish@2x.png 2x, /assets/login/finish@3x.ppng 3x"
              alt=""
            />
          </div>
          <div className="slogan txt-bold">
            요청한 페이지를 찾을 수 없습니다
          </div>
          <div className="slogan2">
            {` 찾으려는 페이지의 주소가 잘못 입력되었거나,
          주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
          입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.`}
          </div>
          <a href="/">메인으로</a>
        </div>
      </main>
    );
  }
  return <></>;
}

export default Page_404;
