import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmtyList from "../../components/EmtyList/EmtyList";
import ListCard from "../../components/ListCard/ListCard";
import MeetupCard from "../../components/ListCard/MeetupCard";
import MagazineCard from "../../components/MagazineCard/MagazineCard";
import UseScrap from "../../hooks/UseScrap";
import { encrypto } from "../../lib/lib";
import "./css/index.css";

const category = ["project", "meetup", "magazine"];

type Props = {};

const MyScrap = (props: Props) => {
  const navigate = useNavigate();
  const ListRef = useRef<null | HTMLDivElement>(null);
  const [ScrapList, PostScarp, RemoveScarp] = UseScrap();
  const [nowIndex, setNowIndex] = useState<number>(0);
  const __nav = useCallback(
    (id: string) => {
      navigate(`/detail/${category[nowIndex]}/${encrypto(id)}`);
    },
    [navigate, nowIndex]
  );
  useEffect(() => {
    if (ListRef && ListRef.current) {
      ListRef.current.classList.remove("inside-ani");
      void ListRef.current.offsetWidth;
      ListRef.current.classList.add("inside-ani");
    }
    return () => {};
  }, [nowIndex]);

  return (
    <main className="my-scrap-page">
      <div className="wrapper">
        <div className="title">스크랩 목록</div>
      </div>
      <div className="nav-wrapper">
        <div className="button-wrapper">
          {navLayout.map((item, idx) => {
            return (
              <button
                key={idx}
                className={nowIndex === idx ? "active-btn" : ""}
                onClick={() => {
                  setNowIndex(idx);
                }}
              >
                {item}
              </button>
            );
          })}
          <div className="nav-line" style={{ left: `${nowIndex * 114}px` }} />
        </div>
      </div>

      {nowIndex === 0 ? (
        ScrapList.prjt.lst ? (
          <div className="scrap-section wrapper inside-ani" ref={ListRef}>
            {ScrapList.prjt.lst.map((item, idx) => {
              return (
                <ListCard
                  key={idx}
                  data={item}
                  __nav={__nav}
                  scrap={PostScarp}
                  unscrap={RemoveScarp}
                  isScrap={
                    ScrapList.prjt.lst.filter(
                      ({ prjt_id }) => item.prjt_id === prjt_id
                    ).length > 0
                  }
                />
              );
            })}
          </div>
        ) : (
          <div className="wrapper">
            <EmtyList text="스크랩 목록이 없습니다" />
          </div>
        )
      ) : nowIndex === 1 ? (
        ScrapList.mtup.lst ? (
          <div className="scrap-section wrapper inside-ani" ref={ListRef}>
            {ScrapList.mtup.lst.map((item, idx) => {
              return (
                <MeetupCard
                  key={idx}
                  data={item}
                  __nav={__nav}
                  scrap={PostScarp}
                  unscrap={RemoveScarp}
                  isScrap={
                    ScrapList.mtup.lst.filter(
                      ({ prjt_id }) => item.prjt_id === prjt_id
                    ).length > 0
                  }
                />
              );
            })}
          </div>
        ) : (
          <div className="wrapper">
            <EmtyList text="스크랩 목록이 없습니다" />
          </div>
        )
      ) : ScrapList.mgn.lst.length > 0 ? (
        <div className="scrap-section wrapper inside-ani" ref={ListRef}>
          {ScrapList.mgn.lst.map((item, idx) => {
            return (
              <MagazineCard
                key={idx}
                data={item}
                __nav={__nav}
                scrap={PostScarp}
                unscrap={RemoveScarp}
                isScrap={
                  ScrapList.mgn.lst.filter(
                    ({ ctts_id }) => item.ctts_id === ctts_id
                  ).length > 0
                }
              />
            );
          })}
        </div>
      ) : (
        <div className="wrapper">
          <EmtyList text="스크랩 목록이 없습니다" />
        </div>
      )}
    </main>
  );
};

export default MyScrap;

const navLayout = ["프로젝트", "밋업", "매거진"];
