import React from "react";
import { Link } from "react-router-dom";
import { encrypto } from "../../lib/lib";
import "./css/index.css";
type Props = {
  data: {
    login_nm: string;
    apply_usr_id: string;
    usr_thumbnail: string;
    tags: { value: string }[];
    rcrt_nm: string;
  };
  usr_id: string;
};

const MemberCard = ({
  data: { login_nm, apply_usr_id, usr_thumbnail, tags, rcrt_nm },
  usr_id,
}: Props) => {
  return (
    <Link
      className="member-card"
      to={
        usr_id !== apply_usr_id
          ? `/profile/${encrypto(apply_usr_id)}`
          : "/my/page"
      }
    >
      <div className="member-card-left">
        <div
          className="img-wrapper"
          style={{ backgroundImage: `url(${usr_thumbnail})` }}
        />

        <div className="nickname-wrapper">
          <div className="nickname">
            {login_nm}
            <span>{rcrt_nm}</span>
          </div>
          <div className="keywords">
            {tags &&
              tags.map((item, idx) => {
                return <span key={idx}> #{JSON.parse(item.value).tag_nm}</span>;
              })}
          </div>
        </div>
      </div>
      <div className="member-card-right">
        <div className="profile-text">프로필</div>
        <img src="/assets/common/grey-arrow.svg" alt="" />
      </div>
    </Link>
  );
};

export default MemberCard;
