import React from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import EmtyList from "../../components/EmtyList/EmtyList";
import Infinite from "../../components/Infinite/Infinite";
import UseGetCommon from "../../hooks/UseGetCommon";
import { encrypto } from "../../lib/lib";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./css/index.css";
function Alliance() {
  const navigate = useNavigate();
  const [Data, PagingUpdate] = UseGetCommon({
    path: "/web/cstm/prtn?row_limit_cnt=10",
    limit: 3,
  });
  return (
    <main className="alliance">
      <div className="wrapper">
        <div className="title">템플러 제휴 및 혜택</div>
        <div className="box">
          <div className="sub">✓ 제휴는 협력상황에 따라 바뀔 수 있습니다.</div>
          <div className="sub">
            ✓ 협력사별 제휴 승인법이 다름으로 본문 참고바랍니다.
          </div>
        </div>
        {Data.length > 0 ? (
          <div className="alliance-list">
            {Data.map((item, idx) => {
              return (
                <div className="alliance-box" key={idx}>
                  {item ? (
                    <AllianceContent data={item} navigate={navigate} />
                  ) : (
                    <>
                      <Skeleton containerClassName="left" height={"100%"} />
                      <div
                        className="right"
                        style={{
                          width: "100%",
                          rowGap: "unset",
                        }}
                      >
                        <Skeleton
                          containerClassName="top"
                          height={"100%"}
                          width="100%"
                          count={2}
                        />
                        <Skeleton
                          containerClassName="bottom"
                          height={"34px"}
                          width={"121px"}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
            <Infinite PagingUpdate={PagingUpdate} />
          </div>
        ) : (
          <EmtyList text="등록된 제휴가 없습니다" />
        )}
      </div>
    </main>
  );
}

export default Alliance;

const AllianceContent = ({
  data,
  navigate,
}: {
  data: any;
  navigate: NavigateFunction;
}) => {
  const { prtn_nm, prtn_id, prtn_desc, thumbnail } = data;
  return (
    <>
      <div className="left" style={{ backgroundImage: `url(${thumbnail})` }} />
      <div className="right">
        <div className="top">
          {prtn_nm} <br />
          <span>{prtn_desc}</span>
        </div>
        <div className="bottom">
          <div
            className="bottom "
            onClick={() => {
              navigate(`/alliance/detail/${encrypto(prtn_id)}`, {
                state: data,
              });
            }}
          >
            <div className="text">자세히보기</div>
            <div className="img">
              <img src="/assets/common/right-arrow.svg" alt="arrow" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
