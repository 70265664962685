import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Comment from "../../../components/Comment/Comment";
import CommentWrite from "../../../components/Comment/Write/CommentWrite";
import EmtyList from "../../../components/EmtyList/EmtyList";
import Infinite from "../../../components/Infinite/Infinite";
import UseCommoent from "../../../hooks/UseCommoent";
import {
  authinterface,
  CommnetInter,
  MeetupBasicDataType,
  ProjectBasicDataType,
} from "../../../interface/interface";
import { encrypto } from "../../../lib/lib";
import { PopupChange } from "../../../reducers/config";

const Ask: React.FunctionComponent<{
  data: ProjectBasicDataType | MeetupBasicDataType;
  auth: authinterface;
  id: string;
}> = ({ data: { leadr_usr_nm, leadr_usr_id, leadr_thumbnail }, auth, id }) => {
  const dispatch = useDispatch();
  const [Data, WriteComment, isWrite, star, PagingUpdate] = UseCommoent({
    path: `/web/cmnt/1?bbs_id=1&ctts_ctg_cd=ASK&prjt_id=${id}&row_limit_cnt=10`,
    dispatch,
  });
  const [inputType, setInputType] = useState<string | undefined>(undefined);
  const [replyId, setReplyId] = useState<string | undefined>(undefined);
  const __updateCommnet = useCallback(
    (e: string, type: string) => {
      setInputType(undefined);
      if (type === "comment") {
        WriteComment({
          bbs_id: "1",
          prjt_id: String(id),
          ctts_ctts: e,
          ctts_ctg_cd: "ASK",
        });
      } else {
        WriteComment({
          bbs_id: "1",
          prjt_id: id,
          ctts_ctts: e,
          ctts_ctg_cd: "ASK",
          up_ctts_id: replyId,
        });
      }
    },
    [replyId, WriteComment, id]
  );
  const RemovePopup = useCallback(
    (id: string) => {
      dispatch(
        PopupChange({
          type: "ASK",
          data: id,
          active: true,
        })
      );
    },
    [dispatch]
  );

  const __updateCancel = useCallback(() => {
    setInputType(undefined);
  }, []);
  const __replyWrite = useCallback((commentid: string) => {
    setInputType("reply");
    setReplyId(commentid);
  }, []);

  const __reportPopup = useCallback(
    (cid: string, content: string) => {
      dispatch(
        PopupChange({
          type: "report",
          active: true,
          data: cid,
        })
      );
    },
    [dispatch]
  );

  return (
    <div className="ask-section">
      <div className="top">
        <div className="wrapper">
          <div className="title">
            무엇이 궁금한가요? <br /> 팀플리더에게 자유롭게 질문해보세요
          </div>
          <div className="basic-card">
            <Link
              className="profile-wrapper"
              to={`/profile/${encrypto(leadr_usr_id)}`}
            >
              <div
                className="img-wrapper"
                style={{ backgroundImage: `url(${leadr_thumbnail})` }}
              />
              <div className="right">
                <div className="name">{leadr_usr_nm}</div>
                <div className="grade">팀플리더</div>
              </div>
            </Link>
            <div className="content">
              무엇이 궁금하신가요? 자유롭게 질문해보세요
            </div>
          </div>
          <div className="comment-wrapper">
            {Data.length > 0 ? (
              <>
                {Data.map((item: CommnetInter) => {
                  return (
                    <Comment
                      key={item.ctts_id}
                      data={item}
                      leadr_usr_id={leadr_usr_id}
                      usr_id={auth.usr_id}
                      __report={__reportPopup}
                      __remove={RemovePopup}
                      __reply={__replyWrite}
                      type="ASK"
                    />
                  );
                })}
                <Infinite PagingUpdate={PagingUpdate} />
              </>
            ) : (
              <EmtyList text="문의 목록이 없습니다" />
            )}
            {/* {Data.length >0 ? 
            
            :} */}
          </div>
        </div>
      </div>
      {auth.usr_id ? (
        <div className="insert-bottom">
          <div className="wrapper">
            <div
              className="img-wrapper"
              style={{ backgroundImage: `url(${auth.info.img})` }}
            />
            <button
              onClick={() => {
                setInputType("comment");
              }}
            >
              무엇이 궁금하신가요?
            </button>
          </div>
        </div>
      ) : undefined}
      {inputType ? (
        <CommentWrite
          __update={__updateCommnet}
          __cancel={__updateCancel}
          type={inputType}
          category="ask"
        />
      ) : undefined}
    </div>
  );
};

export default Ask;
