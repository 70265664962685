import React, { useEffect, useState } from "react";
import { Link, NavigateFunction, useOutletContext } from "react-router-dom";
import { Common_get_api } from "../../../api/get_api";
import EmtyList from "../../../components/EmtyList/EmtyList";
import RoomCard from "../../../components/RoomCard/RoomCard";
import { CommnetInter, ProjectCardInter } from "../../../interface/interface";

function RoomList() {
  const {
    nowPrj,
    navigate,
  }: { nowPrj: ProjectCardInter; navigate: NavigateFunction } =
    useOutletContext();
  const [data, setData] = useState<CommnetInter[]>([]);
  useEffect(() => {
    if (nowPrj) {
      Common_get_api(
        `/web/cmnt/1?bbs_id=1&ctts_ctg_cd=ACT&prjt_id=${nowPrj.prjt_id}&row_limit_cnt=100`
      ).then(({ state, data }) => {
        if (state) {
          setData(data.lst);
        }
      });
    }
    return () => {};
  }, [nowPrj]);
  return data ? (
    <>
      <div className="room-list">
        <div className="search-wrapper">
          <div className="left">
            <div className="title">팀플룸</div>
            <div className="sub">참여 팀플러 커뮤니티</div>
          </div>
          <div className="right">
            <input type="text" placeholder="검색하기" />
            <img src="/assets/common/search.svg" alt="" />
          </div>
        </div>
        <div className="list-wrapper">
          {data.length > 0 ? (
            <div className="list">
              {data.map((item, idx) => {
                return (
                  <RoomCard
                    key={idx}
                    data={item}
                    isLeader={false}
                    navigate={navigate}
                  />
                );
              })}
            </div>
          ) : (
            <EmtyList text="등록된 게시물이 없습니다" />
          )}
        </div>
      </div>
      <Link to={`/room/ACT/write`} className="write-nav">
        <img src="/assets/room/write.svg" alt="" />
        <div className="title">글 작성</div>
      </Link>
    </>
  ) : (
    <div />
  );
}

export default RoomList;
