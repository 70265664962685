import React, { useCallback, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AppDispatch } from "../../..";
import { clientApi } from "../../../api/config";
import FirstInfoBtn from "../../../components/FirstInfoBtn/FirstInfoBtn";
import FirstInfoTop from "../../../components/FirstInfoTop/FirstInfoTop";
import LoginInput from "../../../components/LoginInput/LoginInput";
import { FileReaderBase64 } from "../../../lib/lib";
import { LoadingChange } from "../../../reducers/config";
import { stateface } from "./interface";

const regex = /[가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z]/;

function Step1() {
  const {
    state,
    dispatch,
    usr_id,
    reduxPatch,
  }: {
    state: stateface;
    dispatch: (p: object) => void;
    usr_id: string;
    reduxPatch: AppDispatch;
  } = useOutletContext();

  const navigate = useNavigate();
  const [intro, setIntro] = useState(state.info_desc);
  const __nav = useCallback(() => {
    if (regex.test(state.login_nm) && intro) {
      dispatch({
        type: "intro",
        payload: intro,
      });
      navigate("/firstinfo/step2");
    }
  }, [navigate, dispatch, intro, state]);
  const __updateImg = useCallback(
    (img: File) => {
      reduxPatch(LoadingChange(true));
      FileReaderBase64(img).then(({ url }) => {
        clientApi
          .post("/auth/usr/usr", {
            usr_id,
            usr_thumbnail: url,
          })
          .then((res) => {
            dispatch({
              type: "img",
              payload: url,
            });
            reduxPatch(LoadingChange(false));
          })
          .catch(() => {
            reduxPatch(LoadingChange(false));
          });
      });
    },
    [usr_id, reduxPatch, dispatch]
  );
  const __updateNickname = useCallback(
    (e: string) => {
      dispatch({
        type: "nickname",
        payload: e,
      });
    },
    [dispatch]
  );

  return (
    <div className="step1">
      <FirstInfoTop
        type="intro"
        index={0}
        step="Step 1"
        title="자기소개를 입력해주세요"
        content={
          <div className="content">
            입력된 자기소개 내용은 사이드 프로젝트 <br /> 참여 신청 시
            팀리더에게 공유됩니다
          </div>
        }
      />
      <div className="bottom">
        <label>
          <input
            type="file"
            style={{ opacity: 0 }}
            multiple={false}
            accept="image/x-png,image/gif,image/jpeg"
            onChange={(e) => {
              const files = e.target.files;
              if (files && files?.length > 0) {
                __updateImg(files[0]);
              }
            }}
          />
          {state.img ? (
            <img src={state.img} alt="" />
          ) : (
            <img
              src="/assets/profile/profile.png"
              srcSet="/assets/profile/profile@2x.png 2x ,/assets/profile/profile@3x.png 3x"
              alt=""
            />
          )}
        </label>
        <LoginInput
          Check={undefined}
          type={"text"}
          title={"활동명"}
          placeholder={"활동명을 입력해주세요"}
          value={state.login_nm}
          setValue={__updateNickname}
          errorType={undefined}
        />
        <div className="area-wrapper">
          <div className="title-wrapper">
            <div className="title">소개글</div>
            <div className="length">{intro.length}/500</div>
          </div>
          <textarea
            placeholder="소개글을 입력해주세요"
            value={intro}
            onChange={(e) => {
              const val = e.target.value;
              if (val.length > 500) {
                setIntro(val.substring(0, 500));
              } else {
                setIntro(val);
              }
            }}
          ></textarea>
        </div>
        <FirstInfoBtn
          title="Step 2. 포트폴리오 등록하기"
          __nav={__nav}
          type={regex.test(state.login_nm) && intro ? "insert" : "off"}
        />
      </div>
    </div>
  );
}

export default Step1;
