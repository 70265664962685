import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HeaderChange } from "../../../../reducers/config";
function FixPortfolio({
  __fix,
  type,
  link,
}: {
  __fix: (e: string) => void;
  type: string | undefined;
  link: string;
}) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [portlink, setPortlink] = useState<string>(link);
  const __changeHeader = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (type === "portfolio") {
        const { currentTarget } = e;
        if (currentTarget.scrollTop < 1) {
          dispatch(HeaderChange("parent"));
        } else {
          dispatch(HeaderChange(""));
        }
      }
    },
    [dispatch, type]
  );

  useEffect(() => {
    if (type === "portfolio") {
      dispatch(HeaderChange("parent"));
    }
    return () => {
      setTimeout(() => {
        document.getElementById("fix-port")?.scrollTo(0, 0);
      }, 200);
    };
  }, [type, dispatch]);

  return (
    <div
      className={`fix-portfolio ${type === "portfolio" ? "on" : ""}`}
      id="fix-port"
      onScroll={__changeHeader}
    >
      <div className="top">
        <div className="wrapper">
          <div className="title">포트폴리오를 등록해주세요</div>
          <div className={`step2-drop-down ${isOpen ? "open" : ""}`}>
            <button
              className="open-btn"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <img src="/assets/common/grey-arrow.svg" alt="" />
              포트폴리오, 왜 등록해야하나요?
            </button>
            <div className="box">
              <div className="title">
                <span>Q. </span>포트폴리오, 왜 등록해야 하나요?
              </div>
              <div className="content">
                팀플은 사이드 프로젝트를 함께 해나갈 팀원을 선정하는 곳으로
                포트폴리오는 선정에 기본적이고 중요한 자료가 됩니다. 현재 <br />{" "}
                내가 어떤 것을 해나가고 있는지, 이전에 어떤 경험이 있는지 <br />
                자유롭게 표현한 포트폴리오 링크를 등록해주세요.
              </div>
            </div>
            <button
              className="close-btn"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              접기
              <img src="/assets/common/grey-arrow.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="title">포트폴리오 링크 입력</div>
        <div className="sub">
          SNS, 노션, 비핸스, 링크드인 등에 포트폴리오를 업데이트하고
          <br /> 해당 링크를 추가하고 공유해주세요.
        </div>
        <input
          type="text"
          placeholder="링크를 입력해주세요"
          value={portlink}
          onChange={(e) => {
            setPortlink(e.target.value);
          }}
        />
        <img
          src="/assets/firstinfo/step2.png"
          srcSet="/assets/firstinfo/step2@2x.png 2x, /assets/firstinfo/step2@3x.png 3x"
          alt=""
        />
        <button
          onClick={() => {
            __fix(portlink);
            dispatch(HeaderChange(""));
          }}
        >
          포트폴리오 링크 수정
        </button>
      </div>
    </div>
  );
}

export default FixPortfolio;
