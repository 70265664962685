import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import CertifyInput from "../../CertifyInput/CertifyInput";
import LoginInput from "../../LoginInput/LoginInput";
import { ReactComponent as SmallArrow } from "../svg/small-arrow.svg";
import { Check_usr_email, Fix_user_Email } from "../../../api/user_api";
import FixKeyword from "./components/FixKeyword";
import FixPortfolio from "./components/FixPortfolio";
import { useDispatch } from "react-redux";
import { UserEmail } from "../../../reducers/auth";
import { ToastChange } from "../../../reducers/config";
type Props = {
  __updateNickname: (nickname: string, content: string) => void;
  __fixportfolio: (e: string) => void;
  __fixKeywords: (e: { tag_nm: string }[]) => void;
  fixtype: string | undefined;
  nickname: string;
  intro: string;
  email: string;
  setFixtype: Dispatch<SetStateAction<string | undefined>>;
  portfolio: string;
  keyword: { tag_nm: string }[];
  usr_id: string;
};
const emailReg = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
const MyInfo = ({
  __updateNickname,
  __fixportfolio,
  __fixKeywords,
  fixtype,
  nickname,
  intro,
  setFixtype,
  portfolio,
  keyword,
  email,
  usr_id,
}: Props) => {
  const dispatch = useDispatch();
  const [nickval, setnickval] = useState(nickname);
  const [introval, setIntroval] = useState(intro);
  const [errorType, setErrorType] = useState<string | undefined>(undefined);
  const [isCertify, setisCertify] = useState<boolean>(false);
  const [emailval, setEmailval] = useState(email);
  const EmailCheck = useCallback(() => {
    if (isCertify) {
      Fix_user_Email(emailval, usr_id).then(({ msg, state }) => {
        if (state) {
          dispatch(
            ToastChange({
              active: true,
              content: "이메일 변경이 완료되었습니다",
            })
          );
          dispatch(UserEmail(emailval));
        } else {
          dispatch(ToastChange({ active: true, content: msg }));
        }
      });
    } else {
      if (emailval.length > 0) {
        if (emailReg.test(emailval)) {
          Check_usr_email(emailval).then(({ state }) => {
            if (state) {
              setErrorType("email");
              return false;
            } else {
              if (errorType === "email" || errorType === "email-reg") {
                setErrorType(undefined);
              }
              setisCertify(true);
              return true;
            }
          });
        } else {
          setErrorType("email-reg");
          return false;
        }
      } else {
        setErrorType("email-gap");
        return false;
      }
    }
  }, [emailval, errorType, isCertify, dispatch, usr_id]);

  useEffect(() => {
    setnickval(nickname);
    setIntroval(intro);
    setEmailval(email);
    return () => {};
  }, [intro, nickname, email]);

  return (
    <div className="info-wrapper">
      <div className="basic-info">
        <div className="wrapper">
          <div className="title">
            기본 정보
            <button
              className="my-info-btn"
              onClick={() => {
                __updateNickname(nickval, introval);
              }}
            >
              저장하기
            </button>
          </div>
          <LoginInput
            Check={undefined}
            type={"text"}
            title="활동명"
            placeholder={"닉네임 입력"}
            setValue={setnickval}
            errorType={undefined}
            value={nickval}
          />
          <div className="area-wrapper">
            <div className="title-wrapper">
              <div className="title">소개글</div>
              <div className="length">{intro.length}/500</div>
            </div>
            <textarea
              placeholder="소개글을 입력해주세요"
              value={introval}
              onChange={(e) => {
                const val = e.target.value;
                if (val.length > 500) {
                  setIntroval(val.substring(0, 500));
                } else {
                  setIntroval(val);
                }
              }}
            ></textarea>
          </div>
          <div className="title">이메일 정보</div>
          <div className="my-email-info">
            <CertifyInput
              type={
                errorType === "email"
                  ? "break"
                  : errorType === "email-reg"
                  ? "reg"
                  : errorType === "email-gap"
                  ? "gap"
                  : isCertify
                  ? "finish"
                  : ""
              }
              isFocus={undefined}
              setIsFoucs={undefined}
              setValue={setEmailval}
              category="fix"
              Certify={EmailCheck}
              setIsCertify={setisCertify}
              isCertify={isCertify}
              value={emailval}
            />
          </div>
        </div>
      </div>
      <div className="block" />
      <div className="portfolio-wrapper">
        <div className="wrapper">
          <div className="title-wrapper">
            <div className="title">포트폴리오</div>
            <button
              onClick={() => {
                setFixtype("portfolio");
              }}
            >
              수정
              <SmallArrow />
            </button>
          </div>
          <div className="box">
            <img src="/assets/profile/portfolio.svg" alt="" />
            <div className="title">나의 포트폴리오</div>
            <div className="link">{portfolio}</div>
            <a
              className={`firstinfo-btn`}
              href={`${portfolio}`}
              target="_blank"
              rel="noreferrer"
              style={
                !portfolio
                  ? {
                      pointerEvents: "none",
                    }
                  : undefined
              }
            >
              <div className="title">포트폴리오 이동</div>
              <img src="/assets/profile/white-arrow.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="block" />
      <div className="keyword-wrapper">
        <div className="wrapper">
          <div className="title-wrapper">
            <div className="title">나의 키워드</div>
            <button
              onClick={() => {
                setFixtype("keyword");
              }}
            >
              수정
              <SmallArrow />
            </button>
          </div>
          <div className="keyword-array">
            {keyword.map(({ tag_nm }, idx) => {
              return (
                <div className="box" key={idx}>
                  <div className="title">#{tag_nm}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <FixPortfolio __fix={__fixportfolio} type={fixtype} link={portfolio} />
      <FixKeyword __fix={__fixKeywords} type={fixtype} data={keyword} />
    </div>
  );
};

export default MyInfo;
