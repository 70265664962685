import React, { useState } from "react";
import { ReactComponent as Starsvg } from "../svg/star.svg";
type Props = {
  wrtie: (star: number, content: string) => void;
};

const RecommendWrite = ({ wrtie }: Props) => {
  const [Star, setStar] = useState<number>(0);
  const [Content, setContent] = useState<string>("");

  return (
    <div className="write-recommend-section">
      <div className="title-wrapper">
        <div className="title">추천평 작성</div>
        <div className="length">{Content.length}/300</div>
      </div>
      <textarea
        className="recommend-input"
        placeholder="추천평을 작성해주세요"
        value={Content}
        onChange={(e) => {
          const value = e.target.value;
          if (value.length <= 300) {
            setContent(value);
          }
        }}
      />
      <div className="recommend-write-bottom">
        <div className="recommend-star-wrapper">
          {Array(5)
            .fill(0)
            .map((item, idx) => {
              return (
                <button
                  key={idx}
                  className={`star-btn ${Star > idx ? "active-star" : ""}`}
                  onClick={() => {
                    setStar(idx + 1);
                  }}
                >
                  <Starsvg />
                </button>
              );
            })}
        </div>
        <button
          className="insert-btn"
          onClick={() => {
            if (Content) {
              wrtie(Star, Content);
              setContent("");
              setStar(0);
            }
          }}
        >
          등록하기
        </button>
      </div>
    </div>
  );
};

export default RecommendWrite;
