import { clientApi } from "./config";
import { NetworkError } from "./get_api";

const get_comment_api = (path: string) => {
  return clientApi
    .get(path)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "요청 성공",
        };
      } else {
        return {
          state: false,
          data: undefined,
          msg: err_msg,
        };
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        state: false,
        data: undefined,
        msg: "네트워크 연결을 확인해주세요",
      };
    });
};
const remove_comment_api = (id: string) => {
  return clientApi
    .delete(`/web/cmnt/1/ctts/${id}`)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "요청 성공",
        };
      } else {
        return {
          state: false,
          data: undefined,
          msg: err_msg,
        };
      }
    })
    .catch((error) => {
      return NetworkError;
    });
};

const post_comment_api = (data: any) => {
  return clientApi
    .post("/web/cmnt/1/ctts", data)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "요청 성공",
        };
      } else {
        return {
          state: false,
          data: undefined,
          msg: err_msg,
        };
      }
    })
    .catch((error) => {
      return NetworkError;
    });
};
export { get_comment_api, post_comment_api, remove_comment_api };
