import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./css/index.css";
import {
  delete_usr_card,
  Get_usr_card,
  Insert_usr_card,
} from "../../api/user_api";
import { CardInter } from "../../interface/interface";
import { __insertCard } from "../../lib/payment";
import { ReactComponent as DeleteSvg } from "./svg/delete.svg";
import CreditCard from "../CreditCard/CreditCard";
import { useDispatch } from "react-redux";
import { LoadingChange, ToastChange } from "../../reducers/config";

type Props = {
  usr_id: string;
  type: "payment" | "my";
  initCard: ((e: CardInter) => void) | undefined;
  selectCard: CardInter | undefined;
  period: string | undefined;
  category: "prjt" | "mtup" | undefined;
};

const CardSection = ({
  usr_id,
  type,
  initCard,
  selectCard,
  period,
  category,
}: Props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<CardInter[]>([]);
  const [isopen, setIsopen] = useState<number | undefined>(undefined);
  const __updateCard = useCallback(
    (e: {
      PCD_PAY_CODE: string;
      PCD_PAYER_ID: string;
      PCD_PAY_CARDNAME: string;
      PCD_PAY_CARDNUM: string;

      PCD_PAY_MSG: string;
    }) => {
      if (e.PCD_PAY_CODE === "0000") {
        dispatch(LoadingChange(true));
        Insert_usr_card({
          usr_id,
          crd_nm: e.PCD_PAY_CARDNAME,
          crd_num: e.PCD_PAY_CARDNUM,
          crd_code: e.PCD_PAYER_ID,
        }).then(({ state, data: result }) => {
          if (state) {
            let clone = data.slice();
            clone.push(result);
            setData(clone);
          } else {
            dispatch(
              ToastChange({
                active: true,
                content: "카드 등록에 실패했습니다.",
              })
            );
          }
          dispatch(LoadingChange(false));
        });
      } else {
        dispatch(
          ToastChange({
            active: true,
            content: e.PCD_PAY_MSG,
          })
        );
      }
    },
    [data, dispatch, usr_id]
  );
  const __removeCard = useCallback(
    (id: string, idx: number) => {
      dispatch(LoadingChange(true));
      delete_usr_card(id).then(({ state, data, msg }) => {
        if (state) {
          let clone = data.slice();
          clone.splice(idx, 1);
          setData(clone);
        } else {
          dispatch(ToastChange({ active: true, content: msg }));
        }
        dispatch(LoadingChange(false));
      });
    },
    [dispatch]
  );

  const __initCard = useCallback(() => {
    if (usr_id) {
      __insertCard(__updateCard);
    } else {
      alert("로그인 후에 이용해주세요");
    }
  }, [__updateCard, usr_id]);
  useEffect(() => {
    if (usr_id) {
      Get_usr_card(usr_id).then(({ state, data }) => {
        if (state) {
          setData(data);
        }
      });
    }
    return () => {};
  }, [usr_id]);
  return data.length > 0 ? (
    <div className="select-card-section">
      {data.map((item, idx) => {
        return type === "my" ? (
          <MyCard
            key={idx}
            item={item}
            idx={idx}
            setIsopen={setIsopen}
            isopen={isopen}
            remove={__removeCard}
          />
        ) : (
          <CreditCard
            key={idx}
            selectCard={selectCard}
            data={item}
            period={period}
            __changeCard={initCard}
            category={category}
          />
        );
      })}
      {data.length < 3 ? (
        <button className="card-init-btn" onClick={__initCard}>
          <div className="left">
            <img src="/assets/common/card.svg" alt="" />
            <div className="title">카드 추가 등록하기</div>
          </div>
          <img src="/assets/common/card-init.svg" alt="" />
        </button>
      ) : undefined}
    </div>
  ) : (
    <div className="emty-card-section">
      <div className="img">
        <img src="/assets/common/creditcard.svg" alt=""></img>
      </div>
      <div className="text">등록된 카드가 없어요</div>
      <div className="sub">
        팀 선정시 등록된 카드로 결제가 시도됩니다. <br />
        카드가 등록되지 않은 상태에서는 <br />
        프로젝트 참여가 제한되니 사전에 등록을 부탁드립니다.
      </div>
      <div className="button" onClick={__initCard}>
        카드 등록하기
      </div>
    </div>
  );
};

export default CardSection;

const MyCard = ({
  isopen,
  item,
  idx,
  setIsopen,
  remove,
}: {
  isopen: number | undefined;
  item: CardInter;
  idx: number;
  setIsopen: Dispatch<SetStateAction<undefined | number>>;
  remove: (id: string, idx: number) => void;
}) => {
  return (
    <div className={`box ${isopen === idx ? "open" : ""}`}>
      <div className="card-wrapper">
        <div className="card-flex">
          <div className="left">
            <div className="card-name">{item.crd_nm}</div>
            <div className="number">{item.crd_num}</div>
          </div>
          <div
            className={`img ${isopen === idx ? "img-delete" : ""}`}
            onClick={() => {
              if (idx === isopen) {
                setIsopen(undefined);
              } else {
                setIsopen(idx);
              }
            }}
          >
            <DeleteSvg />
          </div>
        </div>
        <div
          className={`delete-wrapper ${isopen === idx ? "delete-open" : ""}`}
        >
          <div className="text-wrapper">
            <div className="text">정말 삭제 하시겠습니까?</div>
            <div className="sub">
              카드를 삭제하면 추후 필요시 <br />
              카드등록을 다시해주셔야합니다.
            </div>
          </div>
          <button
            className="delete-box"
            onClick={() => {
              remove(item.crd_id, idx);
            }}
          >
            네 삭제합니다
          </button>
        </div>
      </div>
    </div>
  );
};
