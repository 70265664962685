import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clientApi } from "../../../api/config";
import UseCommoent from "../../../hooks/UseCommoent";
import { PopupChange } from "../../../reducers/config";
import ReviewComment from "../../Comment/ReviewComment";
import RecommendWrite from "../../Comment/Write/RecommendWrite";
import EmtyList from "../../EmtyList/EmtyList";

type Props = {
  isMe: boolean;
  usr_id: string;
  my_usr_id: string;
};

const Recommend = ({ isMe, usr_id, my_usr_id }: Props) => {
  const dispatch = useDispatch();
  /** 내가쓴 , 나의 추천평  */
  const [MyIndex, setMyIndex] = useState<number>(0);
  const [RecommendData, WriteComment, isWrite, star] = UseCommoent({
    path: `/web/cmnt/1?bbs_id=1&ctts_ctg_cd=RCMN&${
      MyIndex === 0 ? "rcmn_usr_id" : "reg_usr_id"
    }=${usr_id}`,
    dispatch,
  });
  // 함께 참여한 프로젝트가 있는지
  const [isPrj, setIsPrj] = useState<boolean>(false);
  /** 내가쓴 or 나의 추천평 변경 함수 */
  const ChangeMyIndex = useCallback((idx: number) => {
    setMyIndex(idx);
  }, []);

  /** 삭제 팝업 */
  const RemovePopup = useCallback(
    (id: string) => {
      dispatch(
        PopupChange({
          type: "RECOMMEND",
          data: id,
          active: true,
        })
      );
    },
    [dispatch]
  );
  /** 신고 팝업 */
  const ReportPopup = useCallback(
    (cid: string, content: string) => {
      dispatch(
        PopupChange({
          type: "report",
          active: true,
          data: {
            cid,
            content,
          },
        })
      );
    },
    [dispatch]
  );

  /** 추천평 작성 */
  const WriteRecommend = useCallback(
    (star: number, content: string) => {
      WriteComment({
        bbs_id: "1",
        ctts_ctts: content,
        ctts_ctg_cd: "RCMN",
        rcmn_usr_id: usr_id,
        rcmn_pt: String(star),
      });
    },
    [usr_id, WriteComment]
  );
  /** 추천평 수정 */
  const FixRecommend = useCallback(
    (star: number, content: string, ctts_id: string, rcmn_usr_id: string) => {
      WriteComment({
        bbs_id: "1",
        ctts_ctts: content,
        ctts_ctg_cd: "RCMN",
        rcmn_usr_id,
        rcmn_pt: String(star),
        ctts_id,
      });
    },
    [WriteComment]
  );
  useEffect(() => {
    clientApi
      .get(`/web/cmnt/isJoinPrjt?usr_id_lst=${usr_id},${my_usr_id}`)
      .then(({ data: { succ_obj } }) => {
        if (succ_obj === "NONE") {
          setIsPrj(false);
        } else {
          setIsPrj(true);
        }
      });

    return () => {};
  }, [usr_id, my_usr_id]);

  return (
    <div className="profile-recommend-section">
      <div className="wrapper">
        {isMe ? (
          <div className="recommend-me-btn-wrapper">
            {my_btn_layout.map((item, idx) => {
              return (
                <button
                  key={idx}
                  className={`me-change-btn ${
                    idx === MyIndex ? "color-btn" : ""
                  }`}
                  onClick={() => {
                    ChangeMyIndex(idx);
                  }}
                >
                  {item}
                </button>
              );
            })}
          </div>
        ) : usr_id && !isWrite && isPrj ? (
          <RecommendWrite wrtie={WriteRecommend} />
        ) : undefined}
        {MyIndex === 0 ? (
          <>
            <div className="recommend-title">
              {RecommendData.length}개의 추천평
            </div>
            <div className="recommend-score-section">
              <div className="score-title">
                평점 <span>{star ? star.toFixed(1) : 0}</span>
              </div>
              <div className="score-wrapper">
                {Array(star ? Math.floor(star) : 0)
                  .fill(0)
                  .map((item, idx) => {
                    return (
                      <img
                        src="/assets/profile/recommend.svg"
                        alt=""
                        key={idx}
                      />
                    );
                  })}
              </div>
            </div>
          </>
        ) : undefined}
        <div className="profile-recommend-list">
          {RecommendData.length > 0 ? (
            RecommendData.map((item, idx) => {
              return (
                <ReviewComment
                  data={item}
                  key={idx}
                  usr_id={my_usr_id}
                  __report={ReportPopup}
                  __remove={RemovePopup}
                  fix={FixRecommend}
                  isMyWrite={MyIndex === 1 ? true : false}
                />
              );
            })
          ) : (
            <EmtyList text="작성된 추천평이 없습니다." />
          )}
        </div>
      </div>
    </div>
  );
};

export default Recommend;

const my_btn_layout = ["나의 추천평", "내가 쓴 추천평"];
