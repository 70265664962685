import React, { useState } from "react";
import { CommnetInter } from "../../interface/interface";
import { encrypto, formatDate } from "../../lib/lib";
import { ReactComponent as Starsvg } from "./svg/star.svg";
import "./css/index.css";
import { Link } from "react-router-dom";
const ReviewComment: React.FunctionComponent<{
  data: CommnetInter;
  usr_id: string;
  __report: (cid: string, content: string) => void;
  __remove: (id: string) => void;
  fix: (
    star: number,
    content: string,
    ctts_id: string,
    rcmn_usr_id: string
  ) => void;
  isMyWrite?: boolean;
}> = ({
  data: {
    usr_thumbnail,
    ctts_ctts,
    ctts_reg_at,
    ctts_reg_login_nm,
    ctts_reg_usr_id,
    ctts_id,
    rcmn_pt,
    usr_typ,
    isalertblock,
    rcmn_usr_id,
    rcmn_login_nm,
    rcmn_usr_thumbnail,
  },
  usr_id,
  __report,
  __remove,
  fix,
  isMyWrite,
}) => {
  const [isFix, setIsFix] = useState<boolean>(false);
  const [content, setContent] = useState<string>(ctts_ctts);
  const [star, setStar] = useState<number>(Number(rcmn_pt));
  return (
    <div
      className={`comment ${isFix ? "fix-comment" : ""} ${
        isalertblock === "BLOCK" ? "block-comment" : ""
      }`}
    >
      {usr_id ? (
        <div className="btn-wrapper">
          {usr_id === ctts_reg_usr_id ? (
            <>
              <button
                onClick={() => {
                  if (!isFix) {
                    setIsFix(true);
                  } else {
                    setIsFix(false);
                    fix(star, content, ctts_id, rcmn_usr_id);
                  }
                }}
              >
                수정
              </button>
              {!isFix ? (
                <button
                  onClick={() => {
                    __remove(ctts_id);
                  }}
                >
                  삭제
                </button>
              ) : undefined}
            </>
          ) : (
            <button
              onClick={() => {
                __report(ctts_id, ctts_ctts);
              }}
            >
              신고
            </button>
          )}
        </div>
      ) : undefined}
      <Link
        className="profile-wrapper"
        to={`/profile/${encrypto(ctts_reg_usr_id)}`}
      >
        <div
          className="img-wrapper"
          style={{ backgroundImage: `url(${usr_thumbnail})` }}
        />
        <div className="right">
          <div className="nickname">{ctts_reg_login_nm}</div>
          <div className="grade">
            {usr_typ === "READ" ? "팀플리더" : "팀플러"}
          </div>
        </div>
      </Link>
      {isFix ? (
        <textarea
          className="fix-comment-area"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
      ) : (
        <>
          <div className="content">
            {isalertblock !== "BLOCK"
              ? ctts_ctts
              : "해당 댓글은 블라인드 처리된 댓글입니다"}
          </div>
          {isMyWrite && (
            <div className="rcmn-usr-box">
              <div className="title">추천대상</div>
              <div className="rcmn-profile-wrapper">
                <div
                  className="profile-img"
                  style={{
                    backgroundImage: `url(${rcmn_usr_thumbnail})`,
                  }}
                />
                <div className="name">{rcmn_login_nm}</div>
              </div>
            </div>
          )}
        </>
      )}
      {isFix && rcmn_pt ? (
        <div className="recommend-star-wrapper">
          {Array(5)
            .fill(0)
            .map((item, idx) => {
              return (
                <button
                  key={idx}
                  className={`star-btn ${
                    Math.floor(star) >= idx + 1 ? "active-star" : ""
                  }`}
                  onClick={() => {
                    setStar(idx + 1);
                  }}
                >
                  <Starsvg />
                </button>
              );
            })}
        </div>
      ) : (
        <div className="timestamp">
          {formatDate(ctts_reg_at, "/")}
          {rcmn_pt ? (
            <div className="comment-star-wrapper">
              {Array(Number(rcmn_pt))
                .fill(0)
                .map((item, idx) => {
                  return (
                    <img src="/assets/profile/recommend.svg" alt="" key={idx} />
                  );
                })}
            </div>
          ) : undefined}
        </div>
      )}
    </div>
  );
};

export default ReviewComment;
