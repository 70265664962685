import React, { ChangeEvent, Dispatch, useCallback } from "react";
import { RoomWriteInter } from "../../../interface/interface";
import { FileChangeUrl} from "../../../lib/lib";

type Props = {
  dispatch: Dispatch<{
    type: string;
    payload: string;
    url: string;
  }>;
  state: RoomWriteInter;
  category: string;
  WriteRoom: () => void;
};

function RoomWrite({ dispatch, state, category, WriteRoom }: Props) {
  const __updateFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files;
      if (file) {
        FileChangeUrl(file[0]).then(({ url }) => {
          dispatch({
            type: "file",
            payload: file[0].name,
            url: url,
          });
        });
      }
    },
    [dispatch]
  );
  const __updateContent = useCallback(
    (type: string, content: string) => {
      dispatch({
        type,
        payload: content,
        url: "",
      });
    },
    [dispatch]
  );
  const __cancelFile = useCallback(() => {
    dispatch({
      type: "file",
      payload: "",
      url: "",
    });
  }, [dispatch]);

  return (
    <div className="room-write">
      <div className="title">
        {category === "NOTICE" ? "공지사항 작성" : "게시물 작성"}
      </div>
      <div className="input-wrapper">
        <div className="title">제목</div>
        <input
          type="text"
          placeholder="제목을 입력해주세요"
          value={state.title}
          onChange={(e) => {
            __updateContent("title", e.target.value);
          }}
        />
      </div>
      <div className="input-wrapper">
        <div className="title">내용</div>
        <textarea
          placeholder="내용을 입력해주세요"
          value={state.content}
          onChange={(e) => {
            __updateContent("content", e.target.value);
          }}
        ></textarea>
      </div>

      <div className="input-wrapper">
        <div className="title">링크</div>
        <div className="link-wrapper">
          <img src="/assets/common/grey-link.svg" alt="" />
          <input
            type="text"
            value={state.link}
            placeholder="링크를 입력해주세요"
            onChange={(e) => {
              __updateContent("link", e.target.value);
            }}
          />
        </div>
      </div>
      <div className="input-wrapper">
        <div className="title">첨부파일</div>
        <div className={`label-wrapper ${state.file.name ? "full" : ""}`}>
          {state.file.name ? (
            <img
              src="/assets/common/cancel.svg"
              alt=""
              className="cancel"
              onClick={__cancelFile}
            />
          ) : undefined}
          <label>
            <img
              src={`/assets/common/grey-${
                state.file.name ? "file" : "init"
              }.svg`}
              alt=""
            />
            <span>{state.file.name ? state.file.name : "파일 업로드"}</span>
            <input
              type="file"
              style={{ opacity: 0 }}
              multiple={false}
              onChange={__updateFile}
            />
          </label>
        </div>
      </div>
      <button
        className={`write-btn ${
          state.title && state.content ? "active-write" : ""
        }`}
        onClick={WriteRoom}
      >
        작성하기
      </button>
    </div>
  );
}

export default RoomWrite;
