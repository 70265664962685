import React, { Dispatch, useCallback, useState } from "react";
import "./css/index.css";
function Keywords({
  data,
  patch,
}: {
  data: { tag_nm: string }[];
  patch:
    | ((p: object) => void)
    | Dispatch<{ keyword: { tag_nm: string }[]; type: string }>;
}) {
  const [text, setText] = useState<string>("");
  const __updateKeyword = useCallback(() => {
    if (text && data.length < 5) {
      const clone = data.slice();
      clone.push({ tag_nm: text });
      patch({
        type: "keyword",
        keyword: clone,
      });
      setText("");
    }
  }, [patch, text, data]);
  const __removeKeyword = useCallback(
    (index: number) => {
      const clone = data.slice();
      clone.splice(index, 1);
      patch({
        type: "keyword",
        keyword: clone,
      });
    },
    [data, patch]
  );

  return (
    <div className="keyword-insert">
      <div className="title-wrapper">
        <div className="title">키워드 추가</div>
        <div className="input-notice">7글자 이내 / 최대 5개</div>
      </div>
      <div className="input-wrapper">
        <input
          type="text"
          maxLength={7}
          value={text}
          disabled={data.length >= 5 ? true : false}
          placeholder={data.length >= 5 ? "최대 5개까지 등록가능" : ""}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        <button className="insert" onClick={__updateKeyword}>
          추가
        </button>
      </div>
      <div className="line" />
      <div className="keyword-wrapper">
        {data.map(({ tag_nm }, idx) => {
          return (
            <div className={`box index-${idx}`} key={idx}>
              <div className="title">{`#${tag_nm}`} </div>
              <img
                src="/assets/firstinfo/white-cancel.svg"
                alt=""
                onClick={() => {
                  __removeKeyword(idx);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Keywords;
