import AboutMainSection from "../../components/About/AboutMainSection/AboutMainSection";
import AboutNotice from "../../components/About/AboutNotice";
import AboutReview from "../../components/About/AboutReview";
import AboutSection1 from "../../components/About/AboutSection1/AboutSection1";
import AboutSection2 from "../../components/About/AboutSection2/AboutSection2";
import AboutSection3 from "../../components/About/AboutSection3/AboutSection3";
import AboutSection6 from "../../components/About/AboutSection6/AboutSection6";
import Footer from "../../components/Footer/Footer";
import UseResponsive from "../../hooks/UseResponsive";
import "./css/index.css";

function About() {
  const [isDesktop, isTablet, isMobile, Mobile, Tablet, Desktop] =
    UseResponsive();
  return (
    <main className="about-page">
      <AboutMainSection
        isTablet={isTablet}
        DeskTop={Desktop}
        Tablet={Tablet}
        Mobile={Mobile}
      />
      <AboutSection1
        isMobile={isMobile}
        DeskTop={Desktop}
        Tablet={Tablet}
        Mobile={Mobile}
      />
      <AboutSection2 DeskTop={Desktop} Tablet={Tablet} Mobile={Mobile} />
      <AboutSection3 DeskTop={Desktop} Tablet={Tablet} Mobile={Mobile} />
      <AboutReview />
      <AboutNotice />
      <AboutSection6 />
      <Footer />
    </main>
  );
}

export default About;
