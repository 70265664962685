import React, { useState } from "react";
import "./css/index.css";
function FirstInfoTop({
  type,
  index,
  step,
  title,
  content,
}: {
  type: string;
  index: number;
  step: string;
  title: string;
  content: JSX.Element | undefined;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`first-top ${type} ${isOpen ? "top-long" : ""}`}>
      <div className="wrapper">
        <div className="circle-wrapper">
          {[0, 0, 0].map((item, idx) => {
            return (
              <div
                className={`circle ${idx === index ? "circle-on" : ""}`}
                key={idx}
              />
            );
          })}
        </div>
        <div className="step">{step}</div>
        <div className="title">{title}</div>
        {content ? (
          content
        ) : (
          <div className={`step2-drop-down ${isOpen ? "open" : ""}`}>
            <button
              className="open-btn"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <img src="/assets/common/grey-arrow.svg" alt="" />
              포트폴리오, 왜 등록해야하나요?
            </button>
            <div className="box">
              <div className="title">
                <span>Q. </span>포트폴리오, 왜 등록해야 하나요?
              </div>
              <div className="content">
                팀플은 사이드 프로젝트를 함께 해나갈 팀원을 선정하는 곳으로
                포트폴리오는 선정에 기본적이고 중요한 자료가 됩니다. 현재 <br />{" "}
                내가 어떤 것을 해나가고 있는지, 이전에 어떤 경험이 있는지 <br />
                자유롭게 표현한 포트폴리오 링크를 등록해주세요.
              </div>
            </div>
            <button
              className="close-btn"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              접기
              <img src="/assets/common/grey-arrow.svg" alt="" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default FirstInfoTop;
