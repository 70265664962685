import React, { useCallback } from "react";
import { NavigateFunction } from "react-router-dom";
import { encrypto } from "../../../lib/lib";
import EmtyList from "../../EmtyList/EmtyList";
import ProfileCard from "./components/ProfileCard";
import UseGetCommon from "../../../hooks/UseGetCommon";
import Infinite from "../../Infinite/Infinite";

type Props = {
  navigate: NavigateFunction;
  usr_id: string;
  usr_typ: string;
  isMe:boolean;
};

const ProfileProject = ({ navigate, usr_id, usr_typ,isMe }: Props) => {
const [data,PagingUpdate] =UseGetCommon({
    path: usr_typ === "READ"
        ? `/web/cstm/prjt?prjt_type_cd=prjt&leadr_usr_id=${usr_id}`
        : `/web/my/prjt?apprv_sts_cd=apprv_sts_select&prjt_type_cd=prjt${!isMe ?`&usr_id=${usr_id}`:""}`,
    limit:0
  })
  const NavProject = useCallback(
    (category: string, id: string) => {
      navigate(`/detail/${category}/${encrypto(id)}`);
    },
    [navigate]
  );
  return (
    <div className="profile-project-section">
      <div className="wrapper">
        {data.length > 0 ? (
          <>
          {data.map((item, idx) => {
            return (
              <ProfileCard
                key={idx}
                data={item}
                NavProject={NavProject}
                type="project"
                download={undefined}
                NavSupport={undefined}
              />
            );
          })}
          <Infinite PagingUpdate={PagingUpdate}/>
          </>
        ) : (
          <EmtyList
            text={` 참여한 프로젝트가 없습니다
          `}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileProject;
