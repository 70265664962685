import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { clientApi } from "../../api/config";
import { AllianceType } from "../../interface/interface";
import { decypto } from "../../lib/lib";
import "./css/index.css";
type LocType = {
  pathname: string;
  state: AllianceType | undefined;
};

function AllianceDetail() {
  const { pathname, state } = useLocation() as LocType;
  const id = pathname.substring(17, pathname.length);
  const [data, setData] = useState<undefined | AllianceType>(undefined);
  useEffect(() => {
    if (state) {
      setData(state);
      // clientApi.get(`/cstm/prtn/${decypto(id)}`).then(({ data }) => {
      //   if (data) {
      //     setData(data);
      //   }
      // });
    }
    return () => {};
  }, [state]);

  return data ? (
    <main className="alliancedetail">
      <div className="wrapper">
        <div className="title">{data.prtn_nm}</div>
        <div
          className="img-wrapper"
          style={{ backgroundImage: `url(${data.thumbnail})` }}
        />
        <div className="main">
          <div className="header">
            제휴 내용
            <div className="span">{data.prtn_desc}</div>
          </div>

          <div className="content">
            <div className="title">기업소개</div>
            <div className="text">{data.prtn_info}</div>
          </div>
          <div className="content">
            <div className="title">이용법</div>
            <div className="text">{data.prtn_use}</div>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <></>
  );
}

export default AllianceDetail;
