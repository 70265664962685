import React from "react";
import "./css/index.css";
import { Link } from "react-router-dom";
import UseGetCommon from "../../hooks/UseGetCommon";
import { encrypto } from "../../lib/lib";

type Props = {
  mg_id: string;
};
const MagazineMore = ({ mg_id }: Props) => {
  const [data] = UseGetCommon({
    path: "/web/cmnt/2?bbs_id=4&row_limit_cnt=4",
    limit: 0,
  });
  return (
    <div className="more">
      <div className="wrapper">
        <div className="title">다른 매거진</div>
        <div className="list-wrapper">
          {data
            .filter((item) => item.ctts_id !== mg_id)
            .slice(0, 3)
            .map(({ thumbnail, reader_usr_info, ctts_id, ctts_tit }, idx) => {
              return (
                <Link
                  key={idx}
                  className="more-card"
                  to={`/detail/magazine/${encrypto(ctts_id)}`}
                >
                  <div
                    className="img-wrapper"
                    style={{ backgroundImage: `url(${thumbnail})` }}
                  ></div>
                  <div className="title">{ctts_tit}</div>
                  <div className="sub">{reader_usr_info}</div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MagazineMore;
