import React from "react";
import { CardInter } from "../../interface/interface";
import "./css/index.css";
type Props = {
  data: CardInter;
  period: string | undefined;
  selectCard: CardInter | undefined;
  __changeCard: ((e: CardInter) => void) | undefined;
  category: "prjt" | "mtup" | undefined;
};

const CreditCard = ({
  data,
  period,
  selectCard,
  __changeCard,
  category,
}: Props) => {
  const { crd_nm, crd_num, crd_id } = data;
  const Finish = period ? new Date(period) : new Date();
  return (
    <div
      className={`credit-card ${
        selectCard?.crd_id === crd_id ? "select-card" : ""
      }`}
      onClick={() => {
        if (__changeCard) {
          __changeCard(data);
        }
      }}
    >
      <div className="check">
        <img src="/assets/common/black-check.svg" alt="" />
      </div>
      <div className="credit-name">{crd_nm}</div>
      <div className="credit-number">{crd_num}</div>
      <div className="credit-notice">
        {category === "prjt" ? (
          <>
            해당카드로 {Finish.getFullYear()}년 {Finish.getMonth() + 1}월
            {Finish.getDate()}일에 결제가 시도되며 <br />{" "}
          </>
        ) : (
          ""
        )}
        잔액부족, 한도초과 등의 카드문제가 있을 경우 <br /> 신청이 취소될 수
        있으니 주의 바랍니다.
      </div>
    </div>
  );
};

export default CreditCard;
