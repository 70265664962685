import React from "react";
import "./css/index.css";
type Props = {
  DeskTop: ({ children }: { children: JSX.Element }) => JSX.Element | null;
  Tablet: ({ children }: { children: JSX.Element }) => JSX.Element | null;
  Mobile: ({ children }: { children: JSX.Element }) => JSX.Element | null;
};

const AboutSection3 = ({ DeskTop, Tablet, Mobile }: Props) => {
  return (
    <div className="about-section3">
      <div className="wrapper">
        <div className="title-img">
          <img src="/assets/common/about/plus.svg" alt=""></img>
        </div>
        <div className="title">
          WITH TEAM, <br />
          THEN PLUS
        </div>
        <div className="sub">
          마이팀플에서는 어떤 아이디어라도 '프로젝트'가 될 수 있습니다. <br />{" "}
          IT/개발 · 마케팅 · 문화예술 · 미디어 <br /> 콘텐츠 · 캠페인 · 서비스 ·
          스타트업 · ESG 등 <br /> 직무경험이 필요한 분야의 사이드프로젝트에
          참여해보세요!
        </div>
        <div className="sub-img">
          <img src="assets/common/about/x.svg" alt=""></img>
        </div>
        <DeskTop>
          <div className="text">
            다양한 분야의 사람들과 함께하는 <br /> 사이드프로젝트
          </div>
        </DeskTop>
        <Tablet>
          <div className="text">
            다양한 분야의 사람들과 함께하는 <br /> 사이드프로젝트
          </div>
        </Tablet>
        <Mobile>
          <div className="text">
            다양한 분야의 사람들과 <br />
            함께하는 사이드프로젝트
          </div>
        </Mobile>
        <div className="list">
          {section3.map((item, index) => {
            return (
              <div key={index} className="box">
                <div className="img">{item.img}</div>
                <div className="title">{item.title}</div>
              </div>
            );
          })}
        </div>

        <div className="text-img">
          <img src="/assets/common/about/greenplus.svg" alt=""></img>
        </div>
        <div className="title-green">실제로 마이팀플에서 한 프로젝트가</div>
        <div className="span">스타트업이 되었습니다</div>
        <div className="box-wrapper">
          <div className="week-box ">
            <div className="greenbox">4주</div>
            <div className="box">
              <div className="box-content">
                <img
                  src="/assets/common/about/section3-green1.png"
                  srcSet="/assets/common/about/section3-green1@2x.png 2x, /assets/common/about/section3-green1@3x.png 3x"
                  alt=""
                  className="pc"
                ></img>
                <div className="week-box-title">
                  중소농가{" "}
                  <Mobile>
                    <br />
                  </Mobile>{" "}
                  문제 정의
                </div>
              </div>
              <div className="box-content">
                <img
                  src="/assets/common/about/section3-green2.png"
                  srcSet="/assets/common/about/section3-green2@2x.png 2x, /assets/common/about/section3-green2@3x.png 3x"
                  alt=""
                  className="pc"
                />
                <div className="week-box-title">
                  솔루션{" "}
                  <Mobile>
                    <br />
                  </Mobile>{" "}
                  모색
                </div>
              </div>
            </div>
          </div>
          <div className="week-box">
            <div className="greenbox">2주</div>
            <div className="box">
              <div className="box-content">
                <img
                  src="/assets/common/about/section3-green3.png"
                  srcSet="/assets/common/about/section3-green3@2x.png 2x, /assets/common/about/section3-green3@3x.png 3x"
                  alt=""
                  className="pc"
                />
                <div className="week-box-title">
                  고객검증{" "}
                  <Mobile>
                    <br />
                  </Mobile>{" "}
                  MVP 진행
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-arrow">
          <img src="/assets/common/about/bottom-arrow.svg" alt="" />
        </div>
        <div className="box-bottom">
          <div className="left">
            <img
              src="/assets/common/about/section3-green4.png"
              srcSet="/assets/common/about/section3-green4@2x.png 2x, /assets/common/about/section3-green4@3x.png 3x"
              alt=""
              className="pc"
            />
          </div>
          <div className="right">
            <div className="left-wrapper">
              <div className="text">
                6주 만에 <br />
                <span>새로운 스타트업</span> <br /> 서비스 탄생!
              </div>
            </div>
            <div className="right-wrapper">
              <img src="/assets/common/about/emoticon-th.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection3;

const section3 = [
  {
    img: <img src="/assets/common/about/emoticon1.svg" alt=""></img>,
    title: "디자인",
  },
  {
    img: <img src="/assets/common/about/emoticon2.svg" alt=""></img>,
    title: "미디어/영상",
  },
  {
    img: <img src="/assets/common/about/emoticon3.svg" alt=""></img>,
    title: "개발",
  },
  {
    img: <img src="/assets/common/about/emoticon4.svg" alt=""></img>,
    title: "마케팅",
  },
  {
    img: <img src="/assets/common/about/emoticon5.svg" alt=""></img>,
    title: "서비스",
  },
  {
    img: <img src="/assets/common/about/emoticon6.svg" alt=""></img>,
    title: "기획",
  },
  {
    img: <img src="/assets/common/about/emoticon7.svg" alt=""></img>,
    title: "예술",
  },
  {
    img: <img src="/assets/common/about/emoticon8.svg" alt=""></img>,
    title: "기타",
  },
];
