import React, { useCallback, useEffect, useState } from "react";
import { Common_get_api } from "../../../api/get_api";
import { FileDown } from "../../../lib/lib";
import EmtyList from "../../EmtyList/EmtyList";
import ProfileCard from "./components/ProfileCard";

type Props = {
  usr_id: string;
};

const Certification = ({ usr_id }: Props) => {
  const [data, setData] = useState([]);

  const Download = useCallback((file: string, prjt_nm: string) => {
    FileDown(file, `${prjt_nm} 인증서`);
  }, []);

  useEffect(() => {
    Common_get_api(`/cstm/prjt/cert?usr_id=${usr_id}`).then(
      ({ state, data }) => {
        if (state) {
          setData(data);
        }
      }
    );
    return () => {};
  }, [usr_id]);
  return (
    <div className="profile-certification-section">
      <div className="wrapper">
        <div className="certification-list">
          {data.length > 0 ? (
            data.map((item, idx) => {
              return (
                <ProfileCard
                  key={idx}
                  NavProject={undefined}
                  type="certification"
                  data={item}
                  download={Download}
                  NavSupport={undefined}
                />
              );
            })
          ) : (
            <EmtyList text={` 발급받은 인증서가 없습니다`} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Certification;
