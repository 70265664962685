import React from "react";
import "./css/index.css";
type Props = {
  type: "mtup" | "prjt";
};

const RefundNotice = ({ type }: Props) => {
  return (
    <div className="refund-section">
      <div className="wrapper">
        <div className="title">환불 규정</div>
        <div className="sub">
          <span>결제 당일</span> 전액 환불 가능
        </div>
        <div className="refund-box">
          {type === "prjt"
            ? refundArr.map(({ title, content }, idx) => {
                return (
                  <div key={idx} className="box-line">
                    <div className="title">{title}</div>
                    <div className="content">{content}</div>
                  </div>
                );
              })
            : mtupArr.map(({ title, content }, idx) => {
                return (
                  <div key={idx} className="box-line">
                    <div className="title">{title}</div>
                    <div className="content">{content}</div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default RefundNotice;
const refundArr = [
  {
    title: "환불요청시점",
    content: "환불 금액",
  },
  {
    title: "팀프로젝트 신청기간",
    content: "신청 철회 언제든 가능(결제 이전)",
  },
  {
    title: "결제일 당일",
    content: "전액 환불",
  },
  {
    title: "프로젝트 시작일 전날까지",
    content: "수수료 50% 제외 환불",
  },
  {
    title: "프로젝트 시작일 및 시작일 이후",
    content: "환불 불가",
  },
];
const mtupArr = [
  {
    title: "환불요청시점",
    content: "환불 금액",
  },
  {
    title: "결제일 당일",
    content: "전액 환불",
  },
  {
    title: "결제일 다음날 ~ 밋업 하루전까지",
    content: "취소 수수료 50%제외 환불",
  },
  {
    title: "밋업 당일",
    content: "환불 불가",
  },
];
