import React, { useState } from "react";
import { Link } from "react-router-dom";
import UseGetCommon from "../../hooks/UseGetCommon";
import { encrypto } from "../../lib/lib";
import EmtyList from "../EmtyList/EmtyList";
type Props = {};

const AboutReview = (props: Props) => {
  const [ReviewData] = UseGetCommon({
    path: `/web/cmnt/2?bbs_id=4&ctts_ctg_cd=MGN_02&row_limit_cnt=9`,
    limit: 0,
  });

  const [now, setNow] = useState<number>(0);
  const [scroll, setScroll] = useState<number>(0);
  return (
    <div className="section4">
      <div className="wrapper">
        <div className="title-wrapper">
          <div className="left">
            <img src="/assets/common/about/plus.svg" alt=""></img>
            <div className="title">팀플러들의 생생한 후기</div>
          </div>
          <div className="right">
            <img src="/assets/common/about/speech.svg" alt=""></img>
          </div>
        </div>

        <div className="list-wrapper">
          {now !== 0 ? (
            <div className="img-left">
              <img
                src="/assets/common/about/left-menu.svg"
                alt=""
                onClick={() => {
                  setNow(now - 1);
                }}
              ></img>
            </div>
          ) : undefined}
          <div
            className="list"
            onScroll={(e) => {
              const width = e.currentTarget.scrollWidth;
              const left = e.currentTarget.scrollLeft;
              setScroll((100 * left) / (width - e.currentTarget.clientWidth));
            }}
          >
            <div
              className="box-wrapper"
              style={{ transform: `translateX(-${now * 1050}px)` }}
            >
              {ReviewData.length > 0 ? (
                ReviewData.map(
                  ({ thumbnail, reader_usr_info, ctts_id, ctts_tit }, idx) => {
                    return (
                      <Link
                        key={idx}
                        className="magazine-card"
                        to={`/detail/magazine/${encrypto(ctts_id)}`}
                      >
                        <div
                          className="img-wrapper"
                          style={{
                            backgroundImage: `url(${thumbnail})`,
                          }}
                        />
                        <div className="title">{ctts_tit}</div>
                        <div className="sub">{reader_usr_info}</div>
                      </Link>
                    );
                  }
                )
              ) : (
                <>
                  <EmtyList text="작성된 후기가 없습니다" />
                  <EmtyList text="작성된 후기가 없습니다" />
                  <EmtyList text="작성된 후기가 없습니다" />
                </>
              )}
            </div>
          </div>
          {Math.ceil(ReviewData.length / 3) > now + 1 ? (
            <div className="img-right">
              <img
                src="assets/common/about/right-menu.svg"
                alt=""
                onClick={() => {
                  setNow(now + 1);
                }}
              />
            </div>
          ) : undefined}
        </div>
        {ReviewData.length > 3 ? (
          <div className="scroll-mb">
            <div
              className="scroll-mb-blue"
              style={{
                marginLeft: `${scroll < 90 ? scroll : 90}%`,
              }}
            ></div>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default AboutReview;
