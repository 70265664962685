import React, { useCallback, useState } from "react";
import { NavigateFunction, useOutletContext } from "react-router-dom";
import PaymentIntroArea from "../../../components/PaymentIntroArea/PaymentIntroArea";
import {
  MeetupBasicDataType,
  ProjectBasicDataType,
} from "../../../interface/interface";

type Props = {};

const PaymentIntro = (props: Props) => {
  const {
    data,
    navigate,
    field,
    pid,
  }: {
    data: ProjectBasicDataType | MeetupBasicDataType;
    navigate: NavigateFunction;
    field: string;
    pid: string;
  } = useOutletContext();
  const [brif, setBrif] = useState<string>("");
  const [subBirf, setsubBirf] = useState<string>("");

  const SetBrif = useCallback((type: "main" | "sub", value: string) => {
    if (type === "main") {
      setBrif(value);
    }
    if (type === "sub") {
      setsubBirf(value);
    }
  }, []);
  const __navCheck = useCallback(() => {
    navigate("./receipt", {
      state: {
        data,
        field,
        pid,
        content: {
          apply_self_brif_1: brif,
          apply_self_brif_2: subBirf,
        },
      },
    });
  }, [navigate, brif, subBirf, data, field, pid]);

  return (
    <div className="payment-intro">
      <img
        src="/assets/payment/intro.png"
        srcSet="/assets/payment/intro@2x.png 2x , /assets/payment/intro@3x.png 3x"
        alt=""
      />
      <div className="title">
        신청 전, 선정을 위해 <br /> 자신을 소개해주세요
      </div>
      <div className="project-card">
        <div className="title">
          {data.prjt_type_cd === "prjt" ? "프로젝트" : "밋업"}
        </div>
        <div className="card">
          <div
            className="img-wrapper"
            style={{ backgroundImage: `url(${data.prjt_thumbnail})` }}
          />
          <div className="title">{data.prjt_nm}</div>
        </div>
      </div>
      <div className="area-wrapper">
        <PaymentIntroArea
          number="01"
          title={`${`유사프로젝트 참여 경험이 있나요? 그 프로젝트에서 자신의 역할은 무엇인가요?`} `}
          __update={SetBrif}
          value={brif}
          type="main"
        />
        <PaymentIntroArea
          number="02"
          title={`지원시 팀플리더가 요청한 필수정보는 여기에 작성해주세요.`}
          sub="요청한 정보가 없는 경우에는, 팀플리더가 선정에 참고할 수 있는 내용을 적어주세요."
          value={subBirf}
          __update={SetBrif}
          type="sub"
        />
      </div>
      <button className="next-btn" onClick={__navCheck}>
        작성 완료
      </button>
    </div>
  );
};

export default PaymentIntro;
