import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_scrap_api,
  post_scrap_api,
  remove_scrap_api,
} from "../api/scrap_api";
import { RootState } from "../reducers";
import { ToastChange } from "../reducers/config";
import { ScrapInit, SrcapType } from "../reducers/data";

type ReturnType = [
  {
    prjt: {
      lst: any[];
    };
    mtup: {
      lst: any[];
    };
    mgn: {
      lst: any[];
    };
  },
  (id: { prjt_id: string } | { ctts_id: string }) => void,
  (id: string, type: "prjt" | "mgn" | "mtup") => void
];

const UseScrap = (): ReturnType => {
  const dispatch = useDispatch();
  const usr_id = useSelector<RootState, string>((state) => state.auth.usr_id);
  const ScrapList = useSelector<RootState, SrcapType>(
    (state) => state.data.scrap
  );
  const GetScrap = useCallback(() => {
    get_scrap_api().then(({ state, data }) => {
      if (state) {
        dispatch(
          ScrapInit({
            mgn: data.mgn,
            prjt: { lst: data.prjt },
            mtup: { lst: data.mtup },
          })
        );
      }
    });
  }, [dispatch]);

  const PostScarp = useCallback(
    (
      body:
        | {
            prjt_id: string;
          }
        | {
            ctts_id: string;
          }
    ) => {
      if (usr_id) {
        post_scrap_api(body).then(({ state }) => {
          if (state) {
            GetScrap();
          }
        });
      } else {
        dispatch(
          ToastChange({ active: true, content: "로그인 후에 이용해주세요" })
        );
      }
    },
    [GetScrap, usr_id, dispatch]
  );
  const RemoveScarp = useCallback(
    (id: string, type: "prjt" | "mgn" | "mtup") => {
      if (type === "mgn") {
        ScrapList.mgn.lst.forEach((item) => {
          if (item.ctts_id === id) {
            remove_scrap_api(item.fav_id).then(({ state }) => {
              if (state) {
                GetScrap();
              }
            });
          }
        });
      } else {
        ScrapList[type].lst.forEach((item) => {
          if (item.prjt_id === id) {
            remove_scrap_api(item.fav_id).then(({ state }) => {
              if (state) {
                GetScrap();
              }
            });
          }
        });
      }
    },
    [GetScrap, ScrapList]
  );

  return [ScrapList, PostScarp, RemoveScarp];
};

export default UseScrap;
