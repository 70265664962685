import React from "react";
import { useSelector } from "react-redux";
import CardSection from "../../components/CardSection/CardSection";
import { RootState } from "../../reducers";
import "./css/index.css";
function MyCard() {
  const usr_id = useSelector<RootState, string>((state) => state.auth.usr_id);

  return (
    <div className="mycard">
      <div className="wrapper">
        <div className="title">결제 카드 등록</div>
        <div className="card-title">
          <div className="card-text">카드등록정보</div>
          <div className="card-text2">최대 3개 까지 등록 가능합니다.</div>
        </div>
        <CardSection
          usr_id={usr_id}
          type="my"
          initCard={undefined}
          selectCard={undefined}
          period={undefined}
          category={undefined}
        />
        <div className="line"></div>
        <div className="notice">
          <div className="title">유의사항</div>
          <div className="notice-box">
            <div className="blue"></div>
            <div className="text">
              최초카드 등록후 <span>100원의 결제와 </span>취소가 진행됩니다.{" "}
              <br />
              유효 카드 확인을 위해 PG사(다날쏘시오)에서 진행하는 테스트 결제로{" "}
              <br /> <span>자동 취소처리가 진행</span>되니 안심하셔도 됩니다.
            </div>
          </div>
          <div className="notice-box">
            <div className="blue"></div>
            <div className="text">
              등록된 카드 정보는 암호화된 데이터로 PG(다날쏘시오)에 전달됩니다.
            </div>
          </div>
          <div className="notice-box">
            <div className="blue2"></div>
            <div className="text">
              <span>myteample@myteample.com</span> 으로 가입 닉네임, <br />
              등록 환경(모바일 / 웹), 오류 내용 등을 문의주시면 <br /> 담당자가
              빠른 시일내 회신드리겠습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCard;
