import { clientApi } from "./config";
import { NetworkError } from "./get_api";

type FixUserType = {
  usr_id: string;
  login_nm: string;
  info_desc: string;
  link: string;
  tag_lst: { tag_nm: string }[];
};

type ReturnType = {
  state: boolean;
  data: FixUserType | undefined;
  msg: string;
};
const Fix_user_info = (basic: FixUserType): Promise<ReturnType> => {
  return clientApi
    .post("/cstm/usr/info", basic)
    .then(({ data: { sts_fg, err_msg } }) => {
      if (sts_fg) {
        return { state: true, data: basic, msg: "" };
      } else {
        return { state: false, msg: err_msg, data: undefined };
      }
    })
    .catch(() => {
      return NetworkError;
    });
};
const Fix_user_Email = (email: string, usr_id: string): Promise<ReturnType> => {
  return clientApi
    .post("/cstm/usr/info", { usr_email: email, usr_id })
    .then(({ data: { sts_fg, err_msg } }) => {
      if (sts_fg) {
        return { state: true, data: undefined, msg: "" };
      } else {
        return { state: false, msg: err_msg, data: undefined };
      }
    })
    .catch((e) => {
      return NetworkError;
    });
};

const Check_usr_id = (login_id: string): Promise<boolean> => {
  return clientApi
    .post("/auth/isExist", { login_id })
    .then(({ data: { sts_fg } }) => {
      return sts_fg;
    });
};
const Check_usr_email = (
  email: string
): Promise<{ state: boolean; usr_id: string; login_id: string }> => {
  return clientApi
    .get(`/auth/usr/duplEmail?usr_email=${email}`)
    .then(({ data: { succ_obj } }) => {
      return succ_obj === "NONE"
        ? {
            state: false,
            usr_id: "",
            login_id: "",
          }
        : {
            state: true,
            usr_id: succ_obj.usr_id,
            login_id: succ_obj.login_id,
          };
    })
    .catch((err) => {
      return {
        state: false,
        usr_id: "",
        login_id: "",
      };
    });
};

const delete_usr_card = (id: string) => {
  return clientApi
    .delete(`/cstm/crd/${id}`)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      console.log(err_msg);
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "",
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch(() => {
      return NetworkError;
    });
};

const Insert_usr_card = (data: {
  usr_id: string;
  crd_nm: string;
  crd_num: string;
  crd_code: string;
}) => {
  return clientApi
    .post("/cstm/crd", data)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "",
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch(() => {
      return NetworkError;
    });
};
const Get_usr_card = (usr_id: string) => {
  return clientApi
    .get(`/cstm/crd?usr_id=${usr_id}`)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "",
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch(() => {
      return NetworkError;
    });
};
const Get_usr_coupon = (path: string) => {
  return clientApi
    .get(path)
    .then(({ data: { sts_fg, succ_obj, err_msg } }) => {
      if (sts_fg) {
        return {
          state: true,
          data: succ_obj,
          msg: "",
        };
      } else {
        return {
          state: false,
          msg: err_msg,
        };
      }
    })
    .catch(() => {
      return NetworkError;
    });
};

export {
  Fix_user_info,
  Check_usr_id,
  Check_usr_email,
  Insert_usr_card,
  Get_usr_card,
  Get_usr_coupon,
  delete_usr_card,
  Fix_user_Email,
};
