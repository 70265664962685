import React from "react";
import { useMediaQuery } from "react-responsive";

type ReturnType = [
  boolean,
  boolean,
  boolean,
  ({ children }: { children: JSX.Element }) => JSX.Element | null,
  ({ children }: { children: JSX.Element }) => JSX.Element | null,
  ({ children }: { children: JSX.Element }) => JSX.Element | null
];

const UseResponsive = (): ReturnType => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1511 });
  const isDesktop = useMediaQuery({ minWidth: 1512 });
  const Desktop = ({ children }: { children: JSX.Element }) => {
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }: { children: JSX.Element }) => {
    return isTablet ? children : null;
  };
  const Mobile = ({ children }: { children: JSX.Element }) => {
    return isMobile ? children : null;
  };
  return [isDesktop, isTablet, isMobile, Mobile, Tablet, Desktop];
};

export default UseResponsive;
