import React, { useEffect, useState } from "react";
import { ReactComponent as Scrapsvg } from "../../components/ListCard/svg/scrap.svg";
import { useLocation } from "react-router-dom";
import { clientApi } from "../../api/config";
import Footer from "../../components/Footer/Footer";
import MagazineMore from "../../components/More/MagazineMore";
import Screen from "../../components/Screen/Screen";
import UseScrap from "../../hooks/UseScrap";
import { decypto } from "../../lib/lib";
import "./css/index.css";
type MagazineDataType = {
  thumbnail: string;
  reader_usr_info: string;
  ctts_id: string;
  ctts_tit: string;
  ctts_ctg_cd: string;
  ctts_ctts: string;
  reader_nm: string;
  link: string;
};

const MagazineDetail = () => {
  const { pathname } = useLocation();
  const [data, setData] = useState<MagazineDataType | undefined>(undefined);
  const [ScrapList, PostScarp, RemoveScarp] = UseScrap();
  const isScrap =
    ScrapList.mgn.lst.filter(({ ctts_id }) => data?.ctts_id === ctts_id)
      .length > 0;
  useEffect(() => {
    const id = decypto(pathname.substring(17, pathname.length));
    clientApi
      .get(`/web/cmnt/2/ctts/${id}`)
      .then(({ data: { sts_fg, succ_obj } }) => {
        if (sts_fg) {
          setData(succ_obj);
        }
      });
    return () => {};
  }, [pathname]);

  return data ? (
    <main className="magazine-detail">
      <div className="leader-section">
        <div className="black-back" />
        <div className="wrapper">
          <Scrapsvg
            className={isScrap ? "scrap-on" : ""}
            onClick={() => {
              if (isScrap) {
                RemoveScarp(data.ctts_id, "mgn");
              } else {
                PostScarp({ ctts_id: data.ctts_id });
              }
            }}
          />
          <div
            className="img-wrapper"
            style={{
              backgroundImage: `url(${data.thumbnail})`,
            }}
          />
          <div className="title-section">
            <div className="title">{data.ctts_tit}</div>
            <div className="position">
              {data.ctts_ctg_cd === "MGN_01"
                ? `월간 팀플리더 | ${data.reader_nm ? data.reader_nm : ""}`
                : data.ctts_ctg_cd === "MGN_02"
                ? "팀플러 인터뷰"
                : "워크 스토리"}
            </div>
            <div className="sub">{data.reader_usr_info}</div>
          </div>
        </div>
      </div>
      <div className="content-wrapper">
        <Screen data={data.ctts_ctts} />
        <a className="leader-project-btn" href={data.link}>
          <div className="left">
            <img
              src="/assets/popup/marketing.png"
              srcSet="/assets/popup/marketing@2x.png 2x , /assets/popup/marketing@3x.png 3x"
              alt=""
            />
            <div className="btn-title-section">
              <div className="sub">
                지금 인터뷰 속 팀플리더를 만나보고 싶다면?
              </div>
              <div className="title">팀플 프로젝트 및 밋업 신청</div>
            </div>
          </div>
          <img src="/assets/common/black-arrow.svg" alt="" className="right" />
        </a>
      </div>
      <div className="grey-bar" />
      <MagazineMore mg_id={data.ctts_id} />
      <Footer />
    </main>
  ) : (
    <></>
  );
};

export default MagazineDetail;
