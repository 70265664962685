import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LoginBtn from "../../../components/LoginBtn/LoginBtn";
import "./css/finish.css";
function SignupFinish() {
  const navigate = useNavigate();
  const __nav = useCallback(() => {
    navigate("/firstinfo/step1");
  }, [navigate]);

  return (
    <div className="signup-finish">
      <div className="wrapper">
        <img
          src="/assets/login/finish.png"
          srcSet="/assets/login/finish@2x.png 2x , /assets/login/finish@3x.png 3x"
          alt=""
          className="main-img"
        />
        <div className="title">환영합니다!</div>
        <div className="sub">
          팀플에 가입이 완료되었습니다 <br /> 더 상세한 [내 정보 추가하기]를
          통해 <br />
          사이드 프로젝트를 신청해보세요
        </div>
        <LoginBtn
          type="login"
          title="자기소개와 포트폴리오 등록하기"
          __submit={__nav}
        />
      </div>
    </div>
  );
}

export default SignupFinish;
