import React, { Dispatch, SetStateAction } from "react";
import "./css/index.css";
type Props = {
  isFocus: boolean | undefined;
  setIsFoucs: Dispatch<SetStateAction<boolean>> | undefined;
  setValue: Dispatch<SetStateAction<string>>;
  isCertify: boolean;
  setIsCertify: Dispatch<SetStateAction<boolean>>;
  Certify: () => void;
  type: string | undefined;
  category: "send" | "check" | "fix";
  value?: string;
};

/** 검증 요청이 달린 input comp */
const CertifyInput = ({
  type,
  isFocus,
  setIsFoucs,
  setValue,
  isCertify,
  Certify,
  category,
  setIsCertify,
  value,
}: Props) => {
  return (
    <div className="email-input-wrapper">
      <div className="tag-wrapper">
        <div className="tag">이메일</div>
        <div className={`text ${type}`}>
          {category === "send"
            ? type === "finish"
              ? "아래 버튼을 눌러 메일로 변경링크를 보냅니다"
              : "존재하지 않는 이메일입니다"
            : type === "break"
            ? "이미 존재하는 이메일입니다."
            : type === "reg"
            ? "이메일 형식을 확인해주세요"
            : type === "gap"
            ? "이메일을 입력해주세요"
            : ""}
        </div>
      </div>
      <div
        className={`input-wrapper certify-${type} ${isFocus ? "focus" : ""}`}
      >
        <input
          type="text"
          value={value}
          placeholder={
            category === "send" ? "가입한 메일 주소 입력" : "이메일 주소 입력"
          }
          onFocus={() => {
            if (setIsFoucs) {
              setIsFoucs(true);
            }
          }}
          onBlur={() => {
            if (setIsFoucs) {
              setIsFoucs(false);
            }
          }}
          onChange={(e) => {
            if (isCertify) {
              setIsCertify(false);
            }
            setValue(e.target.value);
          }}
        />
        <button onClick={Certify} className={`init ${isCertify ? "send" : ""}`}>
          {isCertify ? (category === "fix" ? "수정하기" : "확인완료") : "확인"}
        </button>
      </div>
    </div>
  );
};

export default CertifyInput;
