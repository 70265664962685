import React from "react";
import { Link } from "react-router-dom";
import { Bannerinterface } from "../../interface/interface";
import { encrypto } from "../../lib/lib";
import EmtyList from "../EmtyList/EmtyList";
import { ReactComponent as Arrowsvg } from "./svg/white-arrow.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
type Props = {
  data: Bannerinterface[];
};

function MainNotice({ data }: Props) {
  return (
    <div className="main-notice">
      <div className="wrapper">
        <div className="title-wrapper">
          <img src="/assets/main/project.svg" alt="" />
          <div className="title">팀플이 알립니다</div>
        </div>
        {data.length > 0 ? (
          <div className="notice-list">
            {data.slice(0, 5).map((item, idx) => {
              return item ? (
                <MainNoticeCard key={idx} data={item} />
              ) : (
                <Skeleton
                  key={idx}
                  containerClassName="notice-nav-link"
                  height={"100%"}
                />
              );
            })}
          </div>
        ) : (
          <EmtyList text="등록된 공지사항이 없습니다" />
        )}
        <div className="notice-list-nav-wrapper">
          <Link className="notice-list-nav" to="/notice">
            <div className="title">공지사항 전체보기</div>
            <Arrowsvg />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MainNotice;

const MainNoticeCard = ({ data }: { data: Bannerinterface }) => {
  const { ctts_tit, ctts_reg_at, ctts_id } = data;
  const date = new Date(ctts_reg_at);
  return (
    <Link
      className="notice-nav-link"
      to={`/notice/detail/${encrypto(ctts_id)}`}
    >
      <div className="title">{ctts_tit}</div>
      <div className="bottom">
        <div className="date">
          {date.getFullYear()}년 {date.getMonth() + 1}월 {date.getDate()}일
        </div>
        <div className="user">팀플 운영관리팀</div>
      </div>
    </Link>
  );
};
