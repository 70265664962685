import React from "react";
import "./css/index.css";
type Props = {
  DeskTop: ({ children }: { children: JSX.Element }) => JSX.Element | null;
  Tablet: ({ children }: { children: JSX.Element }) => JSX.Element | null;
  Mobile: ({ children }: { children: JSX.Element }) => JSX.Element | null;
};

const AboutSection2 = ({ DeskTop, Tablet, Mobile }: Props) => {
  return (
    <div className="about-section2">
      <div className="wrapper">
        <div className="title">
          팀플러가 되면? <br />
          나에게 필요한{" "}
          <Mobile>
            <br />
          </Mobile>{" "}
          <span>직무역량과 네트워크는 물론,</span> <br />
          실제 결과물을 얻을 수 있습니다
        </div>
        <div className="list">
          {section2.map((item, index) => {
            return (
              <div key={index} className="box">
                <div className="left">
                  <div className="img">{item.img}</div>
                </div>
                <div className="right">
                  <div className="title">{item.title}</div>
                  {item.sub}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutSection2;

const section2 = [
  {
    img: <img src="/assets/common/about/section2-image1.png" alt=""></img>,
    title: "01 프로젝트를 위한 직무교육",
    sub: (
      <div className="sub">
        직무경험이 필요하거나 커리어 전환을 하고 싶다면,{" "}
        <br className="mb-br" /> 팀플을 통해 현직자와 함께{" "}
        <br className="pc-br" /> 해당 분야의 역량과 <br className="mb-br" />{" "}
        노하우를 쌓고 포트폴리오를 만들어 보세요.
      </div>
    ),
  },
  {
    img: <img src="/assets/common/about/section2-image2.png" alt=""></img>,
    title: "02  완주를 위한 리워드",
    sub: (
      <div className="sub">
        프로젝트를 완주한 모든 팀플러에게 <br className="mb-br" /> 팀플리더가
        발행한 완주 인증서를 제공합니다. <br /> 팀플리더가 선정한 우수한
        팀플러는 <br className="mb-br" /> 팀플리더가 오픈하는 다른 프로젝트에
        <br /> 무료로 참여 할 수 있는 인비테이션이 제공됩니다.
      </div>
    ),
  },
  {
    img: <img src="/assets/common/about/section2-image3.png" alt=""></img>,
    title: "03  중개수수료 ZERO 크라우드펀딩",
    sub: (
      <div className="sub">
        프로젝트 결과물에 대한 시장검증이 필요한가요? <br className="mb-br" />{" "}
        팀플러라면 프로젝트퀘스천이 <br className="pc-br" /> 운영하는{" "}
        <br className="mb-br" /> 크라우드펀딩 플랫폼을 중개 수수료 없이{" "}
        <br className="mb-br" /> 이용할 수 있습니다.
      </div>
    ),
  },
  {
    img: <img src="/assets/common/about/section2-image4.png" alt=""></img>,
    title: "04  창업 팀원과의 만남",
    sub: (
      <div className="sub">
        다양한 직군의 팀플러와 함께 프로젝트를 진행하면서{" "}
        <br className="mb-br" /> 역량 있는 팀원을 물색해 보세요. <br /> 팀플을
        통해 새로운 도전을 함께 할 수 있는 <br className="mb-br" /> 든든한
        팀원을 만날 수 있어요.
      </div>
    ),
  },
  {
    img: <img src="/assets/common/about/section2-image5.png" alt=""></img>,
    title: "05  리더와 소통하는 '밋업'",
    sub: (
      <div className="sub">
        마이팀플에서는 1:다수의 정보전달식 온라인 강의가 아닌,
        <br className="mb-br" /> 현직자와 직적 소통하며 <br className="pc-br" />{" "}
        프로젝트 경험과
        <br className="mb-br" />
        노하우를 나누는 밋업 이벤트를 제공합니다. <br />
        팀플리더의 프로젝트에 참여하기 전에
        <br className="mb-br" /> 리더의 관점과 성향을 경험할 수 있습니다
      </div>
    ),
  },
];
