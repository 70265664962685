import React, { useCallback, useEffect, useState } from "react";
import UseGetAdd from "../../hooks/UseGetAdd";
import { reg } from "../Banner/Banner";
import "./css/index.css";
function LineAdd() {
  const [AddData] = UseGetAdd({ type: "line" });
  const [now, setNow] = useState(0);
  const __swipe = useCallback(() => {
    setTimeout(() => {
      setNow((prev) => (prev >= AddData.length - 1 ? 0 : prev + 1));
    }, 2800);
  }, [AddData]);
  useEffect(() => {
    __swipe();
    return () => {};
  }, [__swipe, now]);
  return (
    <div
      className={`line-add ${
        AddData.length > 0 && AddData[now].bg_color.replace(reg, "")
      }`}
    >
      <div className="wrapper">
        <div
          className="banner-wrapper"
          style={{
            gridTemplateRows: `repeat(${AddData.length},100%)`,
            transform: `translateY(-${now * 100}%)`,
          }}
        >
          {AddData.map(
            (
              { ctts_tit, ctts_ctts, ctts_link, btn_label, ad_thumbnail },
              idx
            ) => {
              return (
                <div className="line-banner" key={idx}>
                  <div className="left">
                    <div className="title">{ctts_tit}</div>
                    <div className="sub">{ctts_ctts}</div>
                    <a href={ctts_link} target={"_blank"} rel="noreferrer">
                      {btn_label}
                    </a>
                  </div>
                  <img src={ad_thumbnail} alt="" />
                </div>
              );
            }
          )}
        </div>
        <div className="index-wrapper">
          {AddData.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`circle ${now === idx ? "cl-active" : ""}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LineAdd;
