import { createSlice } from "@reduxjs/toolkit";
type AuthInfoType = {
  login_nm: string;
  img: string;
  info_desc: string;
  link: string;
  tag_lst: { tag_nm: string }[];
};

type gradeType = {
  cnt_prjt: number;
  cnt_end: number;
  cnt_inbi: number;
};
export type AuthType = {
  usr_typ: string;
  usr_id: string;
  email: string;
  info: AuthInfoType;
  grade: gradeType;
};

const initialState: AuthType = {
  usr_id: "",
  email: "",
  usr_typ: "",
  info: {
    login_nm: "",
    img: "",
    info_desc: "",
    link: "",
    tag_lst: [],
  },
  grade: {
    cnt_prjt: 0,
    cnt_end: 0,
    cnt_inbi: 0,
  },
};

const configReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    ResetAuth: () => initialState,
    AllAuth: (state, { payload }) => payload,
    LoginUser: (state, { payload }: { payload: string }) => {
      state.usr_id = payload;
    },
    GradeUpdate: (state, { payload }: { payload: gradeType }) => {
      state.grade = payload;
    },
    UserEmail: (state, { payload }: { payload: string }) => {
      state.email = payload;
    },
    UserImg: (state, { payload }: { payload: string }) => {
      state.info.img = payload;
    },
    UpdateAuthInfo: (state, { payload }: { payload: AuthInfoType }) => {
      state.info = payload;
    },
  },
});

export const {
  LoginUser,
  UpdateAuthInfo,
  GradeUpdate,
  UserEmail,
  UserImg,
  ResetAuth,
  AllAuth,
} = configReducer.actions;

export default configReducer.reducer;
