import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import EmtyList from "../../components/EmtyList/EmtyList";
import Footer from "../../components/Footer/Footer";
import Infinite from "../../components/Infinite/Infinite";
import NoticeCard from "../../components/NoticeCard/NoticeCard";
import UseGetCommon from "../../hooks/UseGetCommon";
import { encrypto } from "../../lib/lib";

import "./css/index.css";

function Notice() {
  const navigate = useNavigate();
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  const [NoticeData, PagingUpdate] = UseGetCommon({
    path: "/web/cmnt/2?bbs_id=3&row_limit_cnt=10",
  });
  // const [type, settype] = useState<string | undefined>(undefined);
  const __nav = useCallback(
    (id: string) => {
      navigate(`/notice/detail/${encrypto(id)}`);
    },
    [navigate]
  );
  return (
    <>
      <main className="notice">
        <div className="wrapper">
          <div className="title">
            <div className="titleLeft">공지 사항</div>
          </div>
          {NoticeData.length > 0 ? (
            <div className="notice-wrapper">
              {NoticeData.map((item, idx) => {
                return <NoticeCard key={idx} data={item} __nav={__nav} />;
              })}
              <Infinite PagingUpdate={PagingUpdate} />
            </div>
          ) : (
            <EmtyList text="등록된 공지사항이 없습니다" />
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Notice;
