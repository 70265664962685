import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  authinterface,
  detailtype,
  ProjectBasicDataType,
} from "../../../interface/interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import Detail from "../Detail";
import { Project_detial_api } from "../../../api/get_api";
import { ToastChange } from "../../../reducers/config";
import { decypto, PrjtCategory } from "../../../lib/lib";

type LocType = {
  pathname: string;
  state: "review" | undefined;
};

const DetailContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation() as LocType;
  const id = decypto(location.pathname.substring(16, location.pathname.length));
  const authInfo = useSelector<RootState, authinterface>((state) => state.auth);
  const [ProjectData, setProjectData] = useState<
    ProjectBasicDataType | undefined
  >(undefined);
  const [selectField, setSelectField] = useState<detailtype | undefined>(
    undefined
  );
  const [navIndex, setNavIndex] = useState<number>(0);
  const __navPayment = useCallback(
    (field: detailtype | undefined) => {
      if (field) {
        navigate("/payment/intro", {
          state: {
            data: ProjectData,
            field,
            pid: id,
          },
        });
      }
    },
    [ProjectData, navigate, id]
  );
  useEffect(() => {
    if (location.state === "review") {
      if (
        ProjectData?.prjt_sts_cd === "prjt_sts_rcrt_ing" ||
        ProjectData?.prjt_sts_cd === "prjt_sts_rcrt_wait"
      ) {
        setNavIndex(2);
      } else {
        setNavIndex(3);
      }
    }

    return () => {};
  }, [location, ProjectData]);

  useEffect(() => {
    Project_detial_api(`/web/cstm/prjt/${id}`)
      .then(({ state, data }) => {
        if (state) {
          if (data?.prjt_sts_cd === "prjt_sts_rcrt_paus") {
            navigate("/");
            dispatch(
              ToastChange({
                active: true,
                content: "팀리더가 프로젝트를 수정중입니다",
              })
            );
          } else {
            setProjectData(data);
          }
        }
      })
      .catch(() => {
        dispatch(
          ToastChange({
            active: true,
            content: "네트워크가 불안정합니다.",
          })
        );
      });
    return () => {};
  }, [dispatch, id, navigate]);

  return (
    <Detail
      Data={ProjectData ? ProjectData : undefined}
      selectField={selectField}
      setSelectField={setSelectField}
      navIndex={navIndex}
      __navPayment={__navPayment}
      setNavIndex={setNavIndex}
      authInfo={authInfo}
      id={id}
      dispatch={dispatch}
      type={"project"}
      categoryContent={
        ProjectData &&
        PrjtCategory[ProjectData.prjt_ctg_cd as keyof typeof PrjtCategory]
      }
      navLayout={
        ProjectData?.prjt_sts_cd === "prjt_sts_rcrt_ing" ||
        ProjectData?.prjt_sts_cd === "prjt_sts_rcrt_wait"
          ? readyLayout
          : finishLayout
      }
    />
  );
};

export default DetailContainer;

const finishLayout = ["정보", "문의하기", "선정 팀원", "후기"];

const readyLayout = ["정보", "문의하기", "후기"];
