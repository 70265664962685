import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { encrypto } from "../../lib/lib";

type Props = {
  data: any;
};

const AboutNoticeCard = ({ data }: Props) => {
  const [image, setImage] = useState<string>("");
  const [text, setText] = useState<string>("");
  useEffect(() => {
    const { ctts_ctts }: { ctts_ctts: string } = data;
    const dummy = document.createElement("div");
    dummy.innerHTML = ctts_ctts;
    const textList = dummy.querySelectorAll("div");
    const imgList = dummy.querySelectorAll("img");
    for (let i = 0; i < textList.length; i++) {
      const e = textList[i];
      if (e.innerText.length > 3) {
        setText(e.innerText.split(".")[0]);
        break;
      }
    }
    imgList.length > 0 && setImage(imgList[0].src);
    return () => {};
  }, [data]);

  return (
    <Link className="box" to={`/notice/detail/${encrypto(data.ctts_id)}`}>
      <div className="img" style={{ backgroundImage: `url(${image})` }} />
      <div className="bottom-box">
        <div className="title">{data.ctts_tit}</div>
        <div className="sub">{text}</div>
      </div>
    </Link>
  );
};

export default AboutNoticeCard;
