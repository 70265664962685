import React, { Dispatch, SetStateAction } from "react";
import { CouponInter } from "../../interface/interface";
import "./css/index.css";
type Props = {
  data: CouponInter;
  pid: string;
  index: number;
  isSelect: boolean;
  __setCoupon: Dispatch<SetStateAction<CouponInter | undefined>>;
  category: "prjt" | "mtup";
};

const Coupon = ({ data, isSelect, __setCoupon, pid, category }: Props) => {
  const {
    cpon_nm,
    cpon_discnt_rate,
    cpon_type_cd,
    cpon_apply_en_at,
    cpon_ctg_cd,
    prjt_id,
  } = data;

  const disabled =
    cpon_type_cd === "normal"
      ? cpon_ctg_cd === category
        ? false
        : true
      : String(prjt_id) !== String(pid);
  const FinishDate = new Date(cpon_apply_en_at);
  return (
    <div
      className={`coupon-card ${
        disabled
          ? "disable"
          : cpon_type_cd === "normal"
          ? "coupon"
          : "invitation"
      }-type ${isSelect ? "select-coupon" : ""}`}
      onClick={() => {
        if (!disabled) {
          __setCoupon(isSelect ? undefined : data);
        }
      }}
    >
      <div className="coupon-content">
        <div className="type">
          {cpon_type_cd === "normal"
            ? `
            ${cpon_ctg_cd === "prjt" ? "프로젝트" : "밋업"}쿠폰
          `
            : "인비테이션"}
        </div>
        <div className="title">
          {cpon_nm}
          <br />
          {cpon_type_cd === "normal" ? `${cpon_discnt_rate}% 할인쿠폰` : ""}
        </div>
        <div className="period">
          {FinishDate.getFullYear()}년 {FinishDate.getMonth() + 1}월{" "}
          {FinishDate.getDate()}일까지 사용 가능
        </div>
      </div>
      <div className="coupon-line">
        <div className="circle" />
        <div className="line" />
        <div className="circle" />
      </div>
      <div className="coupon-check">
        <div className="title">{disabled ? "사용불가" : "사용하기"}</div>
        <div className="circle">
          <img src="/assets/common/black-check.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Coupon;
