import React from "react";
import EmtyList from "../../../components/EmtyList/EmtyList";
import MemberCard from "../../../components/MemberCard/MemberCard";

type Props = {
  title: string;
  data: any[];
  usr_id: string;
};

const Members = ({ title, data, usr_id }: Props) => {
  return (
    <div className="member-section">
      <div className="wrapper">
        <div className="title">{title}</div>
        <div className="member-list">
          {data.length > 0?
            data.map((item, idx) => {
              return <MemberCard key={idx} data={item} usr_id={usr_id} />;
            }) 
            : <EmtyList text="선정된 팀원이 없습니다"/>
          }
        </div>
      </div>
    </div>
  );
};

export default Members;
