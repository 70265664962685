import React, { Dispatch, SetStateAction } from "react";
import "./css/index.css";

function LoginInput({
  type,
  title,
  placeholder,
  setValue,
  errorType,
  value,
  Check,
  subTitle,
}: {
  type: string;
  title: string | undefined;
  placeholder: string;
  setValue: Dispatch<SetStateAction<string>> | ((p: string) => void);
  value: string | undefined;
  errorType: string | undefined;
  Check: (() => void) | undefined;
  subTitle?: string;
}) {
  return (
    <div className={`login-input ${errorType ? "input-error" : ""}`}>
      {title ? (
        <div className="title">
      <span>    {title} {subTitle&& <span className="sub-title">{subTitle}</span> }</span>
          <div className="error">{errorType}</div>
        </div>
      ) : undefined}
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        onBlur={() => {
          if (Check) {
            Check();
          }
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </div>
  );
}
export default LoginInput;
