import React from "react";
import { Link } from "react-router-dom";
import { ProjectCardInter } from "../../interface/interface";
import { DdayChange, encrypto } from "../../lib/lib";
import EmtyList from "../EmtyList/EmtyList";
import { ReactComponent as ArrowSvg } from "./svg/arrow.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function MainList({ data, type }: { data: ProjectCardInter[]; type: string }) {
  return (
    <div className="main-list">
      <div className="wrapper">
        <div className="title-wrapper">
          <img src={`/assets/main/${type}.svg`} alt="" />
          <div className="title">
            {type === "project" ? "프로젝트" : "밋업"}
          </div>
        </div>
        {data.length > 0 ? (
          <div className="list-wrapper">
            {data.map((item, idx) => {
              return item ? (
                <ContentMainListCard key={idx} data={item} type={type} />
              ) : (
                <div className="card" key={idx}>
                  <Skeleton containerClassName="card-img" height={"100%"} />
                </div>
              );
            })}
          </div>
        ) : (
          <EmtyList
            text={`현재 모집중인 ${
              type === "project" ? "프로젝트가" : "밋업이"
            } 없습니다.`}
          />
        )}
        <div className="nav-wrapper">
          <Link className="nav-type-btn" to={`/${type}`}>
            <div className="left">
              <img src={`/assets/main/${type}.svg`} alt="" />
              <div className="title">
                모든 {type === "project" ? "프로젝트" : "밋업"}
                보기
              </div>
            </div>
            <ArrowSvg />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MainList;

const ContentMainListCard = ({
  data,
  type,
}: {
  data: ProjectCardInter;
  type: string;
}) => {
  const { prjt_nm, rcrt_apply_en_at, prjt_thumbnail, prjt_id, prjt_sts_cd } =
    data;
  return (
    <Link className="card" to={`/detail/${type}/${encrypto(prjt_id)}`}>
      <div
        className="card-img"
        style={{ backgroundImage: `url(${prjt_thumbnail})` }}
      >
        {(prjt_sts_cd === "prjt_sts_ing" ||
          prjt_sts_cd === "prjt_sts_end" ||
          prjt_sts_cd === "prjt_sts_rcrt_end") && (
          <div className="finish-blur">
            <img src="/assets/list/finish-logo.svg" alt="logo" />
            <div className="blur-title">
              마감된 {type === "project" ? "프로젝트" : "밋업"}입니다
            </div>
          </div>
        )}
      </div>
      <div className="title">{prjt_nm}</div>
      <div className="line" />
      <div className="period">
        {prjt_sts_cd === "prjt_sts_rcrt_ing" ? (
          <>
            마감일 <span>D-{DdayChange(rcrt_apply_en_at)}</span>
          </>
        ) : prjt_sts_cd === "prjt_sts_rcrt_wait" ? (
          "오픈예정"
        ) : (
          "모집마감"
        )}
      </div>
    </Link>
  );
};
