import { createSlice } from "@reduxjs/toolkit";
import { ProjectScrapInter, ScrapMeetup } from "../../interface/interface";

export type SrcapType = {
  prjt: { lst: ProjectScrapInter[] };
  mtup: { lst: ScrapMeetup[] };
  mgn: {
    lst: {
      thumbnail: string;
      reader_usr_info: string;
      ctts_id: string;
      ctts_tit: string;
      fav_id: string;
    }[];
  };
};

type StateType = {
  removeComment: undefined | string;
  scrap: SrcapType;
};

const initialState: StateType = {
  removeComment: undefined,
  scrap: {
    prjt: { lst: [] },
    mtup: { lst: [] },
    mgn: { lst: [] },
  },
};

const dataReducer = createSlice({
  name: "data",
  initialState,
  reducers: {
    Remove_Comment_Id: (
      state,
      { payload }: { payload: string | undefined }
    ) => {
      state.removeComment = payload;
    },
    ScrapInit: (state, { payload }: { payload: any }) => {
      state.scrap = payload;
    },
  },
});

export const { Remove_Comment_Id, ScrapInit } = dataReducer.actions;

export default dataReducer.reducer;
