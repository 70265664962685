import React from "react";
import "./css/index.css";
type Props = {};

const AboutSection6 = (props: Props) => {
  return (
    <div className="about-section6">
      <div className="wrapper">
        <div className="title">팀플에게 문의하기</div>
        <div className="list">
          {section6.map((item, index) => {
            return (
              <div key={index} className={`box ${item.type}`}>
                <div className="img">{item.img}</div>
                <div className="title">{item.title}</div>
                <div className="sub">{item.sub}</div>
                <a className="button-wrapper" href={item.link}>
                  <div className="button">{item.button}</div>
                  <img src="/assets/common/about/right.svg" alt="" />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutSection6;

const section6 = [
  {
    img: <img src="/assets/common/about/handpointing.svg" alt=""></img>,
    title: "팀플리더 신청",
    sub: (
      <div>
        <br className="br-mb" />
        성공적인 프로젝트 완주를 위한
        <br className="br-mb" />
        디자인씽킹 교육과 <br className="br-pc" />
        광고 지원,
        <br className="br-mb" />
        리더 네트워크 참여 기회 등
        <br className="br-mb" />
        <br className="br-pc" /> 다양한 리더 등록 혜택을 만나보세요{" "}
      </div>
    ),
    button: "팀플리더 신청하기",
    link: "https://pqcompany.imweb.me/",
  },
  {
    img: <img src="/assets/common/about/mail.svg" alt=""></img>,
    title: "제휴 문의",
    sub: (
      <div>
        프로젝트퀘스천 팀플 서비스와
        <br className="br-mb" />
        <br className="br-pc" /> 제휴 프로젝트를 진행하고 싶으신가요?{" "}
        <br className="br-mb" />
        <br className="br-pc" />
        팀플 문의 메일로 연락주세요. <br className="br-mb" />
        <br className="br-mb" />
        <br className="br-pc" /> 문의메일 :<span className="mb-text"></span>{" "}
        <br className="br-mb" /> projectq@projectquestion.com
      </div>
    ),
    button: "제휴 문의하기",
    type: "blue",
    link: "mailto:projectq@projectquestion.com",
  },
  {
    img: <img src="/assets/common/about/chat.svg" alt=""></img>,
    title: "고객 문의",
    sub: (
      <div>
        CS 메일 : <br className="br-mb" />
        myteample@myteample.com
        <br className="br-mb" />
        <br className="br-mb" /> <br className="br-pc" />
        CS상담시간 : <br className="br-mb" /> 평일 10:00 ~ 18:00
        <br />
        (점심시간 13:00 ~ 14:00)
      </div>
    ),
    button: "고객 문의하기",
    type: "pupple",
    link: "mailto:myteample@myteample.com",
  },
];
