import React, { useEffect, useRef } from "react";
import "./css/index.css";
type Props = {
  basic: string;
  detail: string;
};

const MtupPlace = ({ basic, detail }: Props) => {
  const mapElement = useRef(null);
  const { kakao } = window;

  useEffect(() => {
    if (mapElement) {
      var geocoder = new kakao.maps.services.Geocoder();
      geocoder.addressSearch(basic, function (result: any, status: any) {
        // 정상적으로 검색이 완료됐으면
        if (status === kakao.maps.services.Status.OK) {
          const container = mapElement.current;
          const options = {
            center: new window.kakao.maps.LatLng(result[0].y, result[0].x),
          };
          const map = new window.kakao.maps.Map(container, options);
          const markerPosition = new window.kakao.maps.LatLng(
            result[0].y,
            result[0].x
          );
          const marker = new window.kakao.maps.Marker({
            position: markerPosition,
          });
          marker.setMap(map);
        }
      });
    }
    return () => {};
  }, [mapElement, basic, kakao]);

  return (
    <div className="mtup-place-section">
      <div className="mtup-title">밋업장소</div>
      <div className="mtup-place-wrapper">
        <img src="/assets/common/place.svg" alt="" />
        <div className="mtup-place-text">
          {basic} {detail}
        </div>
      </div>
      <div
        className="naver-map-section"
        ref={mapElement}
        style={{
          width: "100%",
          height: "218px",
        }}
      ></div>
    </div>
  );
};

export default MtupPlace;
