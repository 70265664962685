import React from "react";
import "./css/index.css";
import { ReactComponent as Scrapsvg } from "../ListCard/svg/scrap.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
type Props = {
  data: {
    thumbnail: string;
    reader_usr_info: string;
    ctts_id: string;
    ctts_tit: string;
  };
  __nav: (e: string) => void;
  scrap: (id: { ctts_id: string }) => void;
  unscrap: (id: string, type: "prjt" | "mgn" | "mtup") => void;
  isScrap: boolean;
};

const MagazineCard = ({ data, __nav, scrap, unscrap, isScrap }: Props) => {
  return (
    <div className="magazineCard">
      {data ? (
        <MagazineCardContent
          data={data}
          __nav={__nav}
          scrap={scrap}
          unscrap={unscrap}
          isScrap={isScrap}
        />
      ) : (
        <>
          <Skeleton containerClassName="img-wrapper" height="100%" />
          <div className="magazinecard-content">
            <Skeleton containerClassName="left" height="100%" count={2} />
          </div>
        </>
      )}
    </div>
  );
};

export default MagazineCard;

const MagazineCardContent = ({
  data,
  __nav,
  scrap,
  unscrap,
  isScrap,
}: {
  data: {
    thumbnail: string;
    reader_usr_info: string;
    ctts_id: string;
    ctts_tit: string;
  };
  __nav: (e: string) => void;
  scrap: (id: { ctts_id: string }) => void;
  unscrap: (id: string, type: "prjt" | "mgn" | "mtup") => void;
  isScrap: boolean;
}) => {
  const { thumbnail, reader_usr_info, ctts_id, ctts_tit } = data;
  return (
    <>
      <div
        className="img-wrapper"
        style={{
          backgroundImage: `url(${thumbnail})`,
        }}
        onClick={() => {
          __nav(ctts_id);
        }}
      />
      <div className="magazinecard-content">
        <div
          className="left"
          onClick={() => {
            __nav(ctts_id);
          }}
        >
          <div className="title">{ctts_tit}</div>
          <div className="sub">{reader_usr_info}</div>
        </div>
        <Scrapsvg
          className={isScrap ? "scrap-on" : ""}
          onClick={() => {
            if (isScrap) {
              unscrap(ctts_id, "mgn");
            } else {
              scrap({ ctts_id });
            }
          }}
        />
      </div>
    </>
  );
};
