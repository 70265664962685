import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { clientApi } from "../../api/config";
import { koreanTime } from "../../lib/lib";
import "./css/index.css";

type LocationType = {
  pathname: string;
};

type PolicyDataType = {
  [key: string]: any;
};

export type PolicyCtgType = "AGR_INFO" | "ARG_MAT" | "AGR_AD";

const PolicyPage = () => {
  const { pathname } = useLocation() as LocationType;
  const PropsType = pathname.split("/")[2] as PolicyCtgType;
  const ContentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setisOpen] = useState<boolean>(false);
  const [layoutArr, setLayoutArr] = useState(policy_layout_arr);
  const [nowType, setNowType] = useState<PolicyCtgType>(
    PropsType ? PropsType : "AGR_AD"
  );
  const [policyData, setPolicyData] = useState<PolicyDataType>({
    AGR_INFO: {},
    ARG_MAT: {},
    AGR_AD: {},
  });

  const ChangeType = useCallback(
    (data: { title: string; link: PolicyCtgType }, idx: number) => {
      if (idx !== 0) {
        let arr = layoutArr.slice();
        let target = arr.splice(idx, 1)[0];
        arr.splice(0, 0, target);
        setLayoutArr(arr);
        setNowType(data.link);

        if (ContentRef.current) {
          ContentRef.current.innerHTML = policyData[data.link].ctts_ctts;
        }
      }

      setisOpen((prev) => !prev);
    },
    [layoutArr, policyData]
  );
  useEffect(() => {
    let arr = layoutArr.slice();
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (element.link === PropsType) {
        let target = arr.splice(i, 1)[0];
        arr.splice(0, 0, target);
      }
    }
    setLayoutArr(arr);
    return () => {};
  }, [PropsType]);

  useEffect(() => {
    clientApi("/web/cmnt/2?bbs_id=6").then(({ data: { sts_fg, succ_obj } }) => {
      if (sts_fg) {
        let obj: PolicyDataType = {
          AGR_INFO: {},
          ARG_MAT: {},
          AGR_AD: {},
        };
        succ_obj.lst.forEach((data: any) => {
          const { ctts_ctg_cd } = data;
          if (
            ctts_ctg_cd === "AGR_INFO" ||
            ctts_ctg_cd === "ARG_MAT" ||
            ctts_ctg_cd === "AGR_AD"
          ) {
            obj[ctts_ctg_cd] = data;
          }
        });
        if (ContentRef.current) {
          ContentRef.current.innerHTML = obj[nowType].ctts_ctts;
        }

        setPolicyData(obj);
      }
    });

    return () => {};
  }, [ContentRef]);
  return (
    <main className="policy-page">
      <div className="wrapper">
        <div
          className={`policy-drop-down-wrapper ${isOpen ? "policy-drop" : ""}`}
        >
          {layoutArr.map((item, idx) => {
            const { title, link } = item;
            return (
              <button
                key={idx}
                className="policy-drop-down"
                style={
                  idx !== 0
                    ? {
                        borderTop: "solid 1px #DBDBDB",
                      }
                    : undefined
                }
                onClick={() => {
                  ChangeType({ title, link }, idx);
                }}
              >
                {title}
                {idx === 0 ? (
                  <img src="/assets/common/grey-arrow.svg" alt="arrow" />
                ) : undefined}
              </button>
            );
          })}
        </div>
        <div className="policy-content-section">
          <div className="policy-title">{policyData[nowType].ctts_tit}</div>
          <div className="policy-timestamp">
            {policyData[nowType].ctts_desc
              ? koreanTime(policyData[nowType].ctts_desc)
              : ""}
          </div>
          <div className="policy-main-content" ref={ContentRef}></div>
        </div>
      </div>
    </main>
  );
};

export default PolicyPage;

const policy_layout_arr: {
  title: string;
  link: PolicyCtgType;
}[] = [
    {
    title: "서비스 이용약관",
    link: "AGR_AD",
  },
  {
    title: "개인정보 수집 및 이용동의",
    link: "AGR_INFO",
  },
  {
    title: "마케팅 활용 및 광고정보 수신 동의",
    link: "ARG_MAT",
  },
];
