import React, { useEffect, useState } from "react";
import "./css/index.css";
import InquiryFold from "../../components/InquiryFold/InquiryFold";
import Footer from "../../components/Footer/Footer";
function Inquiry() {
  const [navIndex, setNavIndex] = useState(0);
  const [isOpen, setIsOpen] = useState<number | undefined>(undefined);
  useEffect(() => {
    const ref = document.getElementById("inquiry-list");
    if (ref) {
      ref?.classList.remove("inside-ani");
      void ref.offsetWidth;
      ref?.classList.add("inside-ani");
    }
    return () => {};
  }, [navIndex]);
  return (
    <main className="inquiry">
      <div className="wrapper">
        <div className="title">Q&A</div>
        <div className="search">
          <input type="text" className="input" placeholder="검색하기"></input>
          <img src="/assets/common/search.svg" alt="" />
        </div>
      </div>
      <div className="wrapper-menu">
        <div className="menu">
          <ul className="menu-bar">
            {navArr.map((item, idx) => {
              return (
                <li
                  className={`${navIndex === idx ? "inquiry-nav-active" : ""}`}
                  key={idx}
                  onClick={() => {
                    setNavIndex(idx);
                    setIsOpen(undefined);
                  }}
                >
                  {item}
                </li>
              );
            })}
            <div
              className="menu-active-bar"
              style={{ left: `${navIndex * 63}px` }}
            />
          </ul>
        </div>
      </div>
      <div className="wrapper inquiry-list" id="inquiry-list">
        {layout[navIndex as keyof typeof layout].map((item, idx) => {
          return (
            <InquiryFold
              key={idx}
              data={item}
              idx={idx}
              setIsOpen={setIsOpen}
              isOpen={isOpen === idx}
            />
          );
        })}
      </div>
      <div className="wrapper-menu">
        <div className="section">
          <div className="icon">
            <img
              src="/assets/common/inquiry-icon.png"
              srcSet="assets/common/inquiry-icon@2x.png 2x, assets/common/inquiry-icon@3x.png"
              alt=""
            ></img>
          </div>
          <div className="title">
            더 궁금한 부분이 있다면 <br />
            고객센터로 연락주세요
          </div>
          <div className="box">
            <div className="sub">
              영업시간 <span>오전 10시 ~ 오후 6시</span>
              <br />
              (점심시간 오후 1시 ~ 오후 2시)
            </div>
          </div>
          <a className="mail" href="mailto:myteample@myteample.com">
            <div className="title">
              이메일 문의 <span>myteample@myteample.com</span>
            </div>
            <div className="img">
              <img src="/assets/common/mail.svg" alt="mail"></img>
            </div>
          </a>
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default Inquiry;

const layout = {
  0: [
    {
      title: "아이디만 찾고 싶었는데, 비밀번호 변경 링크가 함께 왔습니다.",
      sub: "마이팀플에서는 아이디 찾기를 시도했다면 비밀번호도 함께 변경하는 것을 권장하고 있습니다. 보안을 위해 아이디 확인 후 비밀번호도 변경해주세요.",
    },
    {
      title:
        "비밀번호를 잊어버려서 비밀번호 찾기 메일을 보냈는데 오지 않습니다.",
      sub: "이메일을 잘못 작성한 경우 발생할 수 있습니다. 가입 시 등록하신 메일을 정확히 작성하셨는지 다시 한번 확인해주세요. 여러 번의 시도에도 메일이 오지 않는다면 해당 메일로 회원가입 시도를 해 보시고, 중복 메일 안내가 뜨지 않는다면 이전 가입 시 잘못 입력한 것이므로 재가입을 권유 드립니다.",
    },
    {
      title: "가입시, 활동명은 실명으로 적어야 하나요?",
      sub: "활동명은 실명, 닉네임 모두 가능합니다. 다만 팀플러의 프로젝트 결과와 성과에 따라 채용 연결이 진행될 수 있으므로 가급적 활동명은 실명으로 권장드립니다.",
    },
    {
      title: "회원정보수정은 어디서 하나요?",
      sub: "회원정보는 프로필 클릭 후 내정보에서 수정 가능합니다. 아이디 및 이메일 수정은 불가능하며, 비민번호 변경은 로그인 화면 비밀번호 찾기 버튼을 통해서 가능합니다.",
    },
    {
      title: "서비스 탈퇴는 어떻게 하나요?",
      sub: "로그인 후 우측 상단 햄버거 버튼(≡)을 클릭하면 화면 하단의 버튼을 통해 회원탈퇴를 신청하실 수 있습니다.",
    },
    {
      title: "서비스 탈퇴 조건이 어떻게 되나요?",
      sub: "팀플리더의 경우, 현재 오픈 된 프로젝트의 리더를 승계하셔야 탈퇴처리됩니다. 팀플러의 경우, 탈퇴 시 신청중인 프로젝트가 있었다면 자동 신청취소가 되며, 참여중인 프로젝트의 경우에는 자동 강퇴 처리 됩니다. 프로젝트 참가비 환불은 환불 규정에 따릅니다. 탈퇴 시, 밋업은 자동 취소/환불신청이 되지 않으므로, 밋업 결제 취소는 마이팀플 CS팀에게 문의 부탁드립니다.",
    },
    {
      title: "탈퇴를 하면 개인정보는 어떻게 처리되나요?",
      sub: "탈퇴 즉시 사용자의 개인정보는 삭제 처리됩니다. 이는 사용자의 아이디, 이메일, 성함, 등록한 카드번호 및 전화번호를 포함합니다. 팀플룸 내 게시글 및 댓글 등의 내역은 삭제되지 않습니다.",
    },
    {
      title: "탈퇴 신청을 철회하고 싶습니다.",
      sub: "탈퇴 시에는 사용자의 개인정보가 즉시 삭제되어 복구가 어려우므로, 계정 복원이 불가능합니다. 탈퇴 전 신중한 결정 부탁드립니다.",
    },
    {
      title: "개인정보가 안전하게 보호되고 있나요?",
      sub: "마이팀플에서는 회원가입 시, 이메일 및 로그인 정보만 요구하고 있으며, 암호화하여 처리하고 있습니다.",
    },
  ],
  1: [
    {
      title: "팀프로젝트 참가 자격이 있나요?",
      sub: "팀프로젝트의 참가 자격은 각 프로젝트 마다 기준이 다릅니다. 마이팀플은 프로젝트 수행과 협업 경험을 제공하는 플랫폼으로, 지원하시고자 하는 역할에 대해 협업이 가능한 정도의 '기술역량'을 지닌 사람을 대상으로 하고 있습니다.",
    },
    {
      title: "팀프로젝트별로 참가비가 다른 이유가 있나요? ",
      sub: "프로젝트 참가비는 프로젝트를 오픈하는 리더가 결정하므로 프로젝트 진행 방식과 기간에 따라 참가비가 달라집니다. ",
    },
    {
      title: "팀프로젝트는 온라인으로 진행 되나요?",
      sub: "프로젝트마다 온/오프라인 진행 여부가 다릅니다. 각 프로젝트별 모집페이지를 확인 부탁드리며, 더 자세한 진행 방식은 프로젝트의 문의하기를 통해 팀플리더에게 문의해주세요. ",
    },
    {
      title: "프로젝트 기간은 어느 정도 될까요?",
      sub: "프로젝트는 최소 5주부터 최대 12주를 권장하고 있습니다. 진행 기간은 프로젝트마다 상이하며, 각 프로젝트를 오픈한 팀플리더에게 문의해주세요.",
    },
    {
      title: "프로젝트는 언제부터 시작되나요?",
      sub: "팀플러 선정 이후 바로 프로젝트별 협업 공간인 '팀플룸' 입장이 가능해집니다. 이후 팀플리더의 안내에 따라 프로젝트가 진행됩니다. ",
    },
    {
      title: "프로젝트를 완료하면 어떻게 되나요?",
      sub: "프로젝트를 성실히 수행하셨다면 완료 후 내가 참여한 프로젝트 완주 인증서가 제공됩니다. 완주인증서는 팀플리더가 소속된 기업 혹은 마이팀플 이름으로 발행됩니다. 인증서 다운로드는 프로젝트 모집 상세페이지 내 리워드 안내에서 확인할 수 있습니다. ",
    },
    {
      title: "완주 인증서는 프로젝트에 참여한 모두가 받을 수 있나요?",
      sub: "프로젝트 수행과정 중 리더의 평가를 통해 완주 인증서 발행 여부가 결정됩니다. 프로젝트를 완수했더라도 성실하게 임하지 않았다면 인증서 발행 대상에서 제외될 수 있습니다.",
    },
    {
      title: "프로젝트 진행 도중 하차하고 싶습니다.",
      sub: "프로젝트 도중 하차는 참여중인 프로젝트의 팀플리더와 협의를 통해 결정해야 하며, 결제된 참가비와 쿠폰은 환급이 불가합니다.  ",
    },
    {
      title: "팀플리더가 프로젝트 관리에 소흘합니다. ",
      sub: "각 팀플룸에는 마이팀플의 퍼실리레이터가 상주합니다. 일정기간 프로젝트가 진행되지 않는다고 판단되면, 마이팀플 퍼실리레이터는 팀플리더에게 경고조치하며, 이후에도 시정되지 않을 경우 팀플러와 협의하여 리더교체 혹은 프로젝트 종료가 결정됩니다. 팀플리더로 인한 프로젝트 종료 시, 참가비는 전액 환불되며 사용된 인비테이션은 재발행됩니다. ",
    },
    {
      title: "팀플러는 팀플룸 스케줄에 일정 등록을 할 수 없나요?",
      sub: "스케줄 등록과 관리는 프로젝트의 PO인 팀플리더의 권한입니다. 팀플러는 등록하실 수 없습니다.",
    },
    {
      title: "프로젝트에서 강퇴 되었습니다. ",
      sub: "팀플러가 마이팀플의 이용약관을 준수하지 않은 경우, 프로젝트의 강퇴 사유에 해당되는 행위를 할 경우, 서면(메일 혹은 알림톡)으로 경고를 받게 됩니다. 리더의 경고(3회)에도 시정되지 않는다면, 사전 고지를 통해 강퇴처리 될 수 있습니다. 강퇴된 팀플러는 프로젝트 선정 팀원 내역에서 제외 처리되며, 팀플룸 내에 남겼던 게시글 및 댓글은 보존되나, 타인에게 불쾌감을 줄 수 있는 글은 신고를 통해 블라인드 처리됩니다. 참가비 환불 또한 불가능합니다. ",
    },
  ],
  2: [
    {
      title: "팀플리더 신청 자격이 있나요?",
      sub: "팀플리더는 자신의 직무 분야에서 프로젝트 완수 경험을 보유한 3년차 이상 현업자만 참여할 수 있습니다. 자세한 문의는 myteample@myteample.com 으로 연락 부탁드립니다.",
    },
    {
      title:
        "팀플리더로 프로젝트를 오픈하고 싶습니다. 어떻게 신청하면 될까요? ",
      sub: "회원가입 후, ABOUT 페이지 맨하단에 신청버튼을 통해 신청 부탁드립니다. 마이팀플 운영팀에서 신청내용을 확인 후 영업일 기준 3일 이내 프로젝트 오픈 가능여부에 대해 연락 드립니다.  ",
    },
    {
      title: "기업입니다. 채용을 전제로 팀프로젝트를 오픈하고 싶습니다. ",
      sub: "B2B 프로젝트 오픈 문의는 마이팀플 영업팀(projectq@projectquestion.com)으로 부탁드립니다. ",
    },
    {
      title: "프로젝트에 함께할 팀플러는 어떻게 선정하나요?",
      sub: "모집 마감 후 오픈하신 프로젝트에 지원자의 포트폴리오와 지원서를 팀플리더의 메일로 전송해드립니다. 이후 선정할 팀플러 리스트가 전달되면, 마이팀플 관리자가 선정처리 한 후, 팀플리더에게 연락 드립니다. ",
    },
    {
      title: "프로젝트 진행을 위한 지원에는 무엇이 있나요?",
      sub: "프로젝트 모집 기간 중 팀플리더와 예비 팀플러와의 만남을 위한 밋업을 오픈해드립니다. 해당 밋업에서 서로 간의 궁금한 점 및 니즈 등을 파악하실 수 있습니다. 또한 프로젝트 진행 중 일정 및 자료 공유를 위한 온라인 협업 공간인 팀플룸이 생성됩니다. 팀플룸 내에서 공지사항 또는 일정, 파일 등록 등의 기능을 사용하실 수 있으며, 만약 일시적인 만남을 위한 오프라인 공간이 필요하실 경우 팀플리더께서 마이팀플 측에 요청을 해주시면 오프라인(신촌 에스큐브) 공간을 준비해드릴 수 있습니다. ",
    },
    {
      title:
        "프로젝트 진행 중 약관에 어긋나는 행동을 하는 팀플러에 대해서는 어떻게 처리하나요?",
      sub: "팀플러가 리더의 경고(3회)에도 시정되지 않는다면, 해당 팀플러는 강퇴처리될 수 있습니다. 강퇴자는 프로젝트 선정 팀원 내역에서 제외 처리되며, 팀플룸 내에 남겼던 게시글 및 댓글은 보존되나, 타인에게 불쾌감을 줄 수 있는 글은 신고를 통해 블라인드 처리됩니다. 모든 팀플룸에는 마이팀플의 퍼실리레이터가 함께 입장하여 관리가 가능하나, 타 플랫폼을 이용하여 프로젝트를 진행 중에 이런 경우가 발생한다면, myteample@myteample.com 으로 연락 부탁드립니다. ",
    },
  ],
  3: [
    {
      title: "카드가 등록되지 않습니다.",
      sub: "마이팀플 타 계정에 등록된 카드일 경우, 해외에서 발급한 카드일 경우, 유효기간이 지난 경우, 도난 카드로 등록된 경우에는 카드 등록이 되지 않습니다. 그 외 등록이 되지 않은 경우 마이팀플 CS팀에 문의해주세요.  ",
    },
    {
      title: "카드결제 후 100원이 결제되었다 환불되었습니다.",
      sub: "마이팀플의 계약 PG사인 ‘다날쏘시오’에서 해당 카드의 유효여부를 확인하기 위한 테스트결제로 안심하셔도 됩니다. ",
    },
    {
      title: "법인카드, 체크카드도 등록가능한가요?",
      sub: "네. 가능합니다. 실제 사용자 명의의 카드를 등록해주시길 권장드립니다. ",
    },
    {
      title: "카드결제 밖에 안되나요? 계좌이체가 불가능한지 문의합니다.",
      sub: "마이팀플은 카드결제만 가능하며 계좌이체를 통한 현금거래는 진행하지 않습니다. ",
    },
    {
      title: "팀프로젝트 참가비는 언제 결제되나요?",
      sub: "참가비는 신청 종료 이후 프로젝트 '선정자'에 한해 신청 종료 후 결제가 진행됩니다. 선정된 참가자에게는 결제 전에 메일 혹은 알람톡으로 고지합니다. ",
    },
    {
      title: "결제카드를 변경하고 싶습니다.",
      sub: "신청한 프로젝트의 결제가 진행되기 전에는 결제 카드 변경이 가능합니다. [마이페이지]-[결제내역] 에서 각 프로젝트의 상세 내역을 보면 결제 카드 변경 및 저장을 할 수 있습니다. 프로젝트 선정 전에는 결제 예정 카드 변경이 가능하나, 결제 이후에는 결제카드 변경이 불가능합니다. 밋업은 신청 즉시 결제가 이루어지므로 카드 변경이 불가능하니 유의해주시기 바랍니다.",
    },
    {
      title: "환불하고 싶습니다.",
      sub: "환불신청은 마이페이지 결제 내역에서 가능합니다. 환불은 규정에 따라 진행되며, 각 프로젝트 및 밋업의 환불 규정을 확인 부탁드립니다. ",
    },
    {
      title: "프로젝트 취소 후 사용한 쿠폰/인비테이션이 환급되지 않았습니다. ",
      sub: "한 번 사용된 할인/무료 쿠폰 및 인비테이션은 재발행되지 않습니다. 쿠폰 사용 시, 유의 부탁드립니다.",
    },
  ],
  4: [
    {
      title: "프로젝트 작업물의 권한이 궁금합니다. ",
      sub: "마이팀플은 최종 산출물의 소유권에 관여하지 않습니다. 프로젝트를 오픈한 팀플리더가 정한 가이드를 확인 부탁드리며, 가이드가 없는 경우, 프로젝트에 함께 참여한 팀플러와 팀플리더가 함께 협의하여 결정됩니다. 프로젝트 진행 도중 하차한 경우에도 작업물은 프로젝트에 귀속됩니다. ",
    },
    {
      title: "우수팀플러 리워드 중 '인비테이션'이 무엇인가요?",
      sub: "인비테이션은 함께 참여한 '팀플리더'가 우수팀플러에게 제공하는 프로젝트 초대권입니다. 인비테이션 결제시, 해당 팀플러는 1순위로 우선 선정되며, 프로젝트에 무료로 참여할 수 있습니다. 인비테이션 사용은 해당 초대권을 발행한 리더의 프로젝트에서만 사용 가능합니다.",
    },
    {
      title: "프로젝트 활동에 꼭 팀플룸을 사용해야 하나요?",
      sub: "팀플룸 사용 여부는 팀플리더의 결정에 따르며, 다른 협업툴을 사용할 수 있습니다. 다만 팀플룸에서 프로젝트가 진행될 경우에만, 마이팀플 퍼실리레이터 지원이 가능하며, 원활한 프로젝트 진행에 도움을 드릴 수 있습니다.",
    },
    {
      title: "프로젝트 활동 중 팀원 변경이 있을 수 있나요? ",
      sub: "프로젝트 진행 중 중도 하차하는 팀플러가 발생한 경우, 프로젝트 참여 구성원의 합의를 통해 팀원 변경이 생길 수 있습니다.",
    },
  ],
};

const navArr = ["회원", "팀플러", "팀플리더", "결제", "기타"];
