import React, { useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import FirstInfoBtn from "../../../components/FirstInfoBtn/FirstInfoBtn";
import FirstInfoTop from "../../../components/FirstInfoTop/FirstInfoTop";
import Keywords from "../../../components/Kewords/Keywords";
import { stateface } from "./interface";

// interface propsinter {
//   state: string;
// }

function Step3() {
  const {
    state,
    dispatch,
    usr_id,
    FixUserInfo,
  }: {
    state: stateface;
    dispatch: (p: object) => void;
    usr_id: string;
    FixUserInfo: (e: any) => void;
  } = useOutletContext();
  const __insert = useCallback(() => {
    const { login_nm, info_desc, link, tag_lst, img } = state;
    FixUserInfo({
      usr_id,
      img,
      login_nm,
      info_desc,
      link,
      tag_lst,
    });
  }, [state, FixUserInfo, usr_id]);
  return (
    <div className="step3">
      <FirstInfoTop
        type="intro"
        index={2}
        step="Step 3"
        title="나의 키워드를 등록해주세요"
        content={
          <div className="content">
            나를 표현하는 키워드를 등록해주세요 <br /> 사이드 프로젝트 선정 시
            도움이 됩니다
          </div>
        }
      />
      <div className="bottom">
        <Keywords data={state.tag_lst} patch={dispatch} />
        <FirstInfoBtn title="정보 입력 완료!" __nav={__insert} type="insert" />
      </div>
    </div>
  );
}

export default Step3;
