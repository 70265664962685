import React, { useCallback, useEffect, useReducer } from 'react'
import { NavigateFunction, useOutletContext } from 'react-router-dom';
import { ProjectCardInter, RoomWriteInter } from '../interface/interface';
import { clientApi } from '../api/config';
import RoomWrite from '../pages/TeamRoom/sub/RoomWrite';
import { decypto } from '../lib/lib';
import { Common_get_api } from '../api/get_api';

var info = {
  title: "",
  content: "",
  link: "",
  file: {
    name: "",
    url: "",
  }
};

const RoomFixContainer = () => {
 const {
    navigate,
    pathname,
    nowPrj,
  }: {
    navigate: NavigateFunction;
    pathname: string;
    nowPrj: ProjectCardInter;
  } = useOutletContext();
    const category = pathname.split("/")[2];
    const [state, dispatch] = useReducer(reducer, info);
    const WriteRoom = useCallback(() => {
    const id = decypto(pathname.split("/fix/")[1]);
    if (state.title && state.content) {
        clientApi
          .post(`/web/cmnt/2/ctts`, {
            bbs_id: "1",
            ctts_id: id,
            prjt_id: nowPrj.prjt_id,
            ctts_tit: state.title,
            ctts_ctts: state.content,
            ctts_ctg_cd: category,
            file_link: state.file.url,
            link: state.link,
          })
          .then(({ data: { sts_fg } }) => {
            if (sts_fg) {
              navigate(-1);
            }
          });
    }
  }, [state, category, nowPrj, navigate,pathname]);

useEffect(() => {
const id = decypto(pathname.split("/fix/")[1]);
 Common_get_api(`/web/cmnt/2/ctts/${id}`).then(({state,data})=>{
if (state) {
  dispatch({
    type:"all",
    payload:"",
    url:"",
    all:{
      title:data.ctts_tit,
      content:data.ctts_ctts,
      link:data.link ? data.link : "",
      file:{
        name:"파일",
        url:data.file_link ? data.file_link : ""
      } 
    }
  })
 }})

  return () => {
    
  }
}, [pathname])

  return <RoomWrite state={state} dispatch={dispatch
  } category={category} WriteRoom={WriteRoom}/>
}

export default RoomFixContainer
function reducer(
  state: RoomWriteInter,
  {
    payload,
    type,
    url,
    all
  }: { payload: string; type: string; url: string; all?:RoomWriteInter  }
) {
  switch (type) {
    case "title":
      return { ...state, title: payload };
    case "content":
      return { ...state, content: payload };
    case "link":
      return { ...state, link: payload };
    case "file":
      return {
        ...state,
        file: {
          name: payload,
          url,
        },
      };
      case "all":
        if (all) {
     return { ...state,
      ...all};          
        }else {
          return state
        }

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
