import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clientApi } from "../../api/config";
import {
  payment_cancel,
  payment_crd_change,
  payment_refund,
} from "../../api/payment_api";
import CardSection from "../../components/CardSection/CardSection";
import PaymentResultCard from "../../components/PaymentResultCard/PaymentResultCard";
import { CardInter, PaymentDetailInter } from "../../interface/interface";
import {
  DetailReturnType,
  formatDate,
  makeComma,
  PaymnetDetailForm,
} from "../../lib/lib";
import { RootState } from "../../reducers";
import { LoadingChange, ToastChange } from "../../reducers/config";
import "./css/index.css";

type LocationType = {
  state: {
    id: string;
    type: "cancel" | "refund" | undefined;
  };
};

const MyPaymentDetail = () => {
  const {
    state: { id, type },
  } = useLocation() as LocationType;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usr_id = useSelector<RootState, string>((state) => state.auth.usr_id);
  const [paymentData, setPaymentData] = useState<
    PaymentDetailInter | undefined
  >(undefined);

  const [selsectCard, setSelsectCard] = useState<CardInter>();
  const [PaymentState, setPaymentState] =
    useState<DetailReturnType>("wait-state");
  const [salePrice, setsalePrice] = useState<number>(0);

  const __changeCard = useCallback((e: CardInter) => {
    setSelsectCard(e);
  }, []);
  const PaymentFix = useCallback(() => {
    if (paymentData) {
      dispatch(LoadingChange(true));
      const { prjt_id, rcrt_id, apply_id, payList } = paymentData;
      if (type === "cancel") {
        payment_cancel(prjt_id, rcrt_id, apply_id)
          .then(({ state, data }) => {
            if (state) {
              navigate("/my/finish/payment/fix", {
                state: type,
              });
              dispatch(LoadingChange(false));
            } else {
              dispatch(
                ToastChange({
                  active: true,
                  content: "취소에 에러가 발생하였습니다.",
                })
              );
              dispatch(LoadingChange(false));
            }
          })
          .catch(() => {
            dispatch(
              ToastChange({
                active: true,
                content: "네트워크 상태가 원할하지 않습니다",
              })
            );
            dispatch(LoadingChange(false));
          });
      } else if (type === "refund") {
        payment_refund(prjt_id, rcrt_id, apply_id)
          .then(({ state, data }) => {
            if (state) {
              navigate("/my/finish/payment/fix", {
                state: type,
              });
              dispatch(LoadingChange(false));
            } else {
              dispatch(
                ToastChange({
                  active: true,
                  content: "환불에 에러가 발생하였습니다.",
                })
              );
              dispatch(LoadingChange(false));
            }
          })
          .catch(() => {
            dispatch(
              ToastChange({
                active: true,
                content: "네트워크 상태가 원할하지 않습니다",
              })
            );
            dispatch(LoadingChange(false));
          });
      } else if (
        paymentData.prjt_sts_cd === "prjt_sts_rcrt_ing" &&
        paymentData.payList[0].rcrt_paysts_cd === "pay_wait" &&
        selsectCard
      ) {
        payment_crd_change(
          prjt_id,
          rcrt_id,
          apply_id,
          selsectCard?.crd_id,
          payList[0].cpon_id
        )
          .then(({ state, data }) => {
            if (state) {
              dispatch(
                ToastChange({
                  active: true,
                  content: "결제 카드 변경을 완료하였습니다",
                })
              );
              navigate(-1);
              dispatch(LoadingChange(false));
            } else {
              dispatch(
                ToastChange({
                  active: true,
                  content: "에러가 발생하였습니다.",
                })
              );
              dispatch(LoadingChange(false));
            }
          })
          .catch(() => {
            dispatch(
              ToastChange({
                active: true,
                content: "네트워크 상태가 원할하지 않습니다",
              })
            );
            dispatch(LoadingChange(false));
          });
      }
    }
  }, [type, navigate, paymentData, selsectCard, dispatch]);

  useEffect(() => {
    if (id) {
      clientApi.get(`${id}`).then(({ data: { sts_fg, succ_obj } }) => {
        if (sts_fg) {
          const { crd_id, crd_nm, crd_code, crd_num } = succ_obj;
          setPaymentData(succ_obj);
          setPaymentState(
            PaymnetDetailForm(
              succ_obj.prjt_type_cd,
              succ_obj.prjt_sts_cd,
              succ_obj.apprv_sts_cd,
              succ_obj.apply_sts_cd,
              succ_obj.payList[0].rcrt_paysts_cd
            )
          );
          setSelsectCard({
            crd_id,
            crd_nm,
            crd_code,
            crd_num,
          });
          setsalePrice(
            succ_obj.cpon_discnt_rate
              ? (succ_obj.attd_amt * succ_obj.cpon_discnt_rate) / 100
              : 0
          );
        }
      });
    } else {
      dispatch(ToastChange({ active: true, content: "잘못된 접근입니다." }));
      navigate(-1);
    }

    return () => {};
  }, [id, dispatch, navigate]);

  return paymentData ? (
    <main className="my-payment-detail">
      <div className="wrapper">
        <div className="title">
          {type ? (type === "cancel" ? "신청 취소" : "환불 요청") : "결제 상세"}
        </div>
        <div className="card-section">
          <div className="sub-title">결제 내용</div>
          <div className="card">
            <div
              className="img-wrapper"
              style={{
                backgroundImage: `url(${paymentData.prjt_thumbnail})`,
              }}
            >
              <div className="tag"></div>
            </div>
            <div className="card-content">
              <div className="title">{paymentData.prjt_nm}</div>
              <div className="line" />
              <div className="sub">
                [{paymentData.payList[0].rcrt_nm}]로 신청
              </div>
              <div className="price">{makeComma(paymentData.attd_amt)}원</div>
            </div>
          </div>
        </div>
        <div className="payment-info-section">
          <div className="title">결제정보</div>
          <div className="price-wrapper">
            <div className="tag-wrapper">
              <div className="tag">금액</div>
              <div className="price"> {makeComma(paymentData.attd_amt)}원</div>
            </div>
            <div className="tag-wrapper">
              <div className="tag">
                할인 <span>{paymentData.cpon_nm}</span>
              </div>
              <div className="price">
                {paymentData.cpon_discnt_rate ? makeComma(salePrice) : 0}원
              </div>
            </div>
          </div>
          <div className="line" />
          <div className="tag-wrapper">
            <div className="tag">총 금액</div>
            <div className="price">
              {makeComma(paymentData.attd_amt - salePrice)}원
            </div>
          </div>
          {type === "refund" ||
          PaymentState === "refund-state" ||
          PaymentState === "cancel-state" ? (
            <PaymentResultCard
              type={type}
              paymentData={paymentData}
              paymentState={PaymentState}
              paymentFix={PaymentFix}
              salePrice={salePrice}
            />
          ) : type === "cancel" ? (
            <></>
          ) : paymentData.prjt_sts_cd === "prjt_sts_rcrt_ing" &&
            paymentData.apply_sts_cd !== "apply_sts_cancel" &&
            paymentData.payList[0].rcrt_paysts_cd === "pay_wait" ? (
            <>
              <div className="day-box">
                <div className="left">
                  <div className="text">선정일</div>
                  <div className="day">
                    {paymentData.prjt_type_cd === "prjt"
                      ? formatDate(paymentData.prjt_apply_st_at, "-")
                      : "-"}
                  </div>
                </div>
                <div className="right">
                  <div className="text2">결제예정일</div>
                  <div className={`d-day`}>
                    {" "}
                    {formatDate(
                      paymentData.paym_at
                        ? paymentData.paym_at
                        : paymentData.prjt_apply_st_at,
                      "-"
                    )}
                  </div>
                </div>
              </div>
              <div className="card-title">카드등록정보</div>
              <CardSection
                usr_id={usr_id}
                type="payment"
                initCard={__changeCard}
                selectCard={selsectCard}
                period={paymentData.prjt_apply_st_at}
                category={paymentData.prjt_type_cd}
              />
            </>
          ) : (
            <>
              <div className="day-box">
                <div className="left">
                  <div className="text">선정일</div>
                  <div className="day">
                    {paymentData.prjt_type_cd === "prjt"
                      ? formatDate(paymentData.prjt_apply_st_at, "-")
                      : "-"}
                  </div>
                </div>
                <div className="right">
                  <div className="text2">
                    {PaymentState === "select-wait-state"
                      ? "결제예정일"
                      : "결제일"}
                  </div>
                  <div className={`d-day`}>
                    {" "}
                    {formatDate(
                      paymentData.paym_at
                        ? paymentData.paym_at
                        : paymentData.prjt_apply_st_at,
                      "-"
                    )}
                  </div>
                </div>
              </div>
              <div className="card-title">카드등록정보</div>
              <div className="card-box">
                <div className="type">{paymentData.crd_nm}</div>
                <div className="number">{paymentData.crd_num}</div>
              </div>
            </>
          )}
        </div>
        {(type || PaymentState === "wait-state") && type !== "refund" ? (
          <button className="payment-active-btn" onClick={PaymentFix}>
            {type === "cancel"
              ? "사항 확인 후 취소 신청"
              : type === "refund"
              ? "환불 금액 확인 완료 후 신청"
              : "결제 카드 수정완료"}
          </button>
        ) : undefined}
      </div>
    </main>
  ) : (
    <></>
  );
};

export default MyPaymentDetail;
