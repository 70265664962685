import React, { useCallback, useEffect, useState } from "react";
import { NavigateFunction, useOutletContext } from "react-router-dom";
import { AppDispatch } from "../../..";
import { payment_submit } from "../../../api/payment_api";
import CardSection from "../../../components/CardSection/CardSection";
import LoginInput from "../../../components/LoginInput/LoginInput";
import RefundNotice from "../../../components/RefundNotice/RefundNotice";
import {
  CardInter,
  CouponInter,
  detailtype,
  MeetupBasicDataType,
  ProjectBasicDataType,
} from "../../../interface/interface";
import { formatDate, formatNumber, telform, telreg } from "../../../lib/lib";
import { LoadingChange, ToastChange } from "../../../reducers/config";

const PamyentReceipt = () => {
  const {
    data,
    navigate,
    field,
    coupon,
    pid,
    CouponData,
    usr_id,
    content,
    name: usr_name,
    tel: usr_tel,
    isCs: Cs,
    isRefund,
    dispatch,
  }: {
    pid: string;
    isRefund: boolean;
    data: ProjectBasicDataType | MeetupBasicDataType;
    navigate: NavigateFunction;
    field: detailtype;
    coupon: CouponInter | undefined;
    CouponData: CouponInter[];
    usr_id: string;
    content: {
      apply_self_brif_1: string;
      apply_self_brif_2: string;
    };
    name: string;
    tel: string;
    isCs: boolean;
    policy: boolean[];
    dispatch: AppDispatch;
  } = useOutletContext();
  const { attd_amt, prjt_nm, prjt_thumbnail, rcrt_apply_en_at, prjt_type_cd } =
    data;
  const [selsectCard, setSelsectCard] = useState<CardInter | undefined>(
    undefined
  );

  const [salePrice, setSalePrice] = useState<number>(0);
  const [name, setName] = useState<string>(usr_name ? usr_name : "");
  const [tel, setTel] = useState<string>(usr_tel ? usr_tel : "");
  const [isCs, setIsCs] = useState<boolean>(Cs ? Cs : false);
  const [Refund, setRefund] = useState<boolean>(isRefund ? isRefund : false);
  const [ableCoupon, setAbleCoupon] = useState<any[]>([]);
  const __changeCard = useCallback((e: CardInter) => {
    setSelsectCard(e);
  }, []);
  const __navCoupon = useCallback(() => {
    navigate("coupon", {
      state: {
        data,
        field,
        pid,
        name,
        tel,
        isCs,
        isRefund: Refund,
      },
    });
  }, [navigate, name, tel, isCs, data, field, pid, Refund]);
  const TelChange = useCallback((e: string) => {
    if (e.length < 13) {
      setTel(telform(e));
    }
  }, []);

  const __paymentRequest = useCallback(() => {
    if (telreg.test(tel) && name && selsectCard && isCs && Refund) {
      dispatch(LoadingChange(true));
      payment_submit(
        data.prjt_id,
        data.prjt_type_cd === "mtup" ? data.rcrt_lst[0].rcrt_id : field.rcrt_id,
        Object.assign(
          coupon
            ? {
                crd_id: selsectCard?.crd_id,
                cpon_id: coupon.cpon_id,
                rcrt_usr_name: name,
                rcrt_usr_phone: tel,
              }
            : {
                crd_id: selsectCard?.crd_id,
                rcrt_usr_name: name,
                rcrt_usr_phone: tel,
              },
          content
            ? content
            : {
                apply_self_brif_1: "",
                apply_self_brif_2: "",
              }
        )
      ).then(({ state, msg }) => {
        if (state) {
          navigate("finish", {
            state: {
              data,
              field,
              pid,
            },
          });
        } else {
          dispatch(ToastChange({ active: true, content: msg }));
        }
        dispatch(LoadingChange(false));
      });
    }
  }, [
    navigate,
    data,
    field,
    pid,
    selsectCard,
    coupon,
    content,
    name,
    tel,
    isCs,
    dispatch,
    Refund,
  ]);
  useEffect(() => {
    setAbleCoupon(
      CouponData.filter(({ cpon_type_cd, cpon_ctg_cd, prjt_id }) =>
        cpon_type_cd === "normal"
          ? cpon_ctg_cd === data.prjt_type_cd
            ? true
            : false
          : String(prjt_id) === String(pid)
      )
    );
    return () => {};
  }, [CouponData, pid, data]);

  useEffect(() => {
    if (coupon) {
      if (coupon.cpon_type_cd !== "normal") {
        setSalePrice(attd_amt);
      } else {
        setSalePrice((attd_amt * Number(coupon.cpon_discnt_rate)) / 100);
      }
    } else {
      setSalePrice(0);
    }
    return () => {};
  }, [coupon, attd_amt]);

  return (
    <div className='payment-receipt'>
      <div className='title'>신청하기</div>
      <div className='project-content'>
        <div className='tag'>결제 내용</div>
        <div className='content-card'>
          <div
            className='img-wrapper'
            style={{ backgroundImage: `url(${prjt_thumbnail})` }}
          />
          <div className='right'>
            <div className='title'>{prjt_nm}</div>
            <div className='line' />

            <div className='grade'>
              {prjt_type_cd === "prjt"
                ? `[${field.rcrt_nm}]로 신청`
                : `[${prjt_nm}] 신청`}
            </div>

            <div className='price'>{formatNumber(attd_amt)}원</div>
          </div>
        </div>
      </div>
      <div className='payment-input-section'>
        <div className='tag'>기본정보</div>
        <div className='payment-input-wrapper'>
          <LoginInput
            title='이름'
            type='text'
            placeholder='실명을 입력해주세요'
            setValue={setName}
            value={name}
            errorType={undefined}
            Check={undefined}
          />
          <LoginInput
            title='연락처'
            subTitle='(01000000000 형태)'
            type='text'
            placeholder='연락처를 입력해주세요'
            setValue={TelChange}
            value={tel}
            errorType={undefined}
            Check={undefined}
          />
        </div>
        <div className='payment-cs-btn-wrapper'>
          <button
            className={`circle ${isCs ? "agree-cs" : ""}`}
            onClick={() => {
              setIsCs(!isCs);
            }}
          >
            <img src='/assets/login/check.svg' alt='' />
          </button>
          <div className='title'>
            <span>(필수) </span>입력한 개인정보는 결제 CS시에 사용되며 이에{" "}
            <br /> 사용되는 것에 동의합니다.
          </div>
        </div>
      </div>

      <div className='sale-wrapper'>
        <div className='title-wrapper'>
          <div className='tag'>할인 적용</div>
          <div className='sale-price'>{formatNumber(salePrice)}원</div>
        </div>
        <button
          className={`coupon-btn ${
            coupon
              ? "coupon-insert"
              : ableCoupon.length < 1
              ? "coupon-emty"
              : ""
          }`}
          onClick={__navCoupon}
        >
          <div className='title'>
            {coupon
              ? coupon.cpon_nm
              : ableCoupon.length > 0
              ? `사용가능한 쿠폰 및 인비테이션 ${ableCoupon.length}개`
              : "사용가능한 쿠폰 및 인비테이션이 없습니다"}
          </div>
          <img src='/assets/common/grey-arrow.svg' alt='' />
        </button>
      </div>
      <div className='payment-line' />
      <div className='info-wrapper'>
        <div className='tag'>결제정보</div>
        <div className='info-content-wrapper'>
          <div className='info-line'>
            <div className='info-tag'>금액</div>
            <div className='info-content'>{formatNumber(attd_amt)}원</div>
          </div>
          <div className='info-line'>
            <div className='info-tag'>할인</div>
            <div className='info-content'>{formatNumber(salePrice)}원</div>
          </div>
        </div>
        <div className='payment-line' />
        <div className='payment-price'>
          <div className='info-tag'>총 금액</div>
          <div className='price'>{formatNumber(attd_amt - salePrice)}원</div>
        </div>

        <div className='payment-notice'>
          {data.prjt_type_cd === "prjt"
            ? "바로 결제가 아닌 선정 후 결제입니다"
            : ""}
        </div>
        {data.prjt_type_cd === "prjt" ? (
          <div className='payment-period-card'>
            <div className='period-line'>
              <div className='info-tag'>선정일</div>
              <div className='info-tag'>
                {formatDate(rcrt_apply_en_at, "-")}
              </div>
            </div>
            <div className='period-line'>
              <div className='info-tag'>결제일</div>
              <span>{formatDate(rcrt_apply_en_at, "-")}</span>
            </div>
          </div>
        ) : undefined}
      </div>
      <div className='payment-line' />
      <div className='payment-card-wrapper'>
        <div className='tag'>
          카드등록정보
          <span>100% 할인 쿠폰을 사용할때도 카드를 선택해주세요</span>
        </div>
        <div className='sub'>
          {data.prjt_type_cd === "prjt" ? (
            <>
              팀 선정시 등록된 카드로 결제가 시도됩니다. <br /> 카드가 등록되지
              않은 상태에서는 <br /> 프로젝트 참여가 제한되니 사전에 등록을
              부탁드립니다.{" "}
            </>
          ) : undefined}
        </div>

        <CardSection
          usr_id={usr_id}
          type='payment'
          initCard={__changeCard}
          selectCard={selsectCard}
          period={data.rcrt_apply_en_at}
          category={data.prjt_type_cd}
        />
      </div>
      <button
        onClick={() => {
          setRefund(!Refund);
        }}
        className={`all-policy ${Refund ? "policy-active" : ""}`}
      >
        <div className='circle'>
          <img src='/assets/login/check.svg' alt='' />
        </div>
        <div className='title'>결제 전에 환불규정을 확인하셨습니까? </div>
      </button>
      <RefundNotice type={data.prjt_type_cd} />
      <div className='payment-line' />

      <button
        className={`payment-finish-btn ${
          isCs && name && telreg.test(tel) && selsectCard && Refund
            ? "payment-on"
            : ""
        }`}
        onClick={__paymentRequest}
      >
        신청하기
      </button>
    </div>
  );
};

export default PamyentReceipt;
