import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authinterface } from "../../interface/interface";
import "./css/index.css";
import Certification from "./sub/Certification";
import MyInfo from "./sub/MyInfo";
import OtherInfo from "./sub/OtherInfo";
import ProfileProject from "./sub/ProfileProject";
import Recommend from "./sub/Recommend";
import Support from "./sub/Support";
type Props = {
  __updateNickname: undefined | ((nickname: string, content: string) => void);
  __fixportfolio: ((e: string) => void) | undefined;
  __fixKeywords: ((e: { tag_nm: string }[]) => void) | undefined;
  __updateImg: ((e: File) => void) | undefined;
  intro: string;
  fixtype: string | undefined;
  setFixtype: Dispatch<SetStateAction<string | undefined>> | undefined;
  authinfo: authinterface;
  tabLayout: string[];
  isMe: boolean;
  my_usr_id: string;
};
function Profile({
  __updateNickname,
  __fixKeywords,
  __fixportfolio,
  fixtype,
  intro,
  setFixtype,
  __updateImg,
  authinfo,
  tabLayout,
  isMe,
  my_usr_id,
}: Props) {
  const navigate = useNavigate();
  const {
    grade: { cnt_prjt, cnt_end, cnt_inbi },
    info: { img, login_nm, link, tag_lst },
    usr_typ,
    email,
    usr_id,
  } = authinfo;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const TabChange = useCallback((e: number) => {
    setTabIndex(e);
  }, []);

  return (
    <div className="profile-comp">
      {isMe ? (
        <div className="fix-wrapper">
          <div className="wrapper">
            <div className="title">내 정보</div>
          </div>
        </div>
      ) : undefined}
      <div
        className={`profile-wrapper ${
          usr_typ === "READ" ? "leader-profile" : ""
        }`}
      >
        <div className="wrapper">
          <div className="grade-name">
            {usr_typ === "READ" ? "팀플리더" : "팀플러 회원"}
          </div>
          <label>
            <input
              type="file"
              style={{ opacity: 0 }}
              multiple={false}
              accept="image/x-png,image/gif,image/jpeg"
              onChange={(e) => {
                const files = e.target.files;
                if (__updateImg && files && files?.length > 0) {
                  __updateImg(files[0]);
                }
              }}
              disabled={!isMe}
            />
            {img ? (
              <img src={img} alt="profile" className="main-img" />
            ) : (
              <img
                className="main-img"
                src="/assets/profile/profile.png"
                srcSet="/assets/profile/profile@2x.png 2x ,/assets/profile/profile@3x.png 3x"
                alt=""
              />
            )}
            {isMe ? (
              <img src="/assets/profile/camera.svg" alt="" className="camera" />
            ) : undefined}
          </label>
          <div className="nickname">{login_nm}</div>
          <div className="email">{email}</div>
          {usr_typ !== "READ" ? (
            <div className="grade-wrapper">
              <div className="box">
                <div className="title">팀플 선정</div>
                <div className="number">{cnt_prjt}회</div>
              </div>
              <div className="box">
                <div className="title">팀플 완주</div>
                <div className="number">{cnt_end}회</div>
              </div>
              <div className="box">
                <div className="title">우수팀플러</div>
                <div className="number">{cnt_inbi}회</div>
              </div>
            </div>
          ) : undefined}
          {isMe ? (
            <button
              className="my-teampl-btn"
              onClick={() => {
                navigate("/my/teampl");
              }}
            >
              MY 팀플
            </button>
          ) : undefined}
        </div>
      </div>
      <div className="profile-tab-section">
        <div className="profile-tab-wrapper">
          {tabLayout.map((item, idx) => {
            return (
              <button
                key={idx}
                className={`profile-tab ${
                  tabIndex === idx ? "tab-active" : ""
                }`}
                onClick={() => {
                  TabChange(idx);
                }}
              >
                {item}
              </button>
            );
          })}
          <div
            className="profile-tab-bar"
            style={{
              left: `${74 * tabIndex}px`,
            }}
          />
        </div>
      </div>
      {tabIndex === 0 ? (
        isMe &&
        __updateNickname &&
        __fixportfolio &&
        __fixKeywords &&
        setFixtype ? (
          <MyInfo
            __updateNickname={__updateNickname}
            __fixportfolio={__fixportfolio}
            __fixKeywords={__fixKeywords}
            fixtype={fixtype}
            nickname={login_nm}
            intro={intro}
            setFixtype={setFixtype}
            portfolio={link}
            keyword={tag_lst}
            email={email}
            usr_id={usr_id}
          />
        ) : (
          <OtherInfo keyword={tag_lst} intro={intro} portfolio={link} />
        )
      ) : tabIndex === 1 ? (
        <ProfileProject
          navigate={navigate}
          usr_id={usr_id}
          usr_typ={authinfo.usr_typ}
          isMe={isMe}
        />
      ) : tabIndex === 2 ? (
        <Recommend isMe={isMe} usr_id={usr_id} my_usr_id={my_usr_id} />
      ) : tabIndex === 3 ? (
        <Support navigate={navigate} />
      ) : (
        <Certification usr_id={my_usr_id} />
      )}
    </div>
  );
}

export default Profile;
