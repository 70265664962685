import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clientApi } from "../api/config";
import { get_scrap_api } from "../api/scrap_api";
import { AllAuth } from "../reducers/auth";
import { ToastChange } from "../reducers/config";
import { ScrapInit } from "../reducers/data";

const UseUserInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const GetUserInfo = useCallback(() => {
    clientApi("/auth/usr/usr/myinfo").then(({ data }) => {
      if (data.sts_fg) {
        const { info, tag } = data.succ_obj;
        dispatch(
          AllAuth({
            usr_id: info.usr_id,
            email: info.usr_email ? info.usr_email : "",
            usr_typ: info.usr_typ,
            info: {
              login_nm: info.login_nm ? info.login_nm : "",
              info_desc: info.info_desc ? info.info_desc : "",
              img: info.usr_thumbnail ? info.usr_thumbnail : "",
              link: info.link ? info.link : "",
              tag_lst: tag ? tag : [],
            },
            grade: {
              cnt_prjt: info.cnt_prjt,
              cnt_end: info.cnt_end,
              cnt_inbi: info.cnt_inbi,
            },
          })
        );
        if (!info.login_nm) {
          dispatch(
            ToastChange({ active: true, content: "추가 정보를 입력해주세요" })
          );
          navigate("/firstinfo/step1");
        }
        get_scrap_api().then(({ state, data }) => {
          if (state) {
            dispatch(
              ScrapInit({
                mgn: data.mgn,
                prjt: { lst: data.prjt },
                mtup: { lst: data.mtup },
              })
            );
          }
        });
      }
    });
  }, [navigate, dispatch]);

  return [GetUserInfo];
};

export default UseUserInfo;
