import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch } from "..";
import {
  get_comment_api,
  post_comment_api,
  remove_comment_api,
} from "../api/comment_api";
import { PgInter } from "../interface/interface";
import { RootState } from "../reducers";
import { ToastChange } from "../reducers/config";
import { Remove_Comment_Id } from "../reducers/data";

type Props = {
  path: string;
  dispatch: AppDispatch;
};
type ReturnType = [any[], (e: any) => void, boolean, number, () => void];

/** 댓글 관련 훅스 */
const UseCommoent = ({ path, dispatch }: Props): ReturnType => {
  const remove_id = useSelector<RootState, string | undefined>(
    (state) => state.data.removeComment
  );
  const [CommentData, setCommentData] = useState<any[]>([]);
  const [pg, setPg] = useState<PgInter | null>(null);
  const [star, setStar] = useState<number>(0);
  const [isWrite, setIsWrite] = useState<boolean>(false);
  const GetComment = useCallback(() => {
    get_comment_api(path).then(({ state, data, msg }) => {
      if (state) {
        if (data.ctts) {
          setCommentData(data.ctts.lst);
          setPg(data.ctts.pg);
          if (data.AgvPt) {
            setStar(Number(data.AgvPt));
          } else {
            setStar(0);
          }
          if (data.isRegist) {
            setIsWrite(data.isRegist !== "NONE");
          }
        } else {
          setCommentData(data.lst);
          setPg(data.pg);
        }
      } else {
        dispatch(ToastChange({ active: true, content: msg }));
      }
    });
  }, [path, dispatch]);

  const PagingUpdate = useCallback(() => {
    if (pg) {
      const { page_last, page_next, page_cur } = pg;
      if (page_cur < page_last) {
        get_comment_api(path + `&page=${page_next}`).then(({ state, data }) => {
          if (state) {
            let clone = CommentData.slice();
            if (data.lst) {
              clone = [...clone, ...data.lst];
              setPg(data.pg);
            } else {
              clone = [...clone, ...data];
            }
            setCommentData(clone);
          }
        });
      }
    }
  }, [pg, CommentData]);

  const WriteCommnet = useCallback(
    (e: any) => {
      post_comment_api(e).then(({ state, data, msg }) => {
        if (state) {
          GetComment();
        } else {
          dispatch(ToastChange({ active: true, content: msg }));
        }
      });
    },
    [GetComment, dispatch]
  );
  const RemoveComment = useCallback(
    (id: string) => {
      remove_comment_api(id).then(({ state, data, msg }) => {
        if (state) {
          GetComment();
          dispatch(Remove_Comment_Id(undefined));
        } else {
          dispatch(ToastChange({ active: true, content: msg }));
        }
      });
    },
    [GetComment, dispatch]
  );
  useEffect(() => {
    if (remove_id) {
      RemoveComment(remove_id);
    }
    return () => {};
  }, [remove_id, dispatch, RemoveComment]);

  useEffect(() => {
    GetComment();
    return () => {};
  }, [GetComment]);

  return [CommentData, WriteCommnet, isWrite, star, PagingUpdate];
};

export default UseCommoent;
