import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";
import LineAdd from "../../components/LineAdd/LineAdd";
import MagazineSlideSection from "../../components/MagazineSlideSection/MagazineSlideSection";
import MainList from "../../components/MainList/MainList";
import MainNotice from "../../components/MainNotice/MainNotice";
import UseGetCommon from "../../hooks/UseGetCommon";
import "./css/index.css";
import ChannelService from "../../lib/ChannelService";
function Main() {
  const navigate = useNavigate();
  const [PrjtData] = UseGetCommon({
    path: "/web/prjt?prjt_type_cd=prjt&prjt_sort_order=new&row_limit_cnt=4",
  });
  const [MtupData] = UseGetCommon({
    path: "/web/prjt?prjt_type_cd=mtup&prjt_sort_order=new&row_limit_cnt=4",
  });
  const [NoticeData] = UseGetCommon({
    path: "/web/cmnt/2?bbs_id=3&row_limit_cnt=5",
  });
  const [LeaderList] = UseGetCommon({
    path: `/web/cmnt/2?bbs_id=4&ctts_ctg_cd=MGN_01&row_limit_cnt=1`,
  });
  const [WorkList] = UseGetCommon({
    path: `/web/cmnt/2?bbs_id=4&ctts_ctg_cd=MGN&row_limit_cnt=1`,
  });
  const [sharpList] = UseGetCommon({
    path: `/web/cmnt/2?bbs_id=4&ctts_ctg_cd=MGN_02&row_limit_cnt=1`,
  });
  useEffect(() => {
    ChannelService.loadScript();

    //3. 부트하기
    ChannelService.boot({
      pluginKey: "ca7f5371-acee-490e-92d2-37103ff8e3c4",
    });
    return () => {
      ChannelService.shutdown();
    };
  }, []);

  return (
    <main className="main-section">
      <Banner />
      <MainList data={PrjtData} type="project" />
      <div className="line" />
      <MainList data={MtupData} type="meetup" />
      <LineAdd />
      <MagazineSlideSection
        sharpList={sharpList}
        LeaderList={LeaderList}
        WorkList={WorkList}
        navigate={navigate}
      />
      <MainNotice data={NoticeData} />
      <Footer />
    </main>
  );
}

export default Main;
