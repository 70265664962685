import React, { Dispatch, SetStateAction } from "react";
import { ReactComponent as Arrowsvg } from "./svg/white-arrow.svg";
import "./css/index.css";
import { Link, NavigateFunction } from "react-router-dom";
import { encrypto } from "../../lib/lib";

type Props = {
  data:
    | {
        thumbnail: string;
        reader_usr_info: string;
        ctts_id: string;
        ctts_tit: string;
        ctts_ctg_cd: "MGN_01" | "MGN_02" | "MGN";
      }
    | undefined;
  NavLink?: Dispatch<SetStateAction<number>>;
  navigate?: NavigateFunction;
};

function LeaderIntro({ data, NavLink, navigate }: Props) {
  return data ? (
    <div className={`team-leader-section ${data.ctts_ctg_cd}-intro`}>
      <div className="wrapper">
        <div className="title">
          {data.ctts_ctg_cd === "MGN_01"
            ? "월간 팀플리더"
            : data.ctts_ctg_cd === "MGN_02"
            ? "팀플러 인터뷰"
            : "워크스토리"}
        </div>
        <div className="sub">
          {data.ctts_ctg_cd === "MGN_01"
            ? "마이팀플이 진행한 팀플리더의 인터뷰를 만나보세요!"
            : data.ctts_ctg_cd === "MGN_02"
            ? "우수팀플러의 채용 사례 등 인터뷰를 만나보세요"
            : "PO/PM들의 경험과 노하우를 전달합니다"}
        </div>
        <Link
          className="card"
          to={`/detail/magazine/${data ? encrypto(data.ctts_id) : ""}`}
        >
          <div
            className="img-wrapper"
            style={{
              backgroundImage: `url(${data?.thumbnail})`,
            }}
          ></div>
          <div className="content-wrapper">
            <div className="title">{data?.ctts_tit}</div>
            <div className="grade">{data?.reader_usr_info}</div>
          </div>
        </Link>
        <div className="nav-wrapper">
          <button
            className="magazine-list-nav-btn"
            onClick={() => {
              if (data) {
                if (NavLink) {
                  NavLink(
                    data.ctts_ctg_cd === "MGN_01"
                      ? 1
                      : data.ctts_ctg_cd === "MGN"
                      ? 2
                      : 3
                  );
                }
                if (navigate) {
                  navigate("/magazine", {
                    state:
                      data.ctts_ctg_cd === "MGN_01"
                        ? 1
                        : data.ctts_ctg_cd === "MGN"
                        ? 2
                        : 3,
                  });
                }
              }
            }}
          >
            <div className="title">
              {data.ctts_ctg_cd === "MGN_01"
                ? "월간 팀플리더"
                : data.ctts_ctg_cd === "MGN_02"
                ? "팀플러 인터뷰"
                : "워크스토리"}{" "}
              전체보기
            </div>
            <Arrowsvg />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default LeaderIntro;
