import React, { useCallback, useEffect, useState } from "react";
import "./css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clientApi } from "../../api/config";
import LoginBtn from "../../components/LoginBtn/LoginBtn";
import LoginInput from "../../components/LoginInput/LoginInput";
import { RootState } from "../../reducers";
import UseUserInfo from "../../hooks/UseUserInfo";
import { ToastChange } from "../../reducers/config";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usr_id = useSelector<RootState, string>((state) => state.auth.usr_id);
  const [GetUserInfo] = UseUserInfo();
  const [id, setId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorType, seterrorType] = useState<string | undefined>(undefined);
  const __login = useCallback(() => {
    if (id && password) {
      clientApi
        .post("/auth/signin", {
          login_id: id,
          login_pwd: password,
        })
        .then(({ data }) => {
          if (data.sts_fg && data.succ_obj) {
            const {
              succ_obj: { login_nm, accessToken },
            } = data;
            const tokenDate = new Date();
            tokenDate.setHours(tokenDate.getHours() + 24);
            window.localStorage.setItem(
              "accessToken",
              JSON.stringify({ token: accessToken, time: tokenDate })
            );
            GetUserInfo();
            if (login_nm) {
              navigate("/");
            } else {
              navigate("/firstinfo/step1");
            }
          } else {
            const { err_msg } = data;
            dispatch(ToastChange({ active: true, content: err_msg }));
            // seterrorType(err_cd);
          }
        })
        .catch((err) => {
          dispatch(
            ToastChange({
              active: true,
              content: "네트워크 연결을 확인해주세요",
            })
          );
        });
    }
  }, [id, password, GetUserInfo, navigate, dispatch]);
  const __navSignup = useCallback(() => {
    navigate("/signup");
  }, [navigate]);
  useEffect(() => {
    if (usr_id) {
      navigate("/");
    }
    return () => {};
  }, [usr_id, navigate]);

  return (
    <main className="login">
      <form
        className="wrapper"
        onSubmit={(e) => {
          __login();
          e.preventDefault();
        }}
      >
        <div className="title">팀플에서 새로운 도전을 시작해보세요</div>
        <div className="input-section">
          <LoginInput
            type={"text"}
            title="아이디"
            placeholder={"아이디 입력"}
            setValue={setId}
            errorType={
              errorType === "02" ? "존재하지 않는 아이디입니다" : undefined
            }
            Check={undefined}
            value={id}
          />
          <LoginInput
            Check={undefined}
            type={"password"}
            title="비밀번호"
            placeholder={"비밀번호 입력"}
            setValue={setPassword}
            errorType={
              errorType === "01" ? "비밀번호가 일치하지 않습니다." : undefined
            }
            value={password}
          />
        </div>
        <div className="button-section">
          <LoginBtn type="login" title="로그인" __submit={__login} />
          <LoginBtn type="signup" title="회원가입" __submit={__navSignup} />
        </div>
        <a className="password-find" href="/mail">
          비밀번호를 잃어버리셨나요?
        </a>
      </form>
    </main>
  );
}

export default Login;
