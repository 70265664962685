import React, { Dispatch, SetStateAction } from "react";
import LineAdd from "../../components/LineAdd/LineAdd";
import MagazineList from "../../components/MagazineList/MagazineList";
import MagazineSlideSection from "../../components/MagazineSlideSection/MagazineSlideSection";
import UseGetCommon from "../../hooks/UseGetCommon";
import "./css/index.css";

interface Props {
  NavLink: Dispatch<SetStateAction<number>>;
}

const Magazine = ({ NavLink }: Props) => {
  const [LeaderList] = UseGetCommon({
    path: `/web/cmnt/2?bbs_id=4&ctts_ctg_cd=MGN_01&row_limit_cnt=3&ctts_stat_cd=ctts_sts_nrml`,
    limit: 3,
  });
  const [WorkList] = UseGetCommon({
    path: `/web/cmnt/2?bbs_id=4&ctts_ctg_cd=MGN&row_limit_cnt=3&ctts_stat_cd=ctts_sts_nrml`,

    limit: 3,
  });
  const [sharpList] = UseGetCommon({
    path: `/web/cmnt/2?bbs_id=4&ctts_ctg_cd=MGN_02&row_limit_cnt=3&ctts_stat_cd=ctts_sts_nrml`,
    limit: 3,
  });
  return (
    <div className="magazine">
      <MagazineSlideSection
        LeaderList={LeaderList}
        WorkList={WorkList}
        sharpList={sharpList}
        NavLink={NavLink}
      />
      <MagazineList
        title="월간 팀플리더"
        sub="마이팀플이 진행한 다양한 인터뷰를 만나보세요"
        list={LeaderList}
        type={1}
        nav={NavLink}
      />
      <LineAdd />
      <MagazineList
        title="워크스토리"
        sub="PO/PM들의 경험과 노하우를 전달합니다"
        list={WorkList}
        type={2}
        nav={NavLink}
      />
      <div className="grey-block" />
      <MagazineList
        title="팀플러 인터뷰"
        sub="우수팀플러의 채용 사례 등 인터뷰를 만나보세요"
        list={sharpList}
        type={3}
        nav={NavLink}
      />
    </div>
  );
};

export default Magazine;
