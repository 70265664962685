import { DdayChange, formatDate, makeComma } from "../../lib/lib";
// import { ReactComponent as ScrapSvg } from "./svg/scrap.svg";
import { ReactComponent as ApplySvg } from "./svg/apply.svg";
import "./css/index.css";
import Info from "./sub/Info";
import Ask from "./sub/Ask";
import Review from "./sub/Review";
import { Dispatch, SetStateAction, useState } from "react";
import {
  authinterface,
  detailtype,
  MeetupBasicDataType,
  ProjectBasicDataType,
} from "../../interface/interface";
import Members from "./sub/Members";
import { AppDispatch } from "../..";
type Props = {
  Data: ProjectBasicDataType | MeetupBasicDataType | undefined;
  selectField: detailtype | undefined;
  navIndex: number;
  setSelectField: Dispatch<SetStateAction<detailtype | undefined>> | undefined;
  __navPayment: (e: detailtype | undefined) => void;
  setNavIndex: Dispatch<SetStateAction<number>>;
  authInfo: authinterface;
  id: string;
  categoryContent: string | undefined;
  type: string;
  navLayout: string[];
  dispatch: AppDispatch;
};

const Detail = ({
  Data,
  selectField,
  navIndex,
  setSelectField,
  __navPayment,
  setNavIndex,
  authInfo,
  id,
  categoryContent,
  type,
  navLayout,
  dispatch,
}: Props) => {
  const nowApllyLst = Data?.rcrt_apply_lst.filter(
    ({ apply_sts_cd }) => apply_sts_cd !== "apply_sts_cancel"
  ).length;

  const isApply =
    Data?.rcrt_apply_lst.some(
      ({ apply_usr_id, apply_sts_cd }) =>
        authInfo.usr_id === apply_usr_id && apply_sts_cd !== "apply_sts_cancel"
    ) ||
    Data?.prjt_sts_cd !== "prjt_sts_rcrt_ing" ||
    !authInfo.usr_id;
  const isFinish =
    (Data?.mtup_typ === "OFFLINE" &&
      Number(nowApllyLst) >= Number(Data?.mtup_usr_cnt)) ||
    Data?.prjt_sts_cd !== "prjt_sts_rcrt_ing";
  const [isOpen, setIsOpen] = useState(false);
  return Data ? (
    <main className="detail-section">
      <div className={`back ${isOpen ? "back-on" : ""}`} />
      {type === "project" && Data.prjt_sts_cd === "prjt_sts_rcrt_ing" ? (
        <div className={`mb-field ${isOpen ? "mb-active" : ""}`}>
          <button
            className="close-btn"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <img src="/assets/common/grey-arrow.svg" alt="" />
          </button>
          <div className="title">신청 분야를 선택해주세요</div>
          <div className="field-list">
            {Data.rcrt_lst.map((item, idx) => {
              return (
                <button
                  key={idx}
                  className={`select ${
                    selectField?.rcrt_nm === item.rcrt_nm ? "select-active" : ""
                  }`}
                  onClick={() => {
                    if (setSelectField) {
                      setSelectField(item);
                    }
                  }}
                >
                  <div className="circle">
                    <img src="/assets/login/check.svg" alt="" />
                  </div>
                  {item.rcrt_nm}
                </button>
              );
            })}
          </div>
          <button
            className="select-btn"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            선택 완료
          </button>
        </div>
      ) : undefined}
      <div className="top">
        <div className="wrapper">
          <div
            className="img-wrapper"
            style={{ backgroundImage: `url(${Data.prjt_thumbnail})` }}
          />
          <div className="right">
            <div className="category-wrapper">
              <div className={`category ${type}-category`}>
                {categoryContent}
              </div>
              {/* <ScrapSvg /> */}
            </div>
            <div className="title">{Data.prjt_nm}</div>
            <div
              className="img-wrapper"
              style={{ backgroundImage: `url(${Data.prjt_thumbnail})` }}
            />
            <div className="top-info">
              <div className="info-line">
                <div className="tag">모집 일시</div>
                <div
                  className={`per-wrapper ${
                    Data.prjt_sts_cd !== "prjt_sts_rcrt_ing" ? "finish-day" : ""
                  }`}
                >
                  <div className="period">
                    {formatDate(Data.rcrt_apply_st_at, "-")} ~{" "}
                    {formatDate(Data.rcrt_apply_en_at, "-")}
                  </div>
                  <div className="d-day">
                    {Data.prjt_sts_cd === "prjt_sts_rcrt_ing"
                      ? `D-${DdayChange(Data.rcrt_apply_en_at)}`
                      : Data.prjt_sts_cd === "prjt_sts_rcrt_wait"
                      ? "예정"
                      : "마감"}
                  </div>
                </div>
              </div>
              <div className="info-line">
                <div className="tag">
                  {type === "project" ? "팀플 시작" : "밋업 시작"}
                </div>
                <div className="field">
                  {Data.prjt_apply_st_at
                    ? formatDate(Data.prjt_apply_st_at, "-")
                    : "미정"}
                </div>
              </div>
              {Data.prjt_type_cd === "mtup" ? (
                <div className="info-line">
                  <div className="tag">모집 인원</div>
                  <div className="field">
                    <span>{nowApllyLst}</span>/
                    {Data.mtup_typ === "OFFLINE"
                      ? Data.mtup_usr_cnt
                      : "제한없음"}
                  </div>
                </div>
              ) : undefined}
              <div className="info-line">
                <div className="tag">팀 리더</div>
                <div className="leader">
                  <div className="name">{Data.leadr_usr_nm}</div>
                </div>
              </div>
            </div>
            <div className="position">{Data.leadr_brif}</div>
            <div className="line" />
            <div className="price-wrapper">
              <div className="title">참가비</div>
              <div className="price">
                {Data.prjt_type_cd === "prjt"
                  ? makeComma(Data.attd_amt)
                  : makeComma(Data.mtup_prc)}
                원
              </div>
            </div>

            <div
              className={`field-wrapper ${
                Data.prjt_type_cd === "mtup" ? "mtup-field-wrapper" : ""
              }`}
            >
              {Data.prjt_sts_cd === "prjt_sts_rcrt_ing" ? (
                Data.prjt_type_cd === "prjt" && setSelectField ? (
                  <div
                    className={`btn-wrapper`}
                    style={
                      isOpen
                        ? {
                            height: `${(Data.rcrt_lst.length + 1) * 50}px`,
                          }
                        : undefined
                    }
                  >
                    <button
                      className="now"
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      {selectField ? selectField.rcrt_nm : "모집분야 선택"}
                      <img
                        src="/assets/common/grey-arrow.svg"
                        alt=""
                        style={
                          isOpen ? { transform: `rotate(180deg)` } : undefined
                        }
                      />
                    </button>

                    <div className="field-list pc">
                      {Data.rcrt_lst.map((item, idx) => {
                        return (
                          <button
                            key={idx}
                            className="select"
                            onClick={() => {
                              setSelectField(item);
                              setIsOpen(false);
                            }}
                          >
                            {item.rcrt_nm}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                ) : undefined
              ) : undefined}
            </div>
            {navIndex === 0 ? (
              <button
                className="insert"
                onClick={() => {
                  if (Data.prjt_type_cd === "prjt" && !selectField) {
                    setIsOpen(!isOpen);
                  } else {
                    __navPayment(selectField);
                  }
                }}
                disabled={
                  authInfo.usr_id && !isApply && !isFinish ? false : true
                }
              >
                {!isFinish ? <ApplySvg /> : undefined}
                {isFinish
                  ? "신청이 불가능합니다"
                  : isApply
                  ? !authInfo.usr_id
                    ? "로그인 후 이용가능"
                    : "신청완료"
                  : `신청하기 ${type === "project" ? "모집분야 선택" : ""}`}
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="nav-wrapper">
        <div className="wrapper">
          {navLayout.map((item, idx) => {
            return (
              <button
                key={idx}
                className={`nav ${navIndex === idx ? "nav-active" : ""}`}
                onClick={() => {
                  setNavIndex(idx);
                }}
              >
                {item}
              </button>
            );
          })}
          <div className="line" style={{ left: `${83 * navIndex}px` }} />
        </div>
      </div>
      <div className="bottom">
        {navIndex === 0 ? (
          <Info
            type={type}
            data={Data}
            __navPayment={__navPayment}
            isApply={isApply}
          />
        ) : navIndex === 1 ? (
          <Ask data={Data} auth={authInfo} id={id} />
        ) : navIndex === 2 &&
          type === "project" &&
          Data.prjt_sts_cd !== "prjt_sts_rcrt_ing" &&
          Data.prjt_sts_cd !== "prjt_sts_rcrt_wait" ? (
          // teamplRoom => Members 팀플룸에서 선정회원으로 변경
          <Members
            title={"선정 팀원"}
            data={Data.rcrt_apprv_lst.filter(
              ({ apprv_sts_cd }) => apprv_sts_cd === "apprv_sts_select"
            )}
            usr_id={authInfo.usr_id}
          />
        ) : (
          <Review
            dispatch={dispatch}
            usr_id={authInfo.usr_id}
            prjt_id={Data.prjt_id}
            profile={authInfo.info.img}
            apprv_lst={Data.rcrt_apprv_lst}
          />
        )}
      </div>
    </main>
  ) : (
    <div style={{ display: "none" }} />
  );
};

export default Detail;
