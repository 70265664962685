import React from "react";
import { Link } from "react-router-dom";
import { encrypto } from "../../lib/lib";
import { ReactComponent as ArrowSvg } from "../Banner/svg/btn-arrow.svg";
import EmtyList from "../EmtyList/EmtyList";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./css/index.css";
interface Props {
  title: string;
  sub: string;
  type: number;
  nav: (e: number) => void;
  list: {
    thumbnail: string;
    reader_usr_info: string;
    ctts_id: string;
    ctts_tit: string;
  }[];
}

const MagazineList = ({ title, sub, list, nav, type }: Props) => {
  return (
    <div className="magazine-list">
      <div className="wrapper">
        <div className="title">{title}</div>
        <div className="sub">{sub}</div>
        {list.length > 0 ? (
          <div className="magazine-list-container">
            {list.map((item, idx) => {
              return item ? (
                <MagazineListContent data={item} key={idx} />
              ) : (
                <div className="magazine-card" key={idx}>
                  <Skeleton containerClassName="img-wrapper" height={"100%"} />
                </div>
              );
            })}
          </div>
        ) : (
          <EmtyList text="등록된 매거진이 없습니다" />
        )}
        <div className="nav-wrapper">
          <button
            className="nav-btn"
            onClick={() => {
              nav(type);
            }}
          >
            <div className="title">{title} 전체보기</div>
            <ArrowSvg />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MagazineList;

const MagazineListContent = ({
  data,
}: {
  data: {
    thumbnail: string;
    reader_usr_info: string;
    ctts_id: string;
    ctts_tit: string;
  };
}) => {
  const { thumbnail, reader_usr_info, ctts_id, ctts_tit } = data;
  return (
    <Link
      className="magazine-card"
      to={`/detail/magazine/${encrypto(ctts_id)}`}
    >
      <div
        className="img-wrapper"
        style={{
          backgroundImage: `url(${thumbnail})`,
        }}
      />
      <div className="title">{ctts_tit}</div>
      <div className="sub">{reader_usr_info}</div>
    </Link>
  );
};
