import path from "path";
import React, { useCallback, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { HeaderChange } from "../../../../reducers/config";
import Keywords from "../../../Kewords/Keywords";

function reducer(
  state: {
    tag_lst: { tag_nm: string }[];
  },
  { keyword, type }: { keyword: { tag_nm: string }[]; type: string }
) {
  switch (type) {
    case "keyword":
      return { ...state, tag_lst: keyword };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
function FixKeyword({
  __fix,
  type,
  data,
}: {
  __fix: (e: { tag_nm: string }[]) => void;
  type: string | undefined;
  data: { tag_nm: string }[];
}) {
  const dispatch = useDispatch();
  const [state, patch] = useReducer(reducer, { tag_lst: data });
  const __changeHeader = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (type === "keyword") {
        const { currentTarget } = e;
        if (currentTarget.scrollTop < 1) {
          dispatch(HeaderChange("parent"));
        } else {
          dispatch(HeaderChange(""));
        }
      }
    },
    [dispatch, type]
  );
  useEffect(() => {
    if (type === "keyword") {
      dispatch(HeaderChange("parent"));
    }
    return () => {
      setTimeout(() => {
        document.getElementById("fix-keyword")?.scrollTo(0, 0);
      }, 200);
    };
  }, [type, dispatch]);

  useEffect(() => {
    patch({
      type: "keyword",
      keyword: data,
    });
    return () => {};
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(HeaderChange(""));
    };
  }, [dispatch]);
  return (
    <div
      className={`fix-keyword ${type === "keyword" ? "on" : ""}`}
      onScroll={__changeHeader}
      id="fix-keyword"
    >
      <div className="top">
        <div className="title">나의 키워드를 등록해주세요</div>
        <div className="sub">
          나를 표현하는 키워드를 등록해주세요 <br /> 사이드 프로젝트 선정 시
          도움이 됩니다
        </div>
      </div>
      <div className="bottom">
        <Keywords data={state.tag_lst} patch={patch} />
        <button
          onClick={() => {
            __fix(state.tag_lst);
            dispatch(HeaderChange(""));
          }}
        >
          키워드 등록
        </button>
      </div>
    </div>
  );
}

export default FixKeyword;
