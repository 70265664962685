import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clientApi } from "../../api/config";
import Profile from "../../components/Profile/Profile";
import { authinterface } from "../../interface/interface";
import { decypto } from "../../lib/lib";
import { RootState } from "../../reducers";

const OtherProfile = () => {
  const { pathname } = useLocation();
  const authinfo = useSelector<RootState, authinterface>((state) => state.auth);
  const [data, setData] = useState<authinterface>(authinfo);
  useEffect(() => {
    const id = String(decypto(pathname.split("/profile/")[1]));
    clientApi
      .get(`/auth/usr/usr/${id}`)
      .then(({ data: { sts_fg, succ_obj } }) => {
        if (sts_fg) {
          const {
            info: {
              cnt_end,
              cnt_inbi,
              cnt_prjt,
              login_nm,
              usr_id,
              usr_thumbnail,
              usr_typ,
              email,
              link,
              info_desc,
            },
            tag,
          } = succ_obj;

          setData({
            usr_typ,
            grade: { cnt_prjt, cnt_end, cnt_inbi },
            info: {
              img: usr_thumbnail,
              login_nm,
              link,
              tag_lst: tag,
              info_desc,
            },
            email,
            usr_id,
          });
        }
      });

    return () => {};
  }, [pathname, authinfo.usr_id]);

  return (
    <main className="other-page">
      <Profile
        __updateNickname={undefined}
        intro={data.info.info_desc}
        __fixportfolio={undefined}
        fixtype={undefined}
        __fixKeywords={undefined}
        setFixtype={undefined}
        __updateImg={undefined}
        authinfo={data}
        tabLayout={tabLayout}
        my_usr_id={authinfo.usr_id}
        isMe={false}
      />
    </main>
  );
};

export default OtherProfile;

const tabLayout = ["프로필", "프로젝트", "추천평"];
