import React, { useCallback, useEffect, useReducer } from "react";
import { CalendarInter, ProjectCardInter } from "../interface/interface";
import CalendarWrite from "../pages/TeamRoom/sub/CalendarWrite";
import { NavigateFunction, useOutletContext } from "react-router-dom";
import { clientApi } from "../api/config";

type Props = {};

var info = {
  title: "",
  content: "",
  timestamp: new Date(),
};
const CalendarWriteContainer = (props: Props) => {
  const {
    navigate,
    nowPrj,
    pathname,
  }: {
    pathname: string;
    navigate: NavigateFunction;
    nowPrj: ProjectCardInter;
  } = useOutletContext();
  const [state, dispatch] = useReducer(reducer, info);
  const UpdateInfo = useCallback(
    (type: "title" | "content" | "timestamp", value: string, time?: Date) => {
      dispatch({
        type,
        payload: value,
        time,
      });
    },
    []
  );
  const WriteSubmit = useCallback(() => {
    clientApi
      .post(`/web/my/prjt/${nowPrj.prjt_id}/task`, {
        schd_nm: state.title,
        schd_ctts: state.content,
        schd_apply_st_at: state.timestamp,
        schd_apply_en_at: state.timestamp,
      })
      .then(({ data: { sts_fg } }) => {
        if (sts_fg) {
          navigate(-1);
        }
      });
  }, [state, nowPrj, navigate]);

  useEffect(() => {
    const time = Number(pathname.split("/")[4]);
    if (time) {
      dispatch({
        type: "timestamp",
        payload: "",
        time: new Date(time),
      });
    }

    return () => {};
  }, [pathname]);

  return (
    <CalendarWrite
      state={state}
      UpdateInfo={UpdateInfo}
      nowPrj={nowPrj}
      navigate={navigate}
      WriteSubmit={WriteSubmit}
    />
  );
};

export default CalendarWriteContainer;

function reducer(
  state: CalendarInter,
  {
    payload,
    type,
    time,
  }: { payload: string; type: string; time?: Date | undefined }
) {
  switch (type) {
    case "title":
      return { ...state, title: payload };
    case "content":
      return { ...state, content: payload };
    case "timestamp":
      return { ...state, timestamp: time ? time : new Date() };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
