import React, { useEffect, useState } from "react";
import "./css/index.css";
import { ProjectCardInter } from "../../interface/interface";
import { ReactComponent as Scrapsvg } from "./svg/scrap.svg";
import { DdayChange, PrjtCategory } from "../../lib/lib";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
/** 프로젝트,밋업 list component */
function ListCard({
  data,
  __nav,
  scrap,
  unscrap,
  isScrap,
}: {
  data: ProjectCardInter;
  __nav: (p: string) => void;
  scrap: (id: { prjt_id: string }) => void;
  unscrap: (id: string, type: "prjt" | "mgn" | "mtup") => void;
  isScrap: boolean;
}) {
  return (
    <button className="list-card">
      {data ? (
        <ListCardContent
          data={data}
          __nav={__nav}
          scrap={scrap}
          unscrap={unscrap}
          isScrap={isScrap}
        />
      ) : (
        <>
          <Skeleton containerClassName="img-wrapper" height={"100%"} />
          <Skeleton containerClassName="title-wrapper" height={"23px"} />
          <div className="line" />
          <div className="period-wrapper">
            <Skeleton
              containerClassName="period"
              height={"100%"}
              width="100px"
            />
            <Skeleton
              containerClassName="category"
              height={"100%"}
              borderRadius={"50%"}
            />
          </div>
        </>
      )}
    </button>
  );
}

export default ListCard;

const ListCardContent = ({
  data,
  __nav,
  scrap,
  unscrap,
  isScrap,
}: {
  data: ProjectCardInter;
  __nav: (p: string) => void;
  scrap: (id: { prjt_id: string }) => void;
  unscrap: (id: string, type: "prjt" | "mgn" | "mtup") => void;
  isScrap: boolean;
}) => {
  const {
    prjt_nm,
    rcrt_apply_en_at,
    rcrt_apply_st_at,
    prjt_ctg_cd,
    prjt_id,
    prjt_sts_cd,
    prjt_thumbnail,
    rcrt_usr_cnt,
    rcrt_apply_usr_cnt,
    prjt_type_cd,
  } = data;
  const [isNew, setisNew] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [isHot, setIsHot] = useState(false);
  useEffect(() => {
    if (data) {
      const startTime = new Date(rcrt_apply_st_at);
      const finishTime = new Date(rcrt_apply_en_at);
      startTime.setDate(startTime.getDate() + 5);
      finishTime.setDate(finishTime.getDate() - 5);
      if (startTime.getTime() >= Date.now()) {
        setisNew(true);
      } else {
        setisNew(false);
      }
      if (finishTime.getTime() <= Date.now()) {
        setIsFinish(true);
      } else {
        setIsFinish(false);
      }
      if (rcrt_usr_cnt < rcrt_apply_usr_cnt) {
        setIsHot(true);
      } else {
        setIsHot(false);
      }
    }
    return () => {};
  }, [rcrt_apply_en_at, rcrt_apply_st_at, rcrt_usr_cnt, rcrt_apply_usr_cnt]);
  return (
    <>
      <div
        className="img-wrapper"
        style={{ backgroundImage: `url(${prjt_thumbnail})` }}
        onClick={() => {
          __nav(prjt_id);
        }}
      >
        {prjt_sts_cd === "prjt_sts_rcrt_ing" && (
          <div className="card-tag-wrapper">
            {isNew ? <div className="tag new-tag">신규</div> : undefined}
            {isFinish ? (
              <div className="tag finish-tag">마감임박</div>
            ) : undefined}
            {isHot ? <div className="tag hot-tag">HOT</div> : undefined}
          </div>
        )}
        {(prjt_sts_cd === "prjt_sts_ing" ||
          prjt_sts_cd === "prjt_sts_end" ||
          prjt_sts_cd === "prjt_sts_rcrt_end") && (
          <div className="finish-blur">
            <img src="/assets/list/finish-logo.svg" alt="logo" />
            <div className="blur-title">마감된 프로젝트입니다</div>
          </div>
        )}
      </div>
      <div className="title-wrapper">
        <div
          className={`title ${
            prjt_sts_cd !== "prjt_sts_rcrt_ing" ? "grey-title" : ""
          }`}
          onClick={() => {
            __nav(prjt_id);
          }}
        >
          {prjt_nm}
        </div>
        <Scrapsvg
          className={isScrap ? "scrap-on" : ""}
          onClick={() => {
            if (isScrap) {
              unscrap(prjt_id, prjt_type_cd);
            } else {
              scrap({ prjt_id });
            }
          }}
        />
      </div>
      <div className="line" />
      <div className="period-wrapper">
        <div className="period">
          {prjt_sts_cd === "prjt_sts_rcrt_ing" ? (
            <>
              마감일 <span>D-{DdayChange(rcrt_apply_en_at)}</span>
            </>
          ) : prjt_sts_cd === "prjt_sts_rcrt_wait" ? (
            "오픈예정"
          ) : (
            "모집마감"
          )}
        </div>
        <div className="category">
          {PrjtCategory[prjt_ctg_cd as keyof typeof PrjtCategory]}
        </div>
      </div>{" "}
    </>
  );
};
