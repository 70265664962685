import React from "react";
import { NavigateFunction, useOutletContext } from "react-router-dom";
import Calendar from "../../../components/Calendar/Calendar";
import { ProjectCardInter } from "../../../interface/interface";

const RoomCalendar = () => {
  const {
    nowPrj,
    isLeader,
    navigate,
    usr_id,
  }: {
    nowPrj: ProjectCardInter;
    isLeader: boolean;
    navigate: NavigateFunction;
    usr_id: string;
  } = useOutletContext();

  return (
    <>
      <div className="room-calendar">
        <Calendar
          initial={new Date()}
          type="list"
          __update={undefined}
          prj_id={nowPrj && nowPrj.prjt_id}
          nowPrj={nowPrj}
          isWrite={isLeader}
          navigate={navigate}
          usr_id={usr_id}
        />
      </div>
    </>
  );
};

export default RoomCalendar;
