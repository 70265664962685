import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { clientApi } from "../../api/config";
import { ProjectCardInter } from "../../interface/interface";
import { LoadingChange } from "../../reducers/config";
import "./css/index.css";
type LocType = {
  state: ProjectCardInter;
};

const FixApply = () => {
  const dispatch = useDispatch();
  const { state } = useLocation() as LocType;
  const [isFix, setIsFix] = useState<boolean>(false);
  const [brif1, setBrif1] = useState<string>(
    state.apply_self_brif_1 ? state.apply_self_brif_1 : ""
  );
  const [brif2, setBrif2] = useState<string>(
    state.apply_self_brif_2 ? state.apply_self_brif_2 : ""
  );
  const Fix_Brif = useCallback(() => {
    dispatch(LoadingChange(true));
    clientApi
      .post(`/web/prjt/${state.prjt_id}/rcrt/${state.rcrt_id}/chg`, {
        apply_id: state.apply_id,
        apply_self_brif_1: brif1,
        apply_self_brif_2: brif2,
      })
      .then(({ data: { sts_fg } }) => {
        if (sts_fg) {
          setIsFix(false);
        }
        dispatch(LoadingChange(false));
      });
  }, [state, dispatch, brif1, brif2]);

  return (
    <main className="fix-apply-page">
      <div className="wrapper">
        <div className="title-section">
          <div className="title">프로젝트 지원서</div>
          {state.prjt_sts_cd === "prjt_sts_rcrt_ing" ? (
            <button
              className={`fix-btn ${isFix ? "fixing-btn" : ""}`}
              onClick={() => {
                if (isFix) {
                  Fix_Brif();
                } else {
                  setIsFix(true);
                }
              }}
            >
              {isFix ? "정보수정 완료하기" : "정보 수정"}
            </button>
          ) : undefined}
        </div>
        <div className="project-card">
          <div className="title">프로젝트</div>
          <div className="project-box">
            <div
              className="img-wrapper"
              style={{ backgroundImage: `url(${state.prjt_thumbnail})` }}
            />
            <div className="project-title">{state.prjt_nm}</div>
          </div>
        </div>
        <div className="info-wrapper">
          <div className="number">01</div>
          <div className="info-title">
            유사프로젝트 참여 경험이 있나요? 그 프로젝트에서 자신의 역할은
            무엇인가요?
          </div>
          <textarea
            disabled={!isFix}
            value={brif1}
            onChange={(e) => {
              setBrif1(e.target.value);
            }}
          ></textarea>
        </div>
      </div>
      <div className="grey-line" />
      <div className="wrapper">
        <div className="info-wrapper">
          <div className="number">02</div>
          <div className="info-title">
            지원시 팀플리더가 요청한 필수정보는 여기에 작성해주세요.
          </div>
          <div className="info-sub">
            요청한 정보가 없는 경우에는, 팀플리더가 선정에 참고할 수 있는 내용을
            적어주세요.
          </div>
          <textarea
            disabled={!isFix}
            value={brif2}
            onChange={(e) => {
              setBrif2(e.target.value);
            }}
          ></textarea>
        </div>
      </div>
    </main>
  );
};

export default FixApply;
