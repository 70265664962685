import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Menusvg } from "./assets/menu.svg";
import { ReactComponent as LogoSvg } from "./assets/logo.svg";
import "./css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { MenuChange, PopupChange, ToastChange } from "../../reducers/config";
import { AuthType } from "../../reducers/auth";
import UseUserInfo from "../../hooks/UseUserInfo";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isMenu = useSelector<RootState, boolean>(
    (state) => state.config.isMenu
  );
  const headerType = useSelector<RootState, string>(
    (state) => state.config.header
  );
  const {
    usr_id,
    info: { img },
  } = useSelector<RootState, AuthType>((state) => state.auth);
  const [GetUserInfo] = UseUserInfo();

  useEffect(() => {
    const mainhtml = document.getElementsByTagName("main");
    const root = document.getElementById("root");
    root?.scrollTo(0, 0);
    if (mainhtml[0]) {
      mainhtml[0].scrollTo(0, 0);
    }
    return () => {};
  }, [location]);

  useEffect(() => {
    function ExitPage() {
      navigate("/login");
      dispatch(
        ToastChange({ active: true, content: "로그인 후에 이용해주세요" })
      );
    }

    const token = window.localStorage.getItem("accessToken");

    if (location.pathname.split("/")[1] === "my") {
      if (token) {
        const { time } = JSON.parse(token);
        const expire_time = new Date(time).getTime();
        const now_time = new Date().getTime();
        if (expire_time <= now_time) {
          ExitPage();
        }
      } else {
        ExitPage();
      }
    }
    return () => {};
  }, [usr_id, location.pathname, dispatch, navigate]);
  useEffect(() => {
    const token = window.localStorage.getItem("accessToken");
    if (token) {
      const { time } = JSON.parse(token);
      const expire_time = new Date(time).getTime();
      const now_time = new Date().getTime();
      if (expire_time > now_time) {
        GetUserInfo();
      } else {
        window.localStorage.removeItem("accessToken");
      }
    }
    return () => {};
  }, []);
  // useEffect(() => {
  //   dispatch(
  //     PopupChange({
  //       active: true,
  //       type: "error",
  //       data: "",
  //     })
  //   );

  //   return () => {};
  // }, []);

  return (
    <header
      className={`main-header ${
        location.pathname === "/signup/finish" ||
        location.pathname.includes("/firstinfo")
          ? "header-black"
          : headerType === "parent"
          ? "header-parent"
          : ""
      }`}
    >
      <div className="wrapper">
        <LogoSvg
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        />
        <div className="pc-menu">
          {arr.map(({ title, nav }: { title: string; nav: string }, idx) => {
            return (
              <Link to={nav} key={idx}>
                {title}
              </Link>
            );
          })}
        </div>
        <div className="right">
          {usr_id ? (
            <Link
              to={"/my/page"}
              className="my-info-nav"
              style={{
                backgroundImage: `url(${img})`,
              }}
            ></Link>
          ) : (
            <Link to={"/login"} className="login-nav">
              로그인
            </Link>
          )}
          {isMenu ? (
            <img
              className="menu"
              src="/assets/common/cancel.svg"
              alt=""
              onClick={() => {
                dispatch(MenuChange(false));
              }}
            />
          ) : (
            <Menusvg
              className="menu"
              onClick={() => {
                dispatch(MenuChange(true));
              }}
            />
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;

const arr = [
  { title: "ABOUT", nav: "/about" },
  { title: "프로젝트", nav: "/project" },
  { title: "밋업", nav: "/meetup" },
  { title: "매거진", nav: "/magazine" },
  { title: "공지사항", nav: "/notice" },
];
