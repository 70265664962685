import React, { useCallback, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import FirstInfoBtn from "../../../components/FirstInfoBtn/FirstInfoBtn";
import FirstInfoTop from "../../../components/FirstInfoTop/FirstInfoTop";
import { stateface } from "./interface";

function Step2() {
  const navigate = useNavigate();
  const {
    state,
    dispatch,
  }: { state: stateface; dispatch: (p: object) => void } = useOutletContext();
  const [link, setLink] = useState(state.link);
  // const __urlCheck = useCallback(() => {
  //   try {
  //     new URL(link);
  //     return true;
  //   } catch {
  //     return false;
  //   }
  // }, [link]);

  const __nav = useCallback(() => {
    dispatch({
      type: "link",
      payload: link,
    });
    navigate("/firstinfo/step3");
  }, [navigate, dispatch, link]);
  return (
    <div className="step2">
      <FirstInfoTop
        type="portfolio"
        index={1}
        step="Step 2"
        title="포트폴리오를 등록해주세요"
        content={undefined}
      />
      <div className="bottom">
        <div className="title">포트폴리오 링크 입력 (선택)</div>
        <div className="sub">
          SNS, 노션, 비핸스, 링크드인 등에 포트폴리오를 업데이트하고
          <br /> 해당 링크를 추가하고 공유해주세요.
        </div>
        <input
          type="text"
          placeholder="링크를 입력해주세요"
          value={link}
          onChange={(e) => {
            setLink(e.target.value);
          }}
        />
        <img
          src="/assets/firstinfo/step2.png"
          srcSet="/assets/firstinfo/step2@2x.png 2x, /assets/firstinfo/step2@3x.png 3x"
          alt=""
        />
        <FirstInfoBtn
          title="Step 3. 나의 키워드 등록하기"
          type={"insert"}
          __nav={__nav}
        />
      </div>
    </div>
  );
}

export default Step2;
