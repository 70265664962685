import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { PopupChange } from "../../reducers/config";
import Marketing from "./components/Marketing";
import RemoveComment from "./components/RemoveComment";
import Report from "./components/Report";
import WithDraw from "./components/WithDraw";
import "./css/index.css";
import PostRemove from "./components/PostRemove";
import { useNavigate } from "react-router-dom";
interface PopupInt {
  active: boolean;
  type: string;
  data: any;
}

function Popup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { active, type, data } = useSelector<RootState, PopupInt>(
    (state) => state.config.popup
  );
  const __cancel = useCallback(() => {
    dispatch(PopupChange({ active: false, type: "", data: "" }));
  }, [dispatch]);

  return (
    <div className={`popup ${active ? "popup-on" : ""}`}>
      <div className="back" />
      {type === "marketing" && <Marketing __cancel={__cancel} />}
      {type === "report" && (
        <Report __cancel={__cancel} data={data} dispatch={dispatch} />
      )}
      {type === "withdraw" && (
        <WithDraw __cancel={__cancel} dispatch={dispatch} />
      )}
      {(type === "ASK" ||
        type === "RECOMMEND" ||
        type === "REVIEW" ||
        type === "ROOM" ||
        type === "CALENDAR") && (
        <RemoveComment
          __cancel={__cancel}
          data={data}
          type={type}
          dispatch={dispatch}
        />
      )}
      {type === "POST_REMOVE" && (
        <PostRemove
          __cancel={__cancel}
          data={data}
          dispatch={dispatch}
          navigate={navigate}
        />
      )}
      {type === "error" && (
        <div className="error-popup">
          <img
            src="/assets/common/cancel.svg"
            alt=""
            className="cancel"
            onClick={__cancel}
          />
          <div className="sub">
            안녕하세요? 마이팀플 운영팀입니다.
            <br /> 현재 서버 업데이트 작업으로 인해 홈페이지 접속이 원할하게
            이뤄지지 않고 있습니다.
            <br /> 업데이트한 서버가 인터넷상에 적용될 때까지 최대 48시간이
            걸리는 이슈로, 금일 언제 정상화가 될지 <br /> 시간을 특정할 수 없는
            상태입니다.
            <br /> 플랫폼 이용에 불편을 끼쳐드린 점 양해부탁드리며, 금일
            오픈하기로 예정된 프로젝트 신청 관련해서는 myteample@myteample.com
            으로 <br />
            문의 부탁드립니다.
            <br /> 조속한 웹페이지 정상을 위해 마이팀플 운영진 모두 최선을
            다하겠습니다.
            <br /> 조금만 기다려주세요🙏
          </div>
          <div className="btn-wrapper">
            <button className="cancel-btn" onClick={__cancel}>
              닫기
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Popup;
