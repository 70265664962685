import React, { useEffect, useState } from "react";
import { Common_get_api } from "../api/get_api";
import { Bannerinterface } from "../interface/interface";

type Props = {
  type: "line" | "normal";
};

const UseGetAdd = ({ type }: Props) => {
  const [getData, setGetData] = useState<Bannerinterface[]>([]);
  useEffect(() => {
    Common_get_api("/web/cmnt/2?bbs_id=5").then(({ state, data }) => {
      if (state) {
        setGetData(
          data.lst.filter(
            ({ ctts_ctg_cd }: { ctts_ctg_cd: "normal" | "line" }) =>
              ctts_ctg_cd === type
          )
        );
      }
    });
    return () => {};
  }, [type]);
  return [getData];
};

export default UseGetAdd;
