import React, { useEffect, useState } from "react";
import "./css/index.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {
  data: {
    ctts_tit: string;
    ctts_reg_at: string;
    ctts_id: string;
  };
  __nav: (id: string) => void;
};

const NoticeCard = ({ data, __nav }: Props) => {
  return (
    <div className="noticeCard">
      {data ? (
        <NoticeContent data={data} __nav={__nav} />
      ) : (
        <Skeleton containerClassName="card" />
      )}
    </div>
  );
};

export default NoticeCard;

const NoticeContent = ({
  data: { ctts_reg_at, ctts_tit, ctts_id },
  __nav,
}: {
  data: {
    ctts_tit: string;
    ctts_reg_at: string;
    ctts_id: string;
  };
  __nav: (id: string) => void;
}) => {
  const [timeDate, setTimeDate] = useState<Date | undefined>(undefined);
  useEffect(() => {
    setTimeDate(new Date(ctts_reg_at));
    return () => {};
  }, [ctts_reg_at]);

  return (
    <div
      className="card"
      onClick={() => {
        __nav(ctts_id);
      }}
    >
      <div className="title">{ctts_tit}</div>
      <div className="sub">
        {timeDate ? (
          <div className="subLeft">
            {`${timeDate.getFullYear()}년  ${timeDate.getMonth() + 1}월
            ${timeDate.getDate()}일`}
          </div>
        ) : undefined}
        <div className="subright">마이팀플 운영팀</div>
      </div>
    </div>
  );
};
