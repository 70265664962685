import React from "react";
import { Link } from "react-router-dom";
import { ReplyInter } from "../../../interface/interface";
import { encrypto, FileDown, formatDate } from "../../../lib/lib";

function Reply({
  data: {
    ctts_id,
    ctts_ctts,
    ctts_reg_login_nm,
    ctts_reg_at,
    usr_thumbnail,
    ctts_reg_usr_id,
    isalertblock,
    link,
    file_link,
    file_nm,
  },
  leadr_usr_id,
  __remove,
  usr_id,
  __report,
}: {
  data: ReplyInter;
  leadr_usr_id: string;
  usr_id: string;
  __reply: (commentid: string) => void;
  cid: string;
  __report: (cid: string, content: string) => void;
  __remove: (id: string) => void;
}) {
  return (
    <div
      className={`reply-wrapper ${
        isalertblock === "BLOCK" ? "block-comment" : ""
      }`}
    >
      <img src="/assets/comment/arrow.svg" alt="" />
      <div
        className={`reply-card ${
          leadr_usr_id === ctts_reg_usr_id ? "lment" : ""
        }`}
      >
        <div className="btn-wrapper">
          {usr_id === ctts_reg_usr_id ? (
            <button
              onClick={() => {
                __remove(ctts_id);
              }}
            >
              삭제
            </button>
          ) : isalertblock !== "BLOCK" ? (
            <button
              onClick={() => {
                __report(ctts_id, ctts_ctts);
              }}
            >
              신고
            </button>
          ) : undefined}
        </div>
        <Link
          className="profile-wrapper"
          to={`/profile/${encrypto(ctts_reg_usr_id)}`}
        >
          <div
            className="img-wrapper"
            style={{ backgroundImage: `url(${usr_thumbnail})` }}
          />
          <div className="right">
            <div className="nickname">{ctts_reg_login_nm}</div>
            <div className="grade">
              {leadr_usr_id === ctts_reg_usr_id ? "팀플리더" : "팀플러"}
            </div>
          </div>
        </Link>
        <div className="content">
          {" "}
          {isalertblock !== "BLOCK"
            ? ctts_ctts
            : "해당 댓글은 블라인드 처리된 댓글입니다"}
        </div>
        <div className="file-wrapper">
          {link ? (
            <a href={link} target={"_blank"} rel="noreferrer">
              <div className="left">
                <div className="img-wrapper">
                  <img src="/assets/common/link.svg" alt="" />
                </div>
                <div className="title">{link}</div>
              </div>
              <img src="/assets/common/grey-arrow.svg" alt="" />
            </a>
          ) : undefined}
          {file_link ? (
            <button
              onClick={() => {
                FileDown(file_link, file_nm);
              }}
            >
              <div className="left">
                <div className="img-wrapper">
                  <img src="/assets/common/blue-file.svg" alt="" />
                </div>
                <div className="title">{file_nm}</div>
              </div>
              <img src="/assets/common/grey-arrow.svg" alt="" />
            </button>
          ) : undefined}
        </div>
        <div className="timestamp">{formatDate(ctts_reg_at, "/")}</div>
      </div>
    </div>
  );
}

export default Reply;
