import React from "react";
import "./css/index.css";
function FirstInfoBtn({
  title,
  __nav,
  type,
}: {
  title: string;
  __nav: () => void;
  type: string;
}) {
  return (
    <div className={`firstinfo-btn ${type}`} onClick={__nav}>
      <div className="title">{title}</div>
      <img src="/assets/profile/white-arrow.svg" alt="" />
    </div>
  );
}

export default FirstInfoBtn;
