import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Popup from "./components/Popup/Popup";
import FixPassword from "./pages/FixPassword/FixPassword";
import Login from "./pages/Login/Login";
import Main from "./pages/Main/Main";
import ResendMail from "./pages/ResendMail/ResendMail";
import SendMail from "./pages/SendMail/SendMail";
import Signup from "./pages/Signup/Signup";
import SignupFinish from "./pages/Signup/sub/SignupFinish";
import Step1 from "./pages/FirstInfo/sub/Step1";
import FirstInfo from "./pages/FirstInfo/FirstInfo";
import Step2 from "./pages/FirstInfo/sub/Step2";
import Step3 from "./pages/FirstInfo/sub/Step3";
import Mypage from "./pages/MyPage/Mypage";
import Menu from "./components/Menu/Menu";
import TeamRoom from "./pages/TeamRoom/TeamRoom";
import RoomList from "./pages/TeamRoom/sub/RoomList";
import RoomDetail from "./pages/TeamRoom/sub/RoomDetail";
import Payment from "./pages/Payment/Payment";
import PaymentIntro from "./pages/Payment/sub/PaymentIntro";
import PamyentReceipt from "./pages/Payment/sub/PamyentReceipt";
import Notice from "./pages/Notice/Notice";
import Inquiry from "./pages/Inquiry/Inquiry";
import PamyentCoupon from "./pages/Payment/sub/PamyentCoupon";
import Alliance from "./pages/Alliance/Alliance";
import AllianceDetail from "./pages/AllianceDetail/AllianceDetail";
import PaymentFinish from "./pages/Payment/sub/PaymentFinish";
import DetailContainer from "./pages/Detail/container/DetailContainer";
import NoticeDetail from "./pages/NoticeDetail/NoticeDetail";
import MyPayment from "./pages/MyPayment/MyPayment";
import MyCard from "./pages/MyCard/MyCard";
import MagazineCategoryList from "./pages/MagazineCategoryList/MagazineCategoryList";
import MagazineDetail from "./pages/MagazineDetail/MagazineDetail";
import MyScrap from "./pages/MyScrap/MyScrap";
import MyCoupon from "./pages/MyCoupon/MyCoupon";
import MyPaymentDetail from "./pages/MyPaymentDetail/MyPaymentDetail";
import About from "./pages/About/About";
import MyTeampl from "./pages/MyTeampl/MyTeampl";
import MeetUpContainer from "./pages/Detail/container/MeetUpContainer";
import OtherProfile from "./pages/OtherProfile/OtherProfile";
import PolicyPage from "./pages/Policy/Policy";
import Toast from "./components/Toast/Toast";
import Loading from "./components/Loading/Loading";
import PaymentFixFinish from "./pages/PaymentFixFinish/PaymentFixFinish";
import RoomNotice from "./pages/TeamRoom/sub/RoomNotice";
import RoomCalendar from "./pages/TeamRoom/sub/RoomCalendar";
import RoomMember from "./pages/TeamRoom/sub/RoomMember";
import FixApply from "./pages/FixApply/FixApply";
import ProjectListContainer from "./container/ProjectListContainer";
import MtupListContainer from "./container/MtupListContainer";
import CalendarWriteContainer from "./container/CalendarWriteContainer";
import RoomWriteContainer from "./container/RoomWriteContainer";
import RoomFixContainer from "./container/RoomFixContainer";
import Page_404 from "./pages/Error/Page404/Page_404";

// container 방식으로 리팩토링 예정 3/16
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {/* 메인 페이지 */}
        <Route path="/" element={<Main />} />
        {/* 로그인 페이지 */}
        <Route path="/login" element={<Login />} />
        {/* 어바웃 페이지 */}
        <Route path="/about" element={<About />} />
        {/* 회원가입 페이지 */}
        <Route path="/signup/*" element={<Signup />} />
        {/* 회원가입 마무리 */}
        <Route path="/signup/finish" element={<SignupFinish />} />
        {/* 회원가입 페이지 */}
        <Route path="/policy/*" element={<PolicyPage />} />
        {/* 첫 가입시 정보 입력 마무리 */}
        <Route path="/firstinfo" element={<FirstInfo />}>
          <Route path="step1" element={<Step1 />} />
          <Route path="step2" element={<Step2 />} />
          <Route path="step3" element={<Step3 />} />
        </Route>
        {/* 타인 프로필*/}
        <Route path="/profile/*" element={<OtherProfile />} />
        {/* 비밀번호 찾기 인증번호 보내기 페이지 */}
        <Route path="/mail" element={<SendMail />} />
        {/* 비밀번호 찾기 인증번호 재전송 페이지 */}
        <Route path="/resend" element={<ResendMail />} />
        {/* 비밀번호 변경 페이지 */}
        <Route path="//fixpassword/*" element={<FixPassword />} />
        {/* 프로젝트 리스트 */}
        <Route path="/project" element={<ProjectListContainer />} />
        {/* 밋업 리스트 */}
        <Route path="/meetup" element={<MtupListContainer />} />
        {/* 프로젝트 상세  */}
        <Route path="/detail/project/*" element={<DetailContainer />} />
        {/* 밋업 상세 */}
        <Route path="/detail/meetup/*" element={<MeetUpContainer />} />
        {/* 매거진 상세  */}
        <Route path="/detail/magazine/*" element={<MagazineDetail />} />
        <Route path="/room" element={<TeamRoom />}>
          <Route path="notice" element={<RoomNotice />} />
          <Route path="list" element={<RoomList />} />
          <Route path="member" element={<RoomMember />} />
          <Route path="calendar" element={<RoomCalendar />} />
          <Route path="write/calendar/*" element={<CalendarWriteContainer />} />
          <Route path="fix/calendar/*" element={<CalendarWriteContainer />} />
          <Route path=":id/write/*" element={<RoomWriteContainer />} />
          <Route path=":id/fix/*" element={<RoomFixContainer />} />
          <Route path="detail/*" element={<RoomDetail />} />
        </Route>
        <Route path="/payment" element={<Payment />}>
          <Route path="intro" element={<PaymentIntro />} />
          <Route path="receipt" element={<PamyentReceipt />} />
          <Route path="coupon" element={<PamyentCoupon />} />
          <Route path="finish" element={<PaymentFinish />} />
        </Route>
        {/* 매거진 카테고리별 리스트  */}
        <Route path="/magazine" element={<MagazineCategoryList />} />
        {/* 공지사항 */}
        <Route path="/notice" element={<Notice />} />
        {/* 공지사항 상세  */}
        <Route path="/notice/detail/*" element={<NoticeDetail />} />
        {/* 고객문의 */}
        <Route path="/inquiry" element={<Inquiry />} />
        {/* 제휴 목록  */}
        <Route path="/alliance" element={<Alliance />} />
        {/* 제휴 상세  */}
        <Route path="/alliance/detail/*" element={<AllianceDetail />} />
        {/* 내정보 페이지*/}
        <Route path="/my/page" element={<Mypage />} />
        {/* 지원수 수정 페이지*/}
        <Route path="/my/apply" element={<FixApply />} />
        {/* 결제 내역 페이지 */}
        <Route path="/my/payment" element={<MyPayment />} />
        {/* 결제 내역 상세  */}
        <Route path="/my/payment/detail" element={<MyPaymentDetail />} />
        {/* 결제 수정 마무리 페이지 */}
        <Route path="/my/finish/payment/fix" element={<PaymentFixFinish />} />
        {/* 내 카드  */}
        <Route path="/my/card" element={<MyCard />} />
        {/* 내 스크랩  */}
        <Route path="/my/scrap" element={<MyScrap />} />
        {/* 내 쿠폰  */}
        <Route path="/my/coupon" element={<MyCoupon />} />
        {/* 나의 팀플 */}
        <Route path="/my/teampl" element={<MyTeampl />} />
        <Route path="/*" element={<Page_404 />} />
      </Routes>
      {/* 토스트 */}
      <Toast />
      {/* 메뉴 */}
      <Menu />
      {/* 팝업 */}
      <Popup />
      {/* 로딩 */}
      <Loading />
    </BrowserRouter>
  );
}

export default App;
