import React, { useEffect, useState } from "react";
import "./css/index.css";
import { CouponInter } from "../../interface/interface";
import EmtyList from "../EmtyList/EmtyList";
import MyCouponCard from "../MyCouponCard/MyCouponCard";
import { Get_usr_coupon } from "../../api/user_api";

type Props = {
  usr_id: string;
  type: string | undefined;
  stat: string | undefined;
  location: "payment" | "my";
};

const CouponSection = ({ usr_id, type, stat }: Props) => {
  const [CouponData, setCouponData] = useState<CouponInter[]>([]);
  useEffect(() => {
    if (usr_id) {
      Get_usr_coupon(
        `/cstm/cpon_usr?usr_id=${usr_id}${type ? `&cpon_type_cd=${type}` : ""}${
          stat ? `&cpon_stat_cd=${stat}` : ""
        }`
      ).then(({ state, data }) => {
        if (state) {
          setCouponData(data);
        }
      });
    }
    return () => {};
  }, [usr_id, stat, type]);

  return (
    <div className="coupon-section">
      <div className="coupon-notice">
        팀플 모집 종료 후 크루로 선정된 팀플의 경우 환불 요청 시, 인 <br />{" "}
        비테이션 재발행이 되지 않습니다. <br /> <br /> PM이 발행한 인비테이션은
        해당 PM이 오픈한 팀플에서만 사용 <br /> 가능합니다. 선정되지 않은 팀플은
        인비테이션이 재발행됩니다.
      </div>
      {CouponData.length > 0 ? (
        <div className="coupon-list">
          {CouponData.map((item, idx) => {
            return <MyCouponCard key={idx} data={item} listType="ready" />;
          })}
        </div>
      ) : (
        <EmtyList
          text={
            stat === "99" ? "보유한 쿠폰이 없습니다" : "사용한 쿠폰이 없습니다"
          }
        />
      )}
    </div>
  );
};

export default CouponSection;
