import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Coupon from "../../../components/Coupon/Coupon";
import {
  CouponInter,
  MeetupBasicDataType,
  ProjectBasicDataType,
} from "../../../interface/interface";

const PamyentCoupon = () => {
  const {
    coupon,
    __changeCoupon,
    pid,
    CouponData,
    data,
  }: {
    pid: string;
    field: string;
    coupon: CouponInter | undefined;
    __changeCoupon: (e: CouponInter | undefined) => void;
    usr_id: string;
    CouponData: CouponInter[];
    data: ProjectBasicDataType | MeetupBasicDataType;
  } = useOutletContext();

  const [CouponIndex, setCouponIndex] = useState<CouponInter | undefined>(
    coupon
  );
  const [ableCoupon, setAbleCoupon] = useState<number>(0);
  useEffect(() => {
    setAbleCoupon(
      CouponData.filter(({ cpon_type_cd, cpon_ctg_cd, prjt_id }) =>
        cpon_type_cd === "normal"
          ? cpon_ctg_cd === data.prjt_type_cd
            ? true
            : false
          : String(prjt_id) === String(pid)
      ).length
    );

    return () => {};
  }, [CouponData]);

  return (
    <div className="payment-coupon">
      <div className="payment-coupon-card">
        <div className="title">
          쿠폰 및 인비테이션 <span>{ableCoupon}</span>
        </div>
        <div className="sub">
          한번 사용된 쿠폰 및 인비테이션은 환불/신청취소 시, 재발행되지
          않습니다. 인비테이션은 해당 초청장을 발행한 팀플리더의 프로젝트에서만
          사용 가능합니다.
        </div>
        <div className="payment-coupon-list">
          {CouponData.map((item, idx) => {
            return (
              <Coupon
                key={idx}
                data={item}
                isSelect={CouponIndex?.cpon_id === item.cpon_id ? true : false}
                index={idx}
                __setCoupon={setCouponIndex}
                pid={pid}
                category={data.prjt_type_cd}
              />
            );
          })}
        </div>
        <button
          className="payment-apply-coupon"
          onClick={() => {
            __changeCoupon(CouponIndex);
          }}
        >
          적용하기
        </button>
      </div>
    </div>
  );
};

export default PamyentCoupon;
