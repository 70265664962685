import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Common_get_api } from "../../api/get_api";
import Footer from "../../components/Footer/Footer";
import Screen from "../../components/Screen/Screen";
import { NoticeDetailInter } from "../../interface/interface";
import { decypto, formatDate } from "../../lib/lib";
import "./css/index.css";

const NoticeDetail = () => {
  const pathname = useLocation().pathname;
  const ctts_id = pathname.substring(15, pathname.length);
  const [detailData, setDetailData] = useState<undefined | NoticeDetailInter>(
    undefined
  );

  useEffect(() => {
    Common_get_api(`/web/cmnt/2/ctts/${decypto(ctts_id)}`).then(
      ({ state, data }) => {
        if (state) {
          setDetailData(data);
        }
      }
    );

    return () => {};
  }, [ctts_id]);
  return (
    <>
      <main className="noticelist">
        {detailData ? (
          <div className="wrapper">
            <div className="title">{detailData.ctts_tit}</div>
            <div className="sub">
              <div className="subleft">
                <div className="blue">
                  <img src="/assets/common/plus.svg" alt="" />
                </div>
                마이팀플 운영팀
              </div>
              <div className="subright">
                {formatDate(detailData.ctts_reg_at, "-")}
              </div>
            </div>
            <Screen data={detailData.ctts_ctts} />
          </div>
        ) : undefined}
      </main>
      <Footer />
    </>
  );
};

export default NoticeDetail;
