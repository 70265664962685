import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EmtyList from "../../components/EmtyList/EmtyList";
import Footer from "../../components/Footer/Footer";
import Infinite from "../../components/Infinite/Infinite";
import ListCard from "../../components/ListCard/ListCard";
import MeetupCard from "../../components/ListCard/MeetupCard";
import ListFilter from "../../components/ListFilter/ListFilter";
import UseScrap from "../../hooks/UseScrap";
import { categoryArr, encrypto } from "../../lib/lib";
import "./css/index.css";

type Props = {
  type: "project" | "meetup";
  Listdata: any[];
  filterType?: number | undefined;
  setFilterType?: Dispatch<SetStateAction<number | undefined>>;
  SortType: number | undefined;
  setSortType: Dispatch<SetStateAction<number | undefined>>;
  stateType: number | undefined;
  setStateType: Dispatch<SetStateAction<number | undefined>>;
  PagingUpdate: () => void;
  sortlayout: { title: string; type: string }[];
};

function List({
  type,
  Listdata,
  filterType,
  SortType,
  stateType,
  setFilterType,
  setSortType,
  setStateType,
  PagingUpdate,
  sortlayout,
}: Props) {
  const navigate = useNavigate();
  const [ScrapList, PostScarp, RemoveScarp] = UseScrap();
  const __nav = useCallback(
    (id: string) => {
      navigate(`/detail/${type}/${encrypto(id)}`);
      if (filterType) {
        localStorage.setItem("filter", JSON.stringify(filterType));
      }
      if (SortType) {
        localStorage.setItem("sort", JSON.stringify(SortType));
      }
      if (stateType) {
        localStorage.setItem("state", JSON.stringify(stateType));
      }
    },
    [type, navigate, filterType, SortType, stateType]
  );

  /** 뒤로가기 아닐시 유지되지 않도록 */
  useEffect(() => {
    const filter = localStorage.getItem("filter");
    const sort = localStorage.getItem("sort");
    const state = localStorage.getItem("state");
    if (filter) {
      localStorage.removeItem("filter");
    }
    if (sort) {
      localStorage.removeItem("sort");
    }
    if (state) {
      localStorage.removeItem("state");
    }
    return () => {};
  }, []);

  useEffect(() => {
    const ref = document.getElementById("project-list");
    if (ref) {
      ref.classList.remove("inside-ani");
      void ref.offsetWidth;
      ref.classList.add("inside-ani");
    }
    return () => {};
  }, [type]);

  return (
    <>
      <main className="list-section" id="project-list">
        <div className="wrapper">
          <div className="title">
            {type === "project" ? "프로젝트 " : "밋업"}
          </div>
          <div className="filter-wrapper">
            {type === "project" && setFilterType ? (
              <ListFilter
                list={categoryArr}
                now={
                  filterType !== undefined
                    ? categoryArr[filterType].title
                    : "관심분야"
                }
                __update={setFilterType}
                type="filter"
              />
            ) : undefined}
            <ListFilter
              list={sortlayout}
              now={
                SortType !== undefined ? mtupSort[SortType].title : "정렬순서"
              }
              __update={setSortType}
              type={`sort`}
            />
            <ListFilter
              list={stateFilter}
              now={
                stateType !== undefined ? stateFilter[stateType].title : "상태"
              }
              __update={setStateType}
              type={`sort`}
            />
          </div>
          {Listdata.length > 0 ? (
            <div className="list-wrapper">
              {type === "project"
                ? Listdata.map((item, idx) => {
                    return (
                      <ListCard
                        key={idx}
                        data={item}
                        __nav={__nav}
                        scrap={PostScarp}
                        unscrap={RemoveScarp}
                        isScrap={
                          item &&
                          ScrapList.prjt.lst?.filter(
                            ({ prjt_id }) => item.prjt_id === prjt_id
                          ).length > 0
                        }
                      />
                    );
                  })
                : Listdata.map((item, idx) => {
                    return (
                      <MeetupCard
                        key={idx}
                        data={item}
                        __nav={__nav}
                        scrap={PostScarp}
                        unscrap={RemoveScarp}
                        isScrap={
                          item &&
                          ScrapList.mtup.lst?.filter(
                            ({ prjt_id }) => item.prjt_id === prjt_id
                          ).length > 0
                        }
                      />
                    );
                  })}
              <Infinite PagingUpdate={PagingUpdate} />
            </div>
          ) : (
            <EmtyList
              text={`현재 모집중인 ${
                type === "project" ? "프로젝트가" : "밋업이"
              } 없습니다.`}
            />
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}

export default React.memo(List);

const stateFilter = [
  {
    title: "전체",
    type: undefined,
  },
  {
    title: "오픈예정",
    type: "prjt_sts_rcrt_wait",
  },
  {
    title: "모집중",
    type: "prjt_sts_rcrt_ing,",
  },
  {
    title: "모집마감",
    type: "prjt_sts_end,prjt_sts_ing,prjt_sts_rcrt_end",
  },
];

const projectSort = [
  {
    title: "최신순",
    type: "new",
  },
  {
    title: "인기순",
    type: "pplr",
  },
  {
    title: "마감 임박 순",
    type: "rcrt",
  },
];

const mtupSort = [
  {
    title: "최신순",
    type: "new",
  },
  {
    title: "인기순",
    type: "hot",
  },
  {
    title: "마감 임박 순",
    type: "old",
  },
  {
    title: "비대면 우선순",
    type: "off",
  },
];
