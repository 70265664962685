import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Project_detial_api } from "../../../api/get_api";
import { ApprvType, ProjectCardInter } from "../../../interface/interface";
import Members from "../../Detail/sub/Members";

type Props = {
  nowPrj: ProjectCardInter;
  usr_id: string;
};

const RoomMember = () => {
  const { nowPrj, usr_id }: Props = useOutletContext();
  const [data, setData] = useState<ApprvType[]>([]);
  useEffect(() => {
    if (nowPrj) {
      Project_detial_api(`/web/cstm/prjt/${nowPrj.prjt_id}`).then(
        ({ state, data }) => {
          if (state && data) {
            setData(data.rcrt_apprv_lst);
          }
        }
      );
    }

    return () => {};
  }, [nowPrj]);

  return <Members data={data} title={"팀원목록"} usr_id={usr_id} />;
};

export default RoomMember;
