import React from "react";
import { AppDispatch } from "../../..";
import { Remove_Comment_Id } from "../../../reducers/data";

type Props = {
  __cancel: () => void;
  data: any;
  type: "ASK" | "RECOMMEND" | "REVIEW" | "ROOM" | "CALENDAR";
  dispatch: AppDispatch;
};

const RemoveComment = ({ __cancel, data, type, dispatch }: Props) => {
  return (
    <div className="remove-comment-popup">
      <img
        src="/assets/common/cancel.svg"
        alt=""
        className="cancel"
        onClick={__cancel}
      />
      <div className="title">
        {type === "ASK"
          ? "문의를 "
          : type === "REVIEW"
          ? "후기를 "
          : type === "RECOMMEND"
          ? "추천평을 "
          : type === "ROOM"
          ? "댓글을 "
          : "일정을 "}
        삭제하시겠습니까?
      </div>
      <div className="sub">
        삭제한{" "}
        {type === "ASK"
          ? "문의는 "
          : type === "REVIEW"
          ? "후기는 "
          : type === "RECOMMEND"
          ? "추천평은 "
          : "일정은 "}
        되돌릴 수 없습니다.
        <br /> 해당 후기를 삭제하시겠습니까?
      </div>
      <div className="btn-wrapper">
        <button
          className="report-btn"
          onClick={() => {
            dispatch(Remove_Comment_Id(data));
            __cancel();
          }}
        >
          네, 삭제할래요
        </button>
        <button className="cancel-btn" onClick={__cancel}>
          삭제하지 않을래요
        </button>
      </div>
    </div>
  );
};

export default RemoveComment;
