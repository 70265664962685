import { combineReducers } from "@reduxjs/toolkit";
import config from "./config/index";
import auth from "./auth/index";
import data from "./data/index";
const rootReducer = combineReducers({
  config,
  auth,
  data,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
