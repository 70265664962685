import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { NavigateFunction } from "react-router-dom";
import { clientApi } from "../../api/config";
import { ProjectCardInter } from "../../interface/interface";
import { formatDate } from "../../lib/lib";
import CalendarCard from "./components/CalendarCard";
import CalendarView from "./components/CalendarView";
import "./css/index.css";
type Props = {
  initial: Date;
  type: "write" | "list";
  __update:
    | ((type: "timestamp", value: string, time: Date) => void)
    | undefined;
  prj_id: string;
  nowPrj: ProjectCardInter;
  isWrite: boolean;
  navigate: NavigateFunction;
  setIsClendar?: Dispatch<SetStateAction<boolean>>;
  usr_id?: string;
};
type CalendarType = number | undefined;
export type calendarDataType = {
  schd_apply_st_at: string;
  schd_ctts: string;
  schd_id: string;
  schd_nm: string;
};

const Calendar = ({
  initial,
  type,
  __update,
  prj_id,
  nowPrj,
  isWrite,
  navigate,
  setIsClendar,
  usr_id,
}: Props) => {
  const [nowDate, setNowDate] = useState(initial);
  const [selectDate, setSelectDate] = useState<number | undefined>(
    new Date().getDate()
  );
  const [CallenderArr, setCallenderArr] = useState<CalendarType[]>([]);
  const [CalendarData, setCalendarData] = useState<calendarDataType[]>([]);
  const [viewDate, setViewDate] = useState<calendarDataType[]>([]);
  const ChangeMonth = useCallback(
    (type: "prev" | "next") => {
      let clone = new Date(nowDate);
      clone.setMonth(
        type === "prev" ? clone.getMonth() - 1 : clone.getMonth() + 1
      );
      setNowDate(clone);
      setSelectDate(undefined);
    },
    [nowDate]
  );

  const ChangeSelect = useCallback((date: number) => {
    setSelectDate(date);
  }, []);

  const NavWrite = useCallback(() => {
    let pathDate = new Date(nowDate);
    if (selectDate) {
      pathDate.setDate(selectDate);
    }
    navigate(`./write/calendar/${pathDate.getTime()}`);
  }, [navigate, nowDate, selectDate]);

  const GetCalendar = useCallback(() => {
    if (type === "list" && prj_id) {
      clientApi(
        `/web/my/task?trgt_dt=${formatDate(
          Number(nowDate),
          "-"
        )}&prjt_id=${prj_id}`
      ).then(({ data: { sts_fg, succ_obj } }) => {
        if (sts_fg) {
          setCalendarData(succ_obj);
        }
      });
    }
  }, [type, prj_id, nowDate]);

  useEffect(() => {
    if (CalendarData.length > 0 && selectDate) {
      let cardDate = new Date(nowDate);
      cardDate.setDate(selectDate);
      const Filter = CalendarData.filter(
        ({ schd_apply_st_at }) =>
          formatDate(schd_apply_st_at, "-") ===
          formatDate(Number(cardDate), "-")
      );
      setViewDate(Filter);
    } else {
      setViewDate([]);
    }
    return () => {};
  }, [selectDate, CalendarData, nowDate]);

  useEffect(() => {
    const year = nowDate.getFullYear();
    const month = nowDate.getMonth();
    const firstDayOfMonth = new Date(year, nowDate.getMonth(), 1);
    const lastDate = new Date(year, month + 1, 0).getDate();
    let arr = [];
    let number = 1;
    for (let i = 0; i < 42; i++) {
      if (i < firstDayOfMonth.getDay() || lastDate < number) {
        arr.push(undefined);
      } else {
        arr.push(number);
        number++;
      }
    }
    setCallenderArr(arr);
    return () => {};
  }, [nowDate]);

  useEffect(() => {
    GetCalendar();

    return () => {};
  }, [GetCalendar]);

  return (
    <>
      <div className="calendar">
        <div className="calendar-title-section">
          <div className="title">
            {nowDate.getFullYear()}년 {nowDate.getMonth() + 1}월
          </div>
          <div className="change-month-wrapper">
            <button
              className="prev-btn"
              onClick={() => {
                ChangeMonth("prev");
              }}
            >
              <img src="/assets/calendar/calendar-btn.svg" alt="뒤로" />
            </button>
            <button
              className="now-btn"
              onClick={() => {
                const now = new Date();
                setNowDate(now);
                setSelectDate(now.getDate());
              }}
            >
              오늘
            </button>
            <button
              className="next-btn"
              onClick={() => {
                ChangeMonth("next");
              }}
            >
              <img src="/assets/calendar/calendar-btn.svg" alt="다음으로" />
            </button>
          </div>
        </div>
        <div className="calendar-week-section">
          {week_layouts.map((item, idx) => {
            return (
              <div className="week-text" key={idx}>
                {item}
              </div>
            );
          })}
        </div>
        <div className="calendar-content-section">
          {CallenderArr.map((item, idx) => {
            return (
              <CalendarCard
                key={idx}
                item={item}
                selectDate={selectDate}
                select={ChangeSelect}
                data={CalendarData}
                nowDate={nowDate}
              />
            );
          })}
        </div>
      </div>
      {type === "write" && __update && setIsClendar ? (
        <button
          className={`change-date-btn`}
          onClick={() => {
            const date = new Date(nowDate);
            setIsClendar(false);
            if (selectDate) {
              date.setDate(selectDate);
            }
            __update("timestamp", "", date);
          }}
        >
          날짜 변경
        </button>
      ) : undefined}
      {selectDate && type !== "write" && (
        <CalendarView
          nowDate={nowDate}
          selectDate={selectDate}
          viewData={viewDate}
          nowPrj={nowPrj}
          usr_id={usr_id}
          GetCalendar={GetCalendar}
        />
      )}
      {isWrite ? (
        <button onClick={NavWrite} className="write-nav">
          <img src="/assets/room/write.svg" alt="" />
          <div className="title">일정 추가</div>
        </button>
      ) : undefined}
    </>
  );
};

export default Calendar;

const week_layouts = ["일", "월", "화", "수", "목", "금", "토"];
