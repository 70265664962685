import { clientApi } from "../api/config";

export function __insertCard(__updateCard: (e: any) => void) {
  clientApi.get("/cstm/crd/auth").then(({ data: { sts_fg, succ_obj } }) => {
    if (sts_fg) {
      const { AuthKey, return_url } = succ_obj;
      const info = {
        PCD_AUTH_KEY: AuthKey,
        PCD_CARD_VER: "01",
        PCD_PAY_TYPE: "card",
        PCD_PAY_URL: return_url,
        PCD_PAY_WORK: "AUTH",
        callbackFunction: __updateCard,
      };
      window.PaypleCpayPopup(info);
    }
  });
}
