import { PolicyCtgType } from "../pages/Policy/Policy";
import CryptoJs from "crypto-js";
import { clientApi } from "../api/config";
export var DataReg = new RegExp(
  /[\{\}\[\]\?.,;:|\)*~`!^-_+<>@\#$%&\\\=\(\'\"]/gi // eslint-disable-line
);

export const encrypto = (data: string) => {
  return CryptoJs.AES.encrypt(
    data,
    String(process.env.REACT_APP_KEY)
  ).toString();
};

export const FileDown = (file: string, name: string) => {
  var xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = (event) => {
    var blob = URL.createObjectURL(xhr.response);
    var link = document.createElement("a");
    link.href = blob;
    link.download = `${name}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  xhr.open("GET", file);
  xhr.send();
};

export const decypto = (data: string) => {
  try {
    const bite = CryptoJs.AES.decrypt(data, String(process.env.REACT_APP_KEY));
    return JSON.parse(bite.toString(CryptoJs.enc.Utf8));
  } catch (err) {
    console.log(err);
    return;
  }
};

type ChargeType = {
  text: string;
  amt: number;
  type: string;
};

export const ChargeChange = (
  pay_time: string,
  paym_amt: number,
  type: "prjt" | "mtup",
  finish_time: string,
  cancel_time: string
): ChargeType => {
  const payDate = new Date(pay_time);
  payDate.setHours(0);
  payDate.setMinutes(0);
  payDate.setSeconds(0);
  const nowDate = new Date(cancel_time);
  nowDate.setHours(0);
  nowDate.setMinutes(0);
  nowDate.setSeconds(0);
  const payNumber = ChangeDateNumber(payDate);
  const nowNumber = ChangeDateNumber(nowDate);
  if (payNumber === nowNumber) {
    return {
      type: "all",
      text: "결제당일",
      amt: 0,
    };
  } else {
    let subDate = payDate;
    if (type === "mtup") {
      subDate = new Date(finish_time);
      subDate.setHours(0);
      subDate.setMinutes(0);
      subDate.setSeconds(0);
    } else {
      subDate.setDate(payDate.getDate() + 2);
    }
    const subNumber = ChangeDateNumber(subDate);

    if (nowNumber <= subNumber) {
      return {
        type: "charge",
        amt: (paym_amt / 100) * 20,
        text: `결제 다음날 ~ ${
          type === "prjt" ? "프로젝트" : "밋업"
        } 하루전까지`,
      };
    } else {
      return {
        type: "no-refund",
        amt: 0,
        text: `${type === "prjt" ? "결제일 +2일 이후" : "밋업 당일 이후"}`,
      };
    }
  }
};

const ChangeDateNumber = (date: Date) => {
  return Number(
    date.getFullYear() + "" + (date.getMonth() + 1) + "" + date.getDate()
  );
};

export type StateFormReturnType = {
  type:
    | "cancel"
    | "refund-ready"
    | "refund-complete"
    | "refund"
    | "cancel-complete"
    | "reject"
    | "";
  text: string;
};

export const PaymentStateForm = (
  prjt_type_cd: "prjt" | "mtup",
  prjt_sts_cd: string,
  apprv_sts_cd: string,
  apply_sts_cd: string,
  rcrt_paysts_cd: string
): StateFormReturnType => {
  if (prjt_type_cd === "prjt") {
    if (rcrt_paysts_cd === "ref_req") {
      return {
        type: "refund-ready",
        text: "환불요청",
      };
    } else if (rcrt_paysts_cd === "ref_res") {
      return {
        type: "refund-complete",
        text: "환불완료",
      };
    } else if (
      prjt_sts_cd === "prjt_sts_rcrt_ing" &&
      apply_sts_cd === "apply_sts_wait"
    ) {
      return {
        type: "cancel",
        text: "모집중",
      };
    } else if (rcrt_paysts_cd === "pay_suss") {
      return {
        type: "refund",
        text: "선정",
      };
    } else if (apply_sts_cd === "apply_sts_cancel") {
      return {
        type: "cancel-complete",
        text: "취소",
      };
    } else if (apprv_sts_cd === "apprv_sts_reject") {
      return {
        type: "reject",
        text: "미선정",
      };
    } else if (
      prjt_sts_cd !== "prjt_sts_rcrt_ing" &&
      apply_sts_cd === "apply_sts_wait"
    ) {
      return {
        type: "",
        text: "선정대기",
      };
    } else {
      return {
        type: "reject",
        text: "미선정",
      };
    }
  } else {
    if (rcrt_paysts_cd === "ref_req") {
      return {
        type: "refund-ready",
        text: "환불요청",
      };
    } else if (rcrt_paysts_cd === "ref_res") {
      return {
        type: "refund-complete",
        text: "환불완료",
      };
    } else if (rcrt_paysts_cd === "pay_suss") {
      return {
        type: "refund",
        text: "선정",
      };
    } else {
      return {
        type: "",
        text: "모집중",
      };
    }
  }
};

export type DetailReturnType =
  | "refund-state"
  | "wait-state"
  | "success-state"
  | "cancel-state"
  | "select-wait-state";

export const PaymnetDetailForm = (
  prjt_type_cd: "prjt" | "mtup",
  prjt_sts_cd: string,
  apprv_sts_cd: string,
  apply_sts_cd: string,
  rcrt_paysts_cd: string
): DetailReturnType => {
  if (prjt_type_cd === "prjt") {
    if (rcrt_paysts_cd === "ref_req" || rcrt_paysts_cd === "ref_res") {
      return "refund-state";
    } else if (
      prjt_sts_cd === "prjt_sts_rcrt_ing" &&
      apply_sts_cd === "apply_sts_wait"
    ) {
      return "wait-state";
    } else if (rcrt_paysts_cd === "pay_suss") {
      return "success-state";
    } else if (
      apply_sts_cd === "apply_sts_cancel" &&
      rcrt_paysts_cd === "pay_wait"
    ) {
      return "cancel-state";
    } else {
      return "select-wait-state";
    }
  } else {
    if (rcrt_paysts_cd === "ref_req" || rcrt_paysts_cd === "ref_res") {
      return "refund-state";
    } else if (rcrt_paysts_cd === "pay_suss") {
      return "success-state";
    } else {
      return "cancel-state";
    }
  }
};

export const PrjtCategory = {
  dev: "IT / SW / 개발",
  cmpgn: "비영리 / 캠페인",
  md: "언론 / 미디어",
  mrk: "마케팅",
  art: "문화예술",
  lgs: "물류 / 제조",
  svc: "서비스",
  dsgn: "디자인",
  vid: "영상 / 콘텐츠",
  esg: "사회공헌 / ESG",
  strtp: "창업 / 스타트업",
  dta: "데이터 / AI",
};
export const categoryArr = [
  { title: "전체", type: undefined },
  { title: "IT / SW / 개발", type: "dev" },
  { title: "비영리 / 캠페인", type: "cmpgn" },
  { title: "언론 / 미디어", type: "md" },
  { title: "마케팅", type: "mrk" },
  { title: "문화예술", type: "art" },
  { title: "물류 / 제조", type: "lgs" },
  { title: "서비스", type: "svc" },
  { title: "디자인", type: "dsgn" },
  { title: "영상 / 콘텐츠", type: "vid" },
  { title: "사회공헌 / ESG", type: "esg" },
  { title: "창업 / 스타트업", type: "strtp" },
  { title: "데이터 / AI", type: "dta" },
];

export function DdayChange(e: string) {
  const recheckTime = new Date(e);
  recheckTime.setHours(0);
  const Dday =
    Math.floor((recheckTime.getTime() - Date.now()) / (1000 * 60 * 60 * 24)) +
    1;
  return Dday;
}

export function koreanTime(time: string) {
  let clone = String(time);
  return clone.replace("-", "년 ").replace("-", "월 ") + "일";
}

export function formatNumber(num: number) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
export function formatDate(date: number | string, type: string) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (parseInt(month) < 10) month = "0" + month;
  if (parseInt(day) < 10) day = "0" + day;
  return [year, month, day].join(type);
}
export const telform = (e: string) => {
  const number = e.replace(/[^0-9-]/gi, "");
  const val = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  return val;
};

export const telreg = new RegExp(/^[0-9]{3}-[0-9]{3,4}-[0-9]{4}/); //eslint-disable-next-line

export function refundTime(date: number | string) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate();

  if (parseInt(month) < 10) month = "0" + month;
  if (parseInt(day) < 10) day = "0" + day;
  return `${month}월 ${day}일`;
}

export function urlCheck(link: string) {
  try {
    new URL(link);
    return true;
  } catch {
    return false;
  }
}
export function makeComma(str: string | number) {
  const number = String(str).replace(/[^0-9]/gi, "");
  return String(number).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
}

export function FileReaderBase64(file: File): Promise<{ url: string }> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      const imageUrl = e?.target?.result;
      if (typeof imageUrl === "string") {
        var img = new Image();
        img.src = imageUrl;
        img.onload = function (e) {
          clientApi
            .post("/web/cmnt/2/ctts/img", [{ img_src: imageUrl }])
            .then(({ data: { succ_obj } }) => {
              if (succ_obj[0].img_url) {
                resolve({
                  url: succ_obj[0].img_url,
                  // name: file.name,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        };
      }
    };
    reader.readAsDataURL(file);
  });
}
export function FileChangeUrl(file: File): Promise<{ url: string }> {
  return new Promise((resolve, reject) => {
    const form = new FormData();
    form.append("file", file);
    clientApi
      .post("/cm/atch/file/20230004/1/upload", form)
      .then(({ data }) => {
        if (data) {
          resolve({
            url: data.file_dwn_url,
            // name: file.name,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const PolicyArr: {
  spand: string;
  title: string;
  link: PolicyCtgType;
}[] = [
    {
    spand: "[필수]",
    title: "서비스 이용약관",
    link: "AGR_AD",
  },
  {
    spand: "[필수]",
    title: "개인정보 수집 및 이용동의",
    link: "AGR_INFO",
  },
  {
    spand: "",
    title: "[선택]  마케팅 활용 및 광고정보 수신 동의",
    link: "ARG_MAT",
  },
];
