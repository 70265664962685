import React, { useCallback } from 'react'
import { AppDispatch } from '../../..';
import { clientApi } from '../../../api/config';
import { ToastChange } from '../../../reducers/config';
import { NavigateFunction } from 'react-router-dom';

type Props = {
  __cancel: () => void;
  data: string;
  dispatch:AppDispatch;
  navigate:NavigateFunction;
};


const PostRemove = ({ __cancel, data,dispatch,navigate }: Props) => {
  const RemovePost = useCallback(
    () => {
          clientApi.delete(`/web/cmnt/2/ctts/${data}`).then(({data:{
        sts_fg,
        err_msg
      }})=>{
        if (sts_fg) {
          dispatch(ToastChange({active:true,content:"게시물이 삭제되었습니다."}))
          
          navigate(-1)
        }else {
 dispatch(ToastChange({active:true,content:err_msg}))
        }
      __cancel()
      })
    },
    [navigate,data,dispatch,__cancel],
  )
  
  return (
  <div className="remove-comment-popup">
      <img
        src="/assets/common/cancel.svg"
        alt=""
        className="cancel"
        onClick={__cancel}
      />
      <div className="title">
        게시글을 삭제하시겠습니까?
      </div>
      <div className="sub">
        삭제한 게시글은 되돌릴
        수 없습니다.
        <br /> 해당 후기를 삭제하시겠습니까?
      </div>
      <div className="btn-wrapper">
        <button
          className="report-btn"
          onClick={RemovePost}
        >
          네, 삭제할래요
        </button>
        <button className="cancel-btn" onClick={__cancel}>
          삭제하지 않을래요
        </button>
      </div>
    </div>
  )
}

export default PostRemove