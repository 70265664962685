import React from "react";
import "./css/index.css";
import { useLocation, useNavigate } from "react-router-dom";

type Location = {
  state: "cancel" | "refund";
};

const PaymentFixFinish = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as Location;
  return (
    <main className="payment-fix-finish-page">
      <div className="wrapper">
        <div className="title">
          {state === "cancel"
            ? "신청이 취소되었습니다"
            : "환불 요청이 완료되었습니다"}
        </div>
        <div className="sub">
          {state === "cancel"
            ? `신청이 취소되었습니다.
문의는 아래 고객센터 참고 바랍니다.`
            : `환불 신청이 완료되었습니다.
영업일 기준 3일 이내 처리될 예정이며
문의는 아래 고객센터 참고 바랍니다.`}
        </div>
        <div className="img-wrapper">
          <img
            src="/assets/payment/payment-finish.png"
            srcSet="/assets/payment/payment-finish@2x.png 2x, /assets/payment/payment-finish@3x.png 3x"
            alt=""
          />
        </div>
        <div className="btn-wrapper">
          <button
            className="nav-btn"
            onClick={() => {
              navigate("/my/payment");
            }}
          >
            확인
          </button>
          <button
            className="question"
            onClick={() => {
              navigate("/inquiry");
            }}
          >
            <div className="sub">가입시 정보가 기억나지 않는다면?</div>
            <div className="title">고객센터문의</div>
          </button>
        </div>
      </div>
    </main>
  );
};

export default PaymentFixFinish;
