import React, { useCallback, useState } from "react";
import { AppDispatch } from "../../..";
import { clientApi } from "../../../api/config";
import { ToastChange } from "../../../reducers/config";

function Report({
  __cancel,
  data,
  dispatch,
}: {
  __cancel: () => void;
  data: string;
  dispatch: AppDispatch;
}) {
  const [ReportType, setReportType] = useState<number | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ReportActive = useCallback(() => {
    if (ReportType !== undefined) {
      clientApi
        .post("/cstm/alert", {
          ctts_id: data,
          alert_type_cd: report_layouts[ReportType].index,
          alert_sts_cd: "normal",
        })
        .then(() => {});
      setIsOpen(false);
      dispatch(
        ToastChange({
          active: true,
          content: "신고가 완료되었습니다",
        })
      );
      __cancel();
    }
  }, [__cancel, ReportType, data, dispatch]);

  return (
    <div className="report-popup">
      <img
        src="/assets/common/cancel.svg"
        alt=""
        className="cancel"
        onClick={__cancel}
      />
      <div className="title">유저를 신고하시겠습니까?</div>
      <div className="sub">
        신고한 내역은 되돌릴 수 없습니다. <br /> 해당 유저를 신고하시겠습니까?
      </div>
      <div
        className={`report-category-section ${
          isOpen ? "open-report-category" : ""
        }`}
      >
        <div
          className="report-category-btn"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {ReportType !== undefined
            ? report_layouts[ReportType].title
            : "신고사유 선택"}
          <img src="/assets/common/grey-arrow.svg" alt="" />
        </div>
        {report_layouts.map(({ title, index }, idx) => {
          return (
            <button
              key={idx}
              className="report-category-btn"
              onClick={() => {
                setIsOpen(false);
                setReportType(idx);
              }}
            >
              {title}
            </button>
          );
        })}
      </div>
      <div className="btn-wrapper">
        <button className="report-btn" onClick={ReportActive}>
          네, 신고할래요
        </button>
        <button className="cancel-btn" onClick={__cancel}>
          신고하지 않을래요
        </button>
      </div>
    </div>
  );
}

export default Report;

const report_layouts = [
  {
    title: "영리목적/홍보성",
    index: "01",
  },
  {
    title: "불법정보",
    index: "02",
  },
  {
    title: "음란성/선정성",
    index: "03",
  },
  {
    title: "욕설/인신공격",
    index: "04",
  },
  {
    title: "개인정보노출",
    index: "05",
  },
  {
    title: "도배",
    index: "06",
  },
  {
    title: "기타",
    index: "07",
  },
];
